import { createStore } from "vuex";
import UserModule from "./user.module.js";
import selectedModule from "./selected.modules.js";
import UrlsModule from "./urls.module.js";
export default createStore({
  modules: {
    user: UserModule,
    urls: UrlsModule,
    // admin: adminModule,
    current: selectedModule
    // status: stateModule
  }
});
