<template>
    <div class=" q-display-flex-column  " style="width: 100%; overflow-y: auto;height: 100vh;">
        <div class=" q-display-flex-column  ">
            <h2 class="" style="margin-top: 0; margin-bottom: 16px;padding-left: 16px;">Administrators</h2>
            <AndroidContentHeaderSection :controls="controls" :supportedActions="supportedViewActions"
                @controlData="handleControlClick" @searchItem="handleSearch"
                searchPlaceHolder="Find a administrator by name"
                @currentSelectionChanged="handleCurrentSelectionChanged"
                style="padding-left: 8px !important;padding-right: 8px !important">

            </AndroidContentHeaderSection>
            <ul class="q-listview-container" style="padding-left: 16px !important;padding-right: 16px !important;">
                <li v-for="(row) in rowsData" :key="row.objectId" class="q-list-item q-display">
                    <div class="q-layout-container padding-top-0" style="padding: 0 !important;">
                        <div class="q-display-flex-row padding-top-0 padding-bottom-0" style="padding: 0;">


                            <div class="q-display-flex-column" style="padding: 0;padding-left: 8px; width: 100%;"
                                @click="viewUserDetails($event, row)">
                                <h3 style="margin: 0;">{{ row.name }}</h3>
                                <p class="q-display-flex-row q-list-caption"><span
                                        style="margin-left:  0 !important;">{{ row.email }} </span>
                                    <span>{{ row.role }}</span> <span>

                                        <p style="color: var(--gw_primary_color);"> {{ row.statusText }}</p>

                                    </span>
                                </p>
                            </div>
                            <div style="padding: 0;width: 8px;" class="q-display-flex-column q-center flow-center">
                                <!-- <button>
                                                <i class="ri-more-2-fill"></i>
                                            </button> -->
                                <OptionsPopup @setCurrent="setCurrent" @controlData="handleRowControlClick"
                                    :controls="rowControls" :rowData="row">
                                </OptionsPopup>
                            </div>
                        </div>
                    </div>

                </li>

            </ul>

            <UserDetailsDrawer @edit="editPreset" @userDeleted="loadUsers" ref="detailsDialog"></UserDetailsDrawer>
        </div>

        <CreateUserView @created="loadUsers" ref="createDialog">
        </CreateUserView>
        <AdminEditUserView @edited="loadUsers" ref="editDialog"></AdminEditUserView>
        <button class="q-fab elevate-5"><i class="ri-add-large-line" @click="createUser"></i></button>

    </div>
</template>

<script>



import OptionsPopup from "../../../components/admin/OptionsPopup.vue"
import CreateUserView from "../../../components/admin/CreateUserView.vue"
import AdminEditUserView from '../../../components/admin/AdminEditUserView.vue'
import { showMessage } from "../../../../public/js/utils.js"
import UserDetailsDrawer from "../../../components/admin/UserDetailsDrawer.vue"
import AndroidContentHeaderSection from '../../../../src/components/AndroidContentHeaderSection.vue'


// import { showMessage, joinArrayToStr } from "../../../../public/js/utils.js"

export default {

    name: "AdminDesktopView",
    props: ["rowsData", "hasData", "currentUserData", "currentUserId", "rowControls", "controls", "supportedViewActions", "totalPresets", "headers"],
    components: {
        OptionsPopup,
        AndroidContentHeaderSection,
        CreateUserView, AdminEditUserView, UserDetailsDrawer,

    },
    computed: {

        showPlaceHolderELement() {
            return !this.hasData ? "showPlaceHolder" : ""
        },
        showTableELement() {
            return this.hasData ? "" : "hideTable"
        },
        currentPresetData() {
            return this.$store.getters["current/currentVisitor"];
        },
        isCurrentPreset() {
            var data = this.$store.getters["current/currentVisitor"]
            console.log("mi == " + "  ")

            if (data.objectId != undefined && this.currentPresetId == data.objectId)
                return "current-row"
            return ""
        }

    },
    data() {
        return {
            capturedData: "",
        }
    },

    methods: {
        showMediaCam: function () {
            this.capturedData = ""
            this.$refs.mediaComponent.show()
        },
        handleAccepted: function () {

            this.capturedData = this.$refs.mediaComponent.getImageData()
            console.log("mysd f asdf asf " + this.$refs.mediaComponent.getImageData())

        },
        handleCurrentSelectionChanged: function (data) {

            this.$emit('handleCurrentSelectionChanged', data)
        },

        handleSearch: function (event, data) {
            this.$emit('handleSearch', event, data)

        },

        viewUserDetails: function (event, row) {
            this.$store.dispatch("current/setCurrentUser", row)
            console.log("is sentsdf asdfasdf " + JSON.stringify(row))
            this.$refs.detailsDialog.show(event, row)
        },
        setCurrent: function (event, row) {
            // var data_ = this.tableData.filter(a => {
            //     return a.objectId === row.objectId
            // })

            // this.$store.dispatch("current/setCurrentVisitor", data_.length > 0 ? data_[0] : row)
            console.log("sett cisd asdf asdf ")
            this.$emit('setCurrent', event, row)
        },
        handleRowControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data) + "  " + JSON.stringify(this.currentUserData))
            if (this.currentUserData.objectId) {
                if (data.key === 2) {
                    this.$refs.createDialog.show()
                } else if (data.key == 0) {
                    this.$refs.editDialog.show()
                } else if (data.key == 1) {
                    this.$refs.detailsDialog.deleteUser()
                }
            } else {

                showMessage("Please select a user ", false)

            }
        },
        loadUsers: function (data) {
            this.$emit('loadUsers', data)
        },
        handleControlClick: function (event, data) {
            if (data.key === 0) {
                this.loadUsers()
            } else if (data.key === 1) {
                this.$refs.createDialog.show()
            } else {
                if (!this.hasData) {
                    showMessage("No users created")
                } else {
                    var userData = this.$store.getters["current/currentUser"]
                    if (!userData.objectId) {
                        this.setCurrent(null, this.rowsData[0])
                    }
                }
                if (data.key === 3) {
                    this.$refs.detailsDialog.deleteUser()
                } else if (data.key === 2) {
                    this.$refs.editDialog.show()
                }
            }
        },
        editPreset: function () {
            this.$refs.editDialog.show()
        },
        createUser: function () {

            this.$refs.createDialog.show()
        }
    },
};
</script>
<style scoped></style>
