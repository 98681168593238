<template>
    <div class="q-display-flex-column" style="max-width: 100%;height: 100vh;overflow: auto;">
        <div class="q-display-flex-row " style="width: 100%;min-height: 56px !important">


            <AndroidUserHeaderComponent @showDrawer="showDrawer">
            </AndroidUserHeaderComponent>

        </div>

        <div class="q-display-flex-column  ">
            <AndroidContentHeaderSection :controls="controls" :supportedActions="supportedViewActions"
                @controlData="handleControlClick" @searchItem="handleSearch" searchPlaceHolder="Find a visitor by name"
                @currentSelectionChanged="handleCurrentSelectionChanged">

            </AndroidContentHeaderSection>

            <AndroidGreetingsView></AndroidGreetingsView>

            <div class="q-layout-container padding-top-0" style="padding: 0;max-width: 100vw !important;">
                <div class="q-layout-row padding-top-0 padding-bottom-0" style="padding: 0;">

                    <div class="q-col-sm-12 q-col-md-12 q-col-lg-12 q-col-xl-12 q-display-flex-column  padding-top-0  padding-bottom-0"
                        style="padding-top: 0 !important;">


                        <div :style="showPlaceHolderELement"
                            class=" q-place-holder  q-display-flex-column  q-center q-flex-center-items"
                            style="width: 100px;">

                            <i class="ri-group-line"></i>
                            <p>Oops, No visitors yet,</p>
                        </div>

                        <ul class="q-listview-container">
                            <li v-for="(row) in tableData" :key="row.objectId" class="q-list-item q-display">
                                <div class="q-layout-container padding-top-0" style="padding: 0 !important;">
                                    <div class="q-display-flex-row padding-top-0 padding-bottom-0" style="padding: 0;">


                                        <div class="q-display-flex-column"
                                            style="padding: 0;padding-left: 8px; width: 100%;"
                                            @click="viewUserDetails($event, row)">
                                            <h3 style="margin: 0;">{{ row.purpose }}</h3>
                                            <p class="q-display-flex-row q-list-caption"><span
                                                    style="margin-left:  0 !important;">{{ row.name }} </span>
                                                <span>{{ row.expectedDate }}</span>
                                            </p>
                                        </div>
                                        <div style="padding: 0;width: 8px;"
                                            class="q-display-flex-column q-center flow-center">
                                            <!-- <button>
                                                <i class="ri-more-2-fill"></i>
                                            </button> -->
                                            <OptionsPopup @setCurrent="setCurrent" @controlData="handleRowControlClick"
                                                :controls="rowControls" :rowData="row">
                                            </OptionsPopup>
                                        </div>
                                    </div>
                                </div>

                            </li>

                        </ul>


                    </div>
                </div>
            </div>


        </div>
        <AndroidLeftDrawerComponent ref="drawer" :sections="sections"></AndroidLeftDrawerComponent>
        <EditPresetDialog ref="mainEditDialog" @edited="loadPresets" @deleted="loadPresets" editExpectedDate="false"
            purposeReadonly="false" hostReadonly="false" idRequired="false" showName="true">
        </EditPresetDialog>
        <CreatePresetDrawer @created="loadPresets" ref="detailsDialog">
        </CreatePresetDrawer>
        <PreviewPresetDrawer @edit="editPreset" @deleted="loadPresets" ref="editDialog">
        </PreviewPresetDrawer>
        <button class="q-fab elevate-5"><i class="ri-add-large-line" @click="createPreset"></i></button>
        <EditAskariDialog ref="askariEditDialog" @edited="loadPresets" @deleted="loadPresets"
            expectedDateReadonly="true" purposeReadonly="true" contactReadonly="true" hostReadonly="true"
            idRequired="true" showName="false" showNin="true" showImage="true" @showCamera="showMediaCam"
            :imageData="capturedData"></EditAskariDialog>

    </div>
</template>

<script>


import { showMessage, joinArrayToStr } from "../../../../public/js/utils.js"
import AndroidGreetingsView from '../../../../src/components/AndroidGreetingsView.vue'
import AndroidUserHeaderComponent from '../../../../src/components/android/AndroidUserHeaderComponent.vue'
import AndroidContentHeaderSection from '../../../../src/components/AndroidContentHeaderSection.vue'
import OptionsPopup from "../../../components/admin/OptionsPopup.vue"
import EditAskariDialog from "../../../components/admin/EditAskariVisitor.vue"
import { ON_GOING_STATUS } from "../../../firebaseInit.js"

import EditPresetDialog from "../../../components/admin/EditUserVisitor.vue"
import CreatePresetDrawer from "../../../components/admin/CreateVisitorDrawer.vue"
import PreviewPresetDrawer from "../../../components/admin/PreviewVisitorDrawer.vue"
import AndroidLeftDrawerComponent from '../../../../src/components/AndroidLeftDrawerComponent.vue'
export default {

    name: "AdminDesktopView",
    props: ["tableData", "hasData", "currentPresetId", "rowControls", "controls", "supportedViewActions", "totalPresets", "headers"],
    components: {
        AndroidUserHeaderComponent,
        AndroidGreetingsView,
        OptionsPopup,
        AndroidContentHeaderSection,
        EditPresetDialog,
        CreatePresetDrawer,
        PreviewPresetDrawer,
        AndroidLeftDrawerComponent,
        EditAskariDialog
    },
    mounted() {
        console.log("s ad asdf asdf asdfasf " + this.tableData)

    },
    computed: {

        showPlaceHolderELement() {
            return !this.hasData ? "showPlaceHolder" : ""
        },
        showTableELement() {
            return this.hasData ? "" : "hideTable"
        },
        currentPresetData() {
            return this.$store.getters["current/currentVisitor"];
        },
        isCurrentPreset() {
            var data = this.$store.getters["current/currentVisitor"]
            console.log("mi == " + "  ")

            if (data.objectId != undefined && this.currentPresetId == data.objectId)
                return "current-row"
            return ""
        }

    },
    data() {
        return {


            sections: [

                {
                    key: 'key',
                    name: 'Signout',
                    icon: 'ri-user-received-line',
                    url: '/signout',

                },
            ],


        }
    },

    methods: {
        nextPage: function () { },
        prevPage: function () { },

        showDrawer: function () { this.$refs.drawer.show() },
        handleCurrentSelectionChanged: function (data) {

            this.$emit('handleCurrentSelectionChanged', data)
        },
        formatData: function (data) {
            return joinArrayToStr(data)
        },
        handleSearch: function (event, data) {
            this.$emit('handleSearch', event, data)

        },

        viewUserDetails: function (event, data) {
            // this.$emit('viewUserDetails', event, data)
            this.$store.dispatch("current/setCurrentVisitor", data)
            this.editPreset()
        },
        setCurrent: function (event, row) {

            this.$emit('setCurrent', event, row)
        },
        handleRowControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf   = = = " + JSON.stringify(data) + " ***** " + JSON.stringify(this.currentPresetData))
            if (this.currentPresetData.objectId) {
                if (data.key === 0) {
                    this.editPreset()
                } else if (data.key === 1) {
                    this.$refs.editDialog.startDeleteUser()
                } else if (data.key === 2) {
                    if (this.currentPresetData.status === ON_GOING_STATUS) {
                        this.$refs.askariEditDialog.show()
                    } else {
                        showMessage("Selected visitor`s appointment is not active")
                    }
                }
            } else {
                showMessage("Please select a visitor ", false)
            }
        },
        loadPresets: function (data) {
            this.$emit('loadPresets', data)
        },
        handleControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data))
            if (data.key === 1) {
                this.createPreset()
            } else if (data.key === 0) {
                this.loadPresets()
            } else if (data.key === 2) {
                if (this.currentPresetData.objectId) {
                    this.editPreset()
                } else {
                    showMessage("Please select a visitor ", false)
                }
            } else if (data.key === 3) {

                if (this.currentPresetData.objectId) {
                    this.$refs.editDialog.startDeleteUser()
                } else {
                    showMessage("Please select a visitor ", false)
                }
            }
        },
        editPreset: function () {
            this.$refs.mainEditDialog.show()
        },
        createPreset: function () {

            this.$refs.detailsDialog.show()
        }
    },
};
</script>
<style scoped></style>
