<template>
  <div>
    <popup-dialog-element ref="popUpDialog" id="popUpDialog"></popup-dialog-element>
    <progress-overlay-element ref="q_root_overlay" id="progress_overlay"></progress-overlay-element>
    <main>
      <section class="q-display-flex-column flow-center q-center q-grayed-backgroud" style="
          height: 100vh;
          overflow-y: auto;
          background-image: url(images/bg.jpg);
        ">
        <div class="q-overlay q-item-blur"></div>
        <div class="q-section">
          <div class="q-layout-container q-auth-box q-progress-overlay-container" style="padding: 0;">
            <div class="q-layout-row">
              <!-- <div class="q-col-sm-12 q-col-md-12 q-col-lg-6 q-col-xl-6 q-display-flex-column">
                <a href="index.html">
                  <img src="images/gw_icon.png" alt="Gowant writer icon" style="width: 100px" />
                </a>



              </div> -->
              <div class="q-col-sm-12 q-col-md-12 q-col-lg-12 q-col-xl-12 q-display-flex-column flow-center q-center">
                <a href="index.html">
                  <img src="images/gw_icon.png" class="q-auth-icon" alt="Gowant writer icon" />
                </a>
                <form class="q-auth-form form-container" style="max-width: 400px;" method="post" action="#">
                  <p style="margin-top: 0px; font-size: 1em">
                    Contact system administrator about this issue.
                  </p>


                  <div class="q-display-flex-column  q-form-button-container  ">

                    <button data-can-be-disabled type="button" name="button" ref="submitBtn"
                      class="btn anchor-button   " style="align-self:stretch;">Login</button>

                  </div>
                </form>
                <legal-component></legal-component>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import LegalComponent from "./../components/LegalComponent.vue";

import axios from "axios";
import $ from "jquery";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    LegalComponent,
  },
  mounted: function () {
    this.$refs.submitBtn.addEventListener("click", () => {
      this.$router.push("/login")
    });
    // this.$refs.resetPassword.addEventListener("redirectTo", (e) => {
    //   console.log("mt sdfasdf asdf asdf = = = " + JSON.stringify(e.detail));
    //   this.$router.push(e.detail.path);
    // });
  },
  methods: {
    resetPassword: async function (userData) {
      //setUser(foundUser);
      this.toggleDisabableElements(true);
      try {
        var result = await axios.post(
          `${this.$store.getters["urls/baseUrl"]}/resetPassword/`,
          userData
        );
        console.log("df asfasdfa sf " + JSON.stringify(result));
        var data = result.data;
        console.log("i safsfo asf sd fasf " + JSON.stringify(data));
        this.toggleDisabableElements(false);
        var dataAsJSOn = JSON.parse(data);
        if (dataAsJSOn.error !== undefined && dataAsJSOn.error !== null) {
          // failed to regiter
          console.log("faile o registe yse" + JSON.stringify(data));

          this.showMessage(dataAsJSOn.error, true);
        } else {
          this.showMessage("电子邮件已成功发送", false);
        }
      } catch (e) {
        console.log("msdfas fasdf asdf asf " + JSON.stringify(e));

        this.toggleDisabableElements(false);
      }
    },

    toggleDisabableElements: function (isDisabled) {
      var items = $("[data-can-be-disabled]");

      items.each(function () {
        if (isDisabled) $(this).attr("disabled", "disabled");
        else $(this).removeAttr("disabled");
      });
    },
    showMessage: function (text, isError) {
      this.$refs.popUpDialog.showDialog(text, isError);
    },
    showLoadingState(show) {
      this.$refs.q_root_overlay.run = show;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.q-overlay {
  position: absolute;
  top: 0%;
  background-color: transparent;
  width: 100%;
  height: 100%;
}

.q-layout-row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
