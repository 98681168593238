import { auth, USERS_DEFAULT_ROLE } from "../firebaseInit";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";

export default {
  namespaced: true,
  state: {
    user: {},
    username: "",
    useremail: "",
    photoUrl: "",
    emailVerified: false,
    uid: "",
    isReloadingLoadingUser: false,
    isLoggedIn: false,
    status: 0,
    tempData: {},
    phone: "",
    role: USERS_DEFAULT_ROLE,
    loginOperationRunning: false,
    loginErrorMessage: "",
    fetchingUserRole: false,
    fetchingUserRoleErrorStr: ""
  },
  mutations: {
    UPDATE_USER(state, payload) {
      console.log("uodatinf uasodsf " + JSON.stringify(payload));
      if (payload && payload.userPhotoUrl && payload.userPhotoUrl.url) {
        // update photo url
        state.photoUrl = payload.userPhotoUrl.url;
      }

      if (payload && payload.phone) {
        // user phone
        state.phone = payload.phone;
      }
      state.uid = payload.uid;
      state.username = payload.displayName;
      state.useremail = payload.email;
      console.log(
        "is ssuafsdf as " + state.displayName + "  " + state.useremail
      );
      if (payload.emailVerified) state.emailVerified = payload.emailVerified;

      state.user = payload;
      console.log("musdf asdf as " + state.objectId);
    },
    UPDATE_LOGIN_OPERATION(state, payload) {
      state.loginOperationRunning = payload;
    },
    UPDATE_LOADING_USER(state, payload) {
      state.isReloadingLoadingUser = payload;
    },
    UPDATE_FETCHING_ROLE(state, payload) {
      state.fetchingUserRole = payload;
    },
    UPDATE_LOADING_USER_LOGGED_IN(state, payload) {
      state.isLoggedIn = payload;
    },
    UPDATE_TEMP_DATA(state, payload) {
      state.tempData = payload;
    },
    UPDATE_LOGIN_ERROR(state, payload) {
      state.loginErrorMessage = payload;
    },
    UPDATE_STATUS(state, payload) {
      state.status = payload;
    },
    UPDATE_FETCH_USER_ROLE_ERROR(state, payload) {
      state.fetchingUserRoleErrorStr = payload;
    },
    UPDATE_USER_ROLE(state, payload) {
      state.role = payload;
    }
  },
  actions: {
    signOut(context) {
      var data = {
        username: "",
        useremail: ""
      };
      signOut(auth);
      context.commit("UPDATE_USER", data);
      context.commit("UPDATE_USER_ROLE", "");

      context.commit("UPDATE_LOADING_USER_LOGGED_IN", false);
      context.commit("UPDATE_LOADING_USER", false);
    },

    login(context, payload) {
      context.commit("UPDATE_LOGIN_ERROR", "");
      console.log("is logginh in user " + JSON.stringify(payload));
      context.commit("UPDATE_LOGIN_OPERATION", true);
      signInWithEmailAndPassword(auth, payload.email, payload.password)
        .then((userCredentials) => {
          console.log(
            "My user crehs fpasf asdf " + JSON.stringify(userCredentials)
          );

          context.commit("UPDATE_LOGIN_OPERATION", false);
          context.commit("UPDATE_USER", userCredentials);
        })
        .catch((error) => {
          context.commit("UPDATE_LOGIN_OPERATION", false);
          context.commit(
            "UPDATE_LOGIN_ERROR",
            error.message.replace("Firebase:", "")
          );
          context.commit("UPDATE_LOADING_USER_LOGGED_IN", false);
          console.log("error " + error.code + "  " + error.message);
        });
    },

    setUser(context, payload) {
      context.commit("UPDATE_USER", payload);
    },
    setUserRole(context, payload) {
      context.commit("UPDATE_USER_ROLE", payload);
    },
    setIsLoggedIn(context, payload) {
      context.commit("UPDATE_LOADING_USER_LOGGED_IN", payload);
    },
    setLoginOperation(context, payload) {
      context.commit("UPDATE_LOGIN_OPERATION", payload);
    },
    setLoginError(context, payload) {
      context.commit("UPDATE_LOGIN_ERROR", payload);
    },
    setTempUserData(context, payload) {
      context.commit("UPDATE_TEMP_DATA", payload);
    },
    reloadUser(context, payload) {
      context.dispatch("fetchAndUpdateUser", {
        fetch: payload.fetch
      });
    }
  },
  getters: {
    user: function (state) {
      return state.user;
    },
    role: function (state) {
      return state.role;
    },
    username: function (state) {
      return state.username;
    },
    email: function (state) {
      return state.useremail;
    },
    photoUrl: function (state) {
      return state.photoUrl;
    },
    emailVerified: function (state) {
      return state.emailVerified;
    },
    objectId: function (state) {
      return state.objectId;
    },
    isReloadingLoadingUser: function (state) {
      return state.isReloadingLoadingUser;
    },
    isLoggedIn: function (state) {
      return state.isLoggedIn;
    },
    tempData: function (state) {
      return state.tempData;
    },
    phone: function (state) {
      return state.phone;
    },
    status: function (state) {
      return state.status;
    },
    loginOperationRunning: function (state) {
      return state.loginOperationRunning;
    },
    uid: function (state) {
      return state.uid;
    },
    loginError: function (state) {
      return state.loginErrorMessage;
    },
    fullDate: function () {
      const now = new Date();
      const formattedDate = now.toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      });
      console.log(formattedDate);
      return formattedDate;
    },
    greeting: function () {
      return [
        "What are you doing that early?",
        "Good Morning",
        "Good Afternoon",
        "Good Evening"
      ][parseInt((new Date().getHours() / 24) * 4)];
    }
  }
};
