<template>
    <dialog aria-modal="true" aria-label="user Details"
        class=" q-animatable q-delay-fast elevate-5 details-container  q-display-flex-column " ref="detailsDialog">
        <progress-overlay-element ref="q_overlay"></progress-overlay-element>

        <div class="drawer-header  q-display-flex-row q-align-stretch">
            <h3 style="margin: 0px;width: 100%;">{{ name }}</h3>
            <button data-can-be-disabled @click="close" class=" q-display-flex-column q-center q-flex-center-items"
                title="close">
                <i class="ri-close-circle-line q-align-self-right"></i>
            </button>

        </div>
        <div class="q-divider"></div>

        <div class="drawer-content-container">

            <div class=" q-layout-container q-progress-overlay-container content-container">

                <div class="q-layout-row  child-content-container">
                    <form onkeydown="return event.key != 'Enter';" action="#" ref="form" method="post"
                        style="width: 100%;">
                        <div class="q-layout-row  child-content-container">
                            <div class="photo-container q-display-flex-column q-center flow-center">

                                <i class="ri-user-line"></i>
                                <img ref="imgItem" src="" alt="">
                            </div>
                        </div>

                        <div class="form-label-container q-display-flex-row " :style="showNameField">
                            <label class="form-label" for="name">Name</label>

                        </div>
                        <form-input :style="showNameField" ref="nameElement" type="text" name="name" placeHolder="Name"
                            label=" " style="width: 100%;"></form-input>



                        <div class="form-label-container q-display-flex-row ">
                            <label class="form-label" for="contact">Expected Date</label>
                        </div>
                        <form-input ref="dateInput" :isReadonly="expectedDateReadonly" type="date" name="expectedDate"
                            placeHolder="Date" label=" "></form-input>

                        <div class="form-label-container q-display-flex-row ">
                            <label class="form-label" for="contact">Contact</label>
                        </div>
                        <form-input ref="contactInput" :isReadonly="contactReadonly" type="tel" name="contact"
                            placeHolder="contact" label=" "></form-input>

                        <div class="form-label-container q-display-flex-row " :style="showId">
                            <label class="form-label" for="NIN">National Identification Number(NIN)</label>
                        </div>
                        <form-input :isReadonly="enableIdEdit" :isRequired="idRequired" :style="showId" ref="iniInput"
                            type="text" name="id" placeHolder="NIN" label=" "></form-input>
                        <!-- purpose form -->

                        <div class="q-display-flex-row   q-align-content-stretch q-align-stretch">
                            <div class="form-section-container">
                                <div class="form-label-container q-display-flex-row ">
                                    <label class="form-label" for="purpose">Purpose Of Visit</label>
                                </div>

                                <form-input :isReadonly="purposeReadonly" type="text" name="purpose"
                                    placeHolder="Purpose" label=" "></form-input>
                            </div>
                            <div class="form-section-container">
                                <div class="form-label-container q-display-flex-row ">
                                    <label class="form-label" for="personToMeet">Person To Meet</label>
                                </div>

                                <form-input :isReadonly="hostReadonly" type="text" name="personToMeet"
                                    placeHolder="Person To Meet" label=" "></form-input>
                            </div>
                        </div>





                        <p class="error" :style="showError">{{ errorMsg }}</p>
                        <div class=" q-form-button-container q-display-flex-column  ">
                            <button ref="createButton" data-can-be-disabled type="submit" name="button"
                                class="btn anchor-button   " style="align-self:stretch;">Edit</button>
                        </div>
                    </form>
                </div>



            </div>
        </div>
    </dialog>
</template>

<script>
import { showMessage, toggleDisabableElements, isVaidNIN, parseDate, currentDateFormated } from "../../../public/js/utils.js"
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import { doc, updateDoc } from "firebase/firestore";
import { VISITORS_COLLECTION, db } from "../../firebaseInit.js"

var iti;
export default {
    name: "EditPresetDialog",
    props: [
        "expectedDateReadonly",
        "contactReadonly",
        "showNin",
        "purposeReadonly",
        "hostReadonly",
        "showName",
        "showImage",
        "idRequired",

    ],
    components: {

    },
    data() {
        return {
            errorMsg: "",
            name: ""
        }
    },
    computed: {
        showError: function () {
            return this.errorMsg.length == "" ? "display:none" : "display:block"
        },
        hasId: function () { return this.showNin && this.showNin == "true" },
        showId: function () { return !this.showNin || this.showNin != "true" ? "display:none" : "display:block" }
        , showNameField: function () { return !this.showName || this.showName != "true" ? "display:none" : "display:block" }
        , showImageField: function () { return !this.showImage || this.showImage != "true" ? "display:none" : "display:block" },
        enableIdEdit: function () { return this.hasId ? "false" : "" }
    },
    mounted() {
        const input = this.$refs.contactInput.inputElement
        var w = this.$refs.detailsDialog.clientWidth
        console.log("my sdf as fasf = = = = " + w)

        try {
            iti = intlTelInput(input, {

                allowDropdown: false,
                initialCountry: "Ug",
                strictMode: true,
                utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.12/build/js/utils.js"

            })
        } catch (e) {
            console.log("has error " + e)
        }
        this.$refs.form.addEventListener("submit", e => {
            e.preventDefault()
            console.log("msdf " + e)
            this.editPreset()
        })
    },
    methods: {
        updateCheckedItems: function () {
            var data = this.$store.getters["current/currentVisitor"]
            var elements = this.$refs.form.elements
            console.log("my sdi fasd fasf asf " + JSON.stringify(data))
            if (data) {

                Array.from(elements).forEach((input) => {

                    if (input.tagName === "FIELDSET") {

                        if (input.parentNode.tagName === "FORM-INPUT") {
                            var formInput = input.parentNode
                            var element = formInput.inputElement
                            element.value = data[formInput.getAttribute("name")]

                            if (formInput.getAttribute("name") == "id") {
                                element.value = ""
                            }
                        }
                    }
                })

                if (data.photoUrl) {
                    this.$refs.imgItem.setAttribute("src", data.photoUrl)
                } else {
                    this.$refs.imgItem.setAttribute("src", "")
                }
            }
            this.name = data.name
        },
        getSelctedSections: function () {
            var arr = [];

            var chkBoxes = document.getElementsByClassName("checkBoxes1"); // get all check box array
            for (var i = 0; i < chkBoxes.length; i++) {
                if (chkBoxes[i].checked) { // check if checked
                    arr.push(chkBoxes[i].value);
                }
            }
            return arr
        },


        fetchSections: async function () {
            setTimeout(() => { this.updateCheckedItems() }, 10)
            // try {


        },
        show: async function () {

            this.$refs.detailsDialog.showModal()
            this.fetchSections()
            var w = this.$refs.detailsDialog.clientWidth
            const input = this.$refs.contactInput.inputElement

            console.log("my sdf assdpf asd-fasf as=fa =sdf sdf " + w)

            input.style.width = (w - 32) + 'px'

        },

        editPreset: async function () {
            try {

                var elements = this.$refs.form.elements
                var isAllValid = true;
                var userData = {}
                this.errorMsg = ""
                var data = this.$store.getters["current/currentVisitor"]
                console.log("mydfo sad fasfa sf " + JSON.stringify(data))
                if (!data.objectId || data.objectId == "") {
                    this.errorMsg = "Something went wrong, please try reloading the page"
                    showMessage(this.errorMsg)
                    return;
                }

                Array.from(elements).forEach((input) => {

                    if (input.tagName === "FIELDSET") {

                        if (input.parentNode.tagName === "FORM-INPUT") {
                            var formInput = input.parentNode
                            // if (!formInput.isValid && (formInput.inputType !== 5)) {
                            //     isAllValid = false;
                            //     this.errorMsg = "please check your data";
                            // } else {
                            console.log("my sid fasd fasdf as = = = " + formInput.inputType)
                            if (formInput.inputType === 5) {
                                // -:
                                iti.getValidationError();
                                if (formInput.inputValue.length < 11) {
                                    isAllValid = false
                                    this.errorMsg = "Enter a valid phone number"
                                } else {
                                    userData[formInput.getAttribute("name")] = formInput.inputValue
                                }
                            } else if (formInput.getAttribute("name") === "id" && this.hasId) {
                                console.log("is ha dleo sdf sadf  nin " + isVaidNIN(formInput.inputValue) + "  " + formInput.inputValue)
                                if (!isVaidNIN(formInput.inputValue)) {
                                    isAllValid = false
                                    this.errorMsg = "Enter a valid NIN";
                                }
                            } else if (formInput.getAttribute("name") === "expectedDate") {
                                var date_ = parseDate(formInput.inputValue)
                                var date_1 = parseDate(currentDateFormated())
                                const today = date_1
                                console.log("m sdf asd fasf " + date_ + "  " + formInput.inputValue + "  " + ((date_1 <= date_)) + "  " + date_1 + "  " + " " + currentDateFormated())

                                console.log("sdf asf asf  = == ")
                                if (today <= date_) {
                                    userData[formInput.getAttribute("name")] = formInput.inputValue
                                } else {
                                    isAllValid = false
                                    this.errorMsg = "Please enter a date greater or equal to today";
                                }
                                //  isAllValid = false
                                //this.errorMsg = "Enter a valid NIN";
                            }
                            userData[formInput.getAttribute("name")] = formInput.inputValue
                            //  }
                        }
                    }
                })

                if (userData.timeIn && userData.timeOut) {
                    //validate time
                    const date1 = new Date(userData.expectedDate + (" " + userData.timeIn + ":00"))

                    const date2 = new Date(userData.expectedDate + (" " + userData.timeOut + ":00"))


                    if (date1 > date2) {
                        isAllValid = false;
                        this.errorMsg = "Time in cannot be greater than Time out"
                    }
                }

                console.log(" mysd fasod fasdf asf ------  " + JSON.stringify(userData) + "  " + isAllValid)
                if (!isAllValid) {
                    showMessage(this.errorMsg, true)
                } else {
                    console.log("is daf pasdf asf " + VISITORS_COLLECTION)
                    toggleDisabableElements(true)
                    await updateDoc(doc(db, VISITORS_COLLECTION, data.objectId), userData);

                    toggleDisabableElements(false)

                    this.close()
                    this.$emit('edited', '')
                }
                ///   var result 


                // if (result.data.updatedAt != "") {
                //     this.close()
                //     this.$emit('edited', '')
                // } else {

                //     showMessage("Failed to edit preset", true)
                // }

                // this.showOverlay(false)

            } catch (error) {
                console.log("my errpr " + error)
                this.showOverlay(false)

                showMessage("Failed to edit preset", true)
            }

        },
        close: function () {
            var chkBoxes = document.getElementsByClassName("checkBoxes1"); // get all check box array
            for (var i = 0; i < chkBoxes.length; i++) {
                chkBoxes[i].checked = false
            }
            this.$refs.detailsDialog.close()
        },
        showOverlay: function (show) {
            this.$refs.q_overlay.run = show
            toggleDisabableElements(show)
        },


    }
};
</script>
<style scoped>
dialog {

    position: absolute;

}

.showDrawer {
    width: 400px !important;
    opacity: 1 !important;
}

.details-container {

    background-color: white;

    width: 600px;
    right: 0;
    top: 0;
    padding: 0px;

}

.drawer-header button:hover {
    background-color: var(--nav-current)
}

.drawer-header button {

    border-radius: 32px;
    line-height: 1;
    height: 32px;
    width: 10%;
}

.drawer-header button i {
    font-size: 1.4em;
}

.drawer-header {
    height: 32px;
    padding: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
}

.q-divider {
    margin-top: 16px;
    margin-bottom: 0px;
}

.drawer-content-container {
    height: 100%;
    width: 100%;
    overflow: auto;
}



.child-content-container p,
.child-content-container h4 {
    margin: 0;
}

.child-content-container h4 {
    font-family: main_font_bold;
    font-size: .9em;
}

.group-title-container {
    background-color: var(--theme-color-navbar-bg);

}

.q-layout-row {
    width: 100%;
}

.q-button {
    width: 100%;
    background-color: var(--q_red);
    color: white !important;
}

.q-button p {
    color: white;
    margin-left: 8px
}
</style>
