<template>
    <div class="controls-container q-display-flex-row " style="margin-left: 0px;margin-right: 0px;margin-bottom: 32px;">
        <div class="q-display-flex-row"
            style="height: 46px; width: 100%;background-color: #2727290a;border-radius: 25px;padding-left: 8px;padding-left: 8px;">
            <div class=" q-layout-container q-progress-overlay-container content-container">
                <div class="q-layout-row  child-content-container  group-title-container"
                    style="background-color: transparent;">

                    <div class="content-container q-col-12 q-display-flex-row q-text-container">
                        <div class="search-icon-caontainer  q-display-flex-column  q-center q-flex-center-items"
                            style="width:32px;">
                            <i class="ri-search-line"></i>
                        </div>
                        <div ref="form" class=" q-display-flex-row " style="width: 100%;">
                            <input ref="searchInput" type="search" name="" maxlength="200" spellcheck="true"
                                autocomplete="on" id="" :placeholder="searchPlaceHolder"
                                style="background-color: transparent !important;width: 100%;border: 0px !important;">


                        </div>
                        <div :style="itemHasMoreOptions"
                            class="search-icon-caontainer  q-display-flex-column  q-center q-flex-center-items"
                            style="width:52px;margin-right: 0px;">
                            <button style="margin-right: 0px;" type="button" title="Refresh"
                                @click="handleFilterClick($event)">

                                <i class="ri-filter-line"></i>
                            </button>
                        </div>
                        <div :style="itemHasMoreOptions"
                            class="search-icon-caontainer  q-display-flex-column  q-center q-flex-center-items"
                            style="width:52px;margin-right: 0px;">
                            <button style="margin-right: 0px;" type="button" title="Refresh"
                                @click="handleExportClick($event)">

                                <i class="ri-export-line"></i>
                            </button>
                        </div>
                        <div class="search-icon-caontainer  q-display-flex-column  q-center q-flex-center-items"
                            style="width:52px;margin-right: 4px;">
                            <button style="margin-right: 8px;" type="button" title="Refresh"
                                @click="handleControlClick($event)">
                                <i style="font-size: 16px;" class="ri-refresh-line"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="q-layout-row  child-content-container  group-title-container"
                    style="background-color: transparent;margin-top: 8px">

                    <div class="content-container q-col-12 q-display-flex-column q-text-container">

                        <form ref="form" class=" q-display-flex-row " action="#" method="post">

                            <fieldset :style="itemHasActions">

                                <div class="q-password-field">

                                    <select name="actions" ref="supportedActions" :onchange="handleOptionChanged"
                                        style="background-color: transparent !important; border: 0px !important;">
                                        <option v-for="(item) in supportedActions" :key="item.key" :value="item.name">
                                            {{ item.name }}</option>
                                    </select>
                                </div>

                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- <button @click="handleControlClick($event, data)" v-for="(data) in controls" :key="data.key"
            class=" q-display-flex-row q-center q-flex-center-items" style="width: fit-content;height: inherit;"
            :title="data.toolTip">
            <i :class="data.icon"></i>
            <span style="margin-left: 8px">{{ data.name }}</span>
        </button> -->

    </div>
</template>

<script>
export default {
    name: "ContentHeaderComponent",
    props: [
        "controls",
        "searchPlaceHolder",
        "supportedActions",
        "hasMoreOptions",
    ],
    computed: {
        itemHasActions: function () {
            return this.supportedActions && this.supportedActions.length > 0 ? "display:block" : "display:none"
        },
        itemHasMoreOptions: function () {
            console.log("musfo asd fasf " + this.hasMoreOptions)
            return this.hasMoreOptions != undefined && this.hasMoreOptions ? "display:flex" : "display:none"
        }
    },
    data() {
        return {
        }
    },
    mounted: function () {
        this.$refs.searchInput.addEventListener('keyup', (event) => {
            console.log("is msd fosado foasdf asdf ")
            if (event.key == 'Enter' || event.key === 13) {

                this.$emit('searchItem', event, this.$refs.searchInput.value)
            }
        })
    },
    methods: {
        handleControlClick: function (event) {
            //mysdfu asdfasdf
            this.$emit('controlData', event, {
                "key": 0,
                "name": "Refresh",
                "toolTip": "Refresh Presets",
                "icon": "ri-refresh-line"
            },)
        },
        handleFilterClick: function (event) {
            //mysdfu asdfasdf
            this.$emit('filter', event,)
        },
        handleExportClick: function (event) {
            //mysdfu asdfasdf
            this.$emit('exportClick', event,)
        },
        handleOptionChanged: function () {


            this.$emit('currentSelectionChanged', {
                index: this.$refs.supportedActions.selectedIndex,
                value: this.$refs.supportedActions.value
            })
        }

    }
};
</script>
<style scoped>
.q-password-field {
    width: 150px;
    margin: 0 !important;
    margin-left: 8px !important;
}

fieldset {
    margin: 0 !important;
}

dialog {
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.content-container {
    padding: 0 !important;
}

.search-icon-container {
    position: absolute;
    z-index: 999;
    left: 8px;
    top: 0;
    bottom: 0;
}

button:hover {
    background-color: var(--nav-selected)
}


.child-content-container {
    padding: 0 !important;
    height: 100%;
}

.child-content-container input {
    height: 100%;
    padding-left: 32px;
    border-radius: 8px;
}

.controls-container {
    width: 100%;

    background-color: transparent;

    padding-left: 8px;
    padding-right: 8px;
}

button {
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: transparent
}

textarea:focus,
input:focus {
    outline: none;
}

form {
    height: 100%;
}

input[type="button"] {
    width: 32px;
}

form input {
    border: 0px !important;
    padding: 0;
    background-color: transparent
}
</style>
