<template>
    <div class=" q-display-flex-column  q-center q-flex-center-items" style="width: 100%;">
        <div class="main-content-container q-display-flex-column  q-center q-flex-center-items">
            <h2 class="q-align-self-left" style="margin-top: 0; margin-bottom: 0px;">{{
                this.$store.getters["user/greeting"] }}</h2>
            <div class="date-container q-center  q-flex-center-items q-align-self-left q-display-flex-row">
                <span class=" q-display-flex-row q-center  q-flex-center-items"><i class="ri-calendar-line"></i></span>
                <p>{{ this.$store.getters["user/fullDate"] }}</p>
            </div>
            <h2 class="q-align-self-left section-title">Overview</h2>
            <div class="q-layout-container padding-top-0" style="padding: 0;">
                <div class="q-layout-row padding-top-0 padding-bottom-0" style="padding: 0;">

                    <div
                        class="q-col-sm-12 q-col-md-6 q-col-lg-6 q-col-xl-6 q-display-flex-column flow-center padding-top-0  padding-bottom-0">

                        <OverviewDataComponent backgroundColor="#CFC9F9" color="#4E3395" :number="visitorTotal"
                            caption="Pending visitors" icon="ri-group-line">
                        </OverviewDataComponent>
                    </div>
                    <div
                        class="q-col-sm-12 q-col-md-6 q-col-lg-6 q-col-xl-6 q-display-flex-column flow-center padding-top-0  padding-bottom-0">

                        <OverviewDataComponent backgroundColor="#E6F3EA" color="#329B57" :number="userTotal"
                            caption="Administrators" icon="ri-user-2-line">
                        </OverviewDataComponent>

                    </div>


                </div>
            </div>
            <!-- <Bar id="my-chart-id" :options="chartOptions" :data="chartData" /> -->
            <h2 class="q-align-self-left section-title">Pending Visitors </h2>
            <div class="q-layout-container padding-top-0" style="padding: 0;">
                <div class="q-layout-row padding-top-0 padding-bottom-0" style="padding: 0;">

                    <div
                        class="q-col-sm-12 q-col-md-12 q-col-lg-12 q-col-xl-12 q-display-flex-column flow-center padding-top-0  padding-bottom-0">
                        <table :class="showTableELement" style="visibility: visible;">
                            <tr style="background-color:transparent;border-radius: 8px;" :key="tableKey">
                                <th v-for="(header) in headers" :key="header.key">
                                    {{ header.name }}
                                </th>

                            </tr>
                            <tr data-can-be-disabled class="q-animatable" v-for="(row) in tableData"
                                :key="row.objectId">
                                <td>
                                    {{ row.name }}
                                </td>
                                <td>
                                    {{ row.expectedDate }}
                                </td>
                                <td>
                                    {{ row.purpose }}
                                </td>
                                <td>
                                    {{ row.personToMeet }}
                                </td>

                                <!-- <OptionsPopup @setCurrent="setCurrent" @controlData="handleRowControlClick"
                                    :controls="rowControls" :rowData="row">
                                </OptionsPopup> -->

                            </tr>
                        </table>

                    </div>


                </div>
            </div>


        </div>
    </div>
</template>

<script>


import { showMessage, joinArrayToStr } from "../../../../public/js/utils.js"

import OverviewDataComponent from '../../../components/admin/OverviewDataComponent.vue'
// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

// import { Bar } from 'vue-chartjs'
// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: "AdminDesktopView",
    props: ["tableData", "hasData", "userTotal", "visitorTotal", "controls", "supportedViewActions", "totalPresets", "headers"],
    components: {
        OverviewDataComponent,

    },
    mounted() {
        console.log("s ad asdf asdf asdfasf " + this.tableData)

    },
    computed: {

        showPlaceHolderELement() {
            return !this.hasData ? "showPlaceHolder" : ""
        },
        showTableELement() {
            return this.hasData ? "" : "hideTable"
        },
        currentPresetData() {
            return this.$store.getters["current/currentVisitor"];
        },
        isCurrentPreset() {
            var data = this.$store.getters["current/currentVisitor"]
            console.log("mi == " + "  ")

            if (data.objectId != undefined && this.currentPresetId == data.objectId)
                return "current-row"
            return ""
        }

    },
    data() {
        return {
            chartData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                datasets: [{ data: [40, 20, 12] }]
            },
            chartOptions: {
                responsive: true
            },
            capturedData: "",
            sections: [

                {
                    key: 'key',
                    name: 'Signout',
                    icon: 'ri-user-received-line',
                    url: '/signout',

                },
            ],


        }
    },

    methods: {
        showMediaCam: function () {
            this.capturedData = ""
            this.$refs.mediaComponent.show()
        },
        handleAccepted: function () {

            this.capturedData = this.$refs.mediaComponent.getImageData()
            console.log("mysd f asdf asf " + this.$refs.mediaComponent.getImageData())

        },
        showDrawer: function () { this.$refs.drawer.show() },
        handleCurrentSelectionChanged: function (data) {

            this.$emit('handleCurrentSelectionChanged', data)
        },
        formatData: function (data) {
            return joinArrayToStr(data)
        },
        handleSearch: function (event, data) {
            this.$emit('handleSearch', event, data)
            // console.trace()
            // if (!data || data === "") {
            //     this.loadPresets()
            // } else {
            //     this.loadPresets({
            //         query: data
            //     })
            // }
        },

        viewUserDetails: function (event, data) {
            // this.$emit('viewUserDetails', event, data)
            this.$store.dispatch("current/setCurrentVisitor", data)
            this.editPreset()
        },
        setCurrent: function (event, row) {
            // var data_ = this.tableData.filter(a => {
            //     return a.objectId === row.objectId
            // })

            // this.$store.dispatch("current/setCurrentVisitor", data_.length > 0 ? data_[0] : row)

            this.$emit('setCurrent', event, row)
        },
        handleRowControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data) + " ***** " + JSON.stringify(this.currentPresetData))
            if (this.currentPresetData.objectId) {
                if (data.key === 0) {
                    this.editPreset()
                } else if (data.key === 1) {
                    this.$refs.editDialog.startDeleteUser()
                }
            } else {
                showMessage("Please select a visitor ", false)
            }
        },
        loadPresets: function (data) {
            this.$emit('loadPresets', data)
        },
        handleControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data))
            if (data.key === 1) {
                this.createPreset()
            } else if (data.key === 0) {
                this.loadPresets()
            } else if (data.key === 2) {
                if (this.currentPresetData.objectId) {
                    this.editPreset()
                } else {
                    showMessage("Please select a visitor ", false)
                }
            } else if (data.key === 3) {

                if (this.currentPresetData.objectId) {
                    this.$refs.editDialog.startDeleteUser()
                } else {
                    showMessage("Please select a visitor ", false)
                }
            }
        },
        editPreset: function () {
            this.$refs.mainEditDialog.show()
        },
        createPreset: function () {

            this.$refs.detailsDialog.show()
        }
    },
};
</script>
<style scoped></style>
