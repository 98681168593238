<template>
    <div class=" q-display-flex-column  " style="width: 100%;">

        <DesktopUsersView :style="visibleOnAndroid" @handleCurrentSelectionChanged="handleCurrentSelectionChanged"
            @handleSearch="handleSearch" :rowsData="rowsData" :hasData="hasData" :currentPresetId="currentPresetId"
            @viewUserDetails="viewUserDetails" @setCurrent="setCurrent" @loadUsers="loadUsers"
            :currentUserData="currentUserData" :isCurrentPreset="isCurrentPreset" :showTableELement="showTableELement"
            :showPlaceHolderELement="showPlaceHolderELement" :rowControls="rowControls" :controls="controls"
            :supportedViewActions="supportedViewActions" :totalPresets="totalPresets"
            @handleControlClick="handleControlClick" :currentUserId="currentUserId" :headers="headers">
        </DesktopUsersView>

        <AndroidUsersView :style="visibleOnDesktop" @handleCurrentSelectionChanged="handleCurrentSelectionChanged"
            @handleSearch="handleSearch" :rowsData="rowsData" :hasData="hasData" :currentPresetId="currentPresetId"
            @viewUserDetails="viewUserDetails" @setCurrent="setCurrent" @loadUsers="loadUsers"
            :currentUserData="currentUserData" :isCurrentPreset="isCurrentPreset" :showTableELement="showTableELement"
            :showPlaceHolderELement="showPlaceHolderELement" :rowControls="rowControls" :controls="controls"
            :supportedViewActions="supportedViewActions" :totalPresets="totalPresets"
            @handleControlClick="handleControlClick" :currentUserId="currentUserId" :headers="headers">

        </AndroidUsersView>
    </div>
</template>

<script>
import DesktopUsersView from '../sections/desktop/DesktopUsersView.vue'
import AndroidUsersView from '../sections/android/AndroidUsersView.vue'
import { showLoadingState, showMessage } from "../../../public/js/utils.js"

import { collection, getDocs } from "firebase/firestore";
import { db, USERS_COLLECTION, MAX_SMALL_DEVICE_WIDTH } from "../../firebaseInit.js"



export default {
    name: "users-compoent",
    components: {

        DesktopUsersView,
        AndroidUsersView
    },
    props: {
        users: [],
    },
    computed: {
        visibleOnDesktop() {

            return this.deviceWidth > MAX_SMALL_DEVICE_WIDTH ? "display:none !important" : "display:flex"
        },
        visibleOnAndroid() {

            return this.deviceWidth <= MAX_SMALL_DEVICE_WIDTH ? "display:none !important" : "display:flex"
        },
        isLoadingUser() {
            return this.$store.getters["admin/isReloadingLoadingUser"];
        },
        isLoggedIn() {
            return this.$store.getters["admin/isLoggedIn"];
        },
        currentUserData() {
            return this.$store.getters["current/currentUser"];
        },
        isCurrentUser() {
            var data = this.$store.getters["current/currentUser"]
            console.log("mi == " + "  ")

            if (data.objectId != undefined && this.currentUserId == data.objectId)
                return "current-row"
            return ""
        }


    },
    watch: {
        isLoadingUser(new_, old_) {
            if (new_ != old_) {
                this.loadUsers();
            }
        },
        currentUserData(new_, old_) {

            if (new_ != old_) {
                this.setCurrentUserId();
            }
        },

    },
    methods: {
        nextPage: function () {

            this.$refs.footerItem.generateQuery(true)
        }, polishData: function (data) {

            return data ? data : 0

        },
        prevPage: function () {
            this.$refs.footerItem.generateQuery(false)
        },

        setCurrentUserId: function () {
            this.currentUserId = this.currentUserData && this.currentUserData.objectId ? this.currentUserData.objectId : ""

        },
        handleControlClick: function (event, data) {
            console.log("is clisdf asdfasf " + JSON.stringify(data))

            if (data.key === 0) {
                this.loadUsers()
            } else if (data.key === 1) {
                this.$refs.createDialog.show()
            } else {
                if (!this.hasData) {
                    showMessage("No users created")
                } else {
                    var userData = this.$store.getters["current/currentUser"]
                    if (!userData.objectId) {
                        this.setCurrent(null, this.rowsData[0])
                    }
                }
                if (data.key === 3) {
                    this.$refs.detailsDialog.deleteUser()
                } else if (data.key === 2) {
                    this.$refs.editDialog.show()
                }
            }
        },
        handleSearch: function (event, data) {
            console.log("my dattasdaD  " + data)
            console.trace()
            if (!data || data === "") {
                this.loadUsers()
            } else {
                this.loadUsers({
                    query: `where={"username":"${data}"}`
                })
            }
        },
        loadUsers: async function (data) {
            this.$store.dispatch("current/setCurrentUser", {})



            // let url = `${this.$store.getters["urls/adminBaseUrl"]}/users/all/limit=${499}`
            // if (data) {
            //     if (data.limit)
            //         url = `${this.$store.getters["urls/adminBaseUrl"]}/users/all/limit=${data.limit}`
            //     else if (data.query)
            //         url = `${this.$store.getters["urls/adminBaseUrl"]}/users/all/${data.query}`
            // }

            try {
                console.log("is laodsdofaso fasf asf  ")
                showLoadingState(true)
                var isQueringName = false
                var searchData = data
                var querySnapshot = undefined
                if (data) {

                    // const visitorsRef = collection(db, USERS_COLLECTION);
                    // const q = query(visitorsRef, where("name", "==", data.query));
                    // console.log("my sdfi asdf asf = = = = = = " + data.query)
                    // querySnapshot = await getDocs(q);
                    //
                    isQueringName = true;
                    console.log("us dfo asfoasdof asdfa sdf ")
                    // showLoadingState(false)
                    // this.rowsData = [];
                    // querySnapshot.forEach((doc) => {
                    //     console.log("is rsdofasfa asdfas =df=asd a ")
                    //     // doc.data() is never undefined for query doc snapshots
                    //     console.log(doc.id, " => ", doc.data());
                    //     console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
                    //     const data = doc.data();
                    //     data.objectId = doc.uid

                    //     this.rowsData.push(data)
                    // });
                    console.log("is fosdfoas fasd f  == = = == ")
                }
                querySnapshot = await getDocs(collection(db, USERS_COLLECTION));




                this.rowsData = [];
                showLoadingState(false)
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    var targetData = true
                    if (isQueringName) {
                        targetData = data.name.includes(searchData.query)
                    } else {
                        targetData = true
                    }
                    console.log("is fetchsdf asf " + this.rowsData)
                    console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);

                    data.objectId = doc.data().uid
                    if (targetData)
                        this.rowsData.push(data)
                });
                this.hasData = this.rowsData.length > 0
            } catch (error) {
                showLoadingState(false)
                showMessage("Failed to load users", true)
                console.log("is failed " + error)
            }

        },
        countUsers: async function () {

            // try {
            //     var result = await axios.get(
            //         `${this.$store.getters["urls/adminBaseUrl"]}/users/all/limit=0&count=1`,
            //     );

            //     this.total = result.data.count;
            // } catch (error) {

            //     showMessage("Failed to load users", true)
            //     console.log("is failed " + error)
            // }
        },
        viewUserDetails: function (event, row) {
            this.$store.dispatch("current/setCurrentUser", row)
            console.log("is sentsdf asdfasdf " + JSON.stringify(row))
            this.$refs.detailsDialog.show(event, row)
        },
        setCurrent: function (event, row) {
            console.log("settincuretn susdf  = = = = = " + JSON.stringify(row))
            var data_ = this.rowsData.filter(a => {
                return a.objectId === row.objectId
            })
            this.$store.dispatch("current/setCurrentUser", data_.length > 0 ? data_[0] : row)
        },
        editPreset: function () {
            this.$refs.editDialog.show()
        },
        handleRowControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data) + "  " + JSON.stringify(this.currentUserData))
            if (this.currentUserData.objectId) {
                if (data.key === 2) {
                    this.$refs.createDialog.show()
                } else if (data.key == 0) {
                    this.$refs.editDialog.show()
                } else if (data.key == 1) {
                    this.$refs.detailsDialog.deleteUser()
                }
            } else {

                showMessage("Please select a user ", false)

            }
        },
    },
    mounted: function () {
        console.log("relao use ")
        this.deviceWidth = window.innerWidth
        this.loadUsers();
    },
    data() {

        return {
            currentUserId: "",
            total: 0,
            deviceWidth: 0,
            rowControls: [

                {
                    "key": 0,
                    "name": "Edit",
                    "toolTip": "Edit Admin",

                },

                {
                    "key": 1,
                    "name": "Delete",
                    "toolTip": "Delete Admin",

                },

            ],

            controls: [
                {
                    "key": 0,
                    "name": "Refresh",
                    "toolTip": "Refresh Presets",
                    "icon": "ri-refresh-line"
                },
                {
                    "key": 1,
                    "name": "Add",
                    "toolTip": "Add Admin",
                    "icon": "ri-add-circle-line"
                },
                {
                    "key": 2,
                    "name": "Edit",
                    "toolTip": "Edit Admin",
                    "icon": "ri-pencil-line"
                },
                {
                    "key": 3,
                    "name": "Delete",
                    "toolTip": "Delete Admin",
                    "icon": "ri-delete-bin-line"
                },

            ],
            headers: [

                { "name": "Name", "key": "" },
                { "name": "Email", "key": "" },
                { "name": "Contact", "key": "" },
                { "name": "Role", "key": "" },


            ],
            rowsData: []

        }
    }
};
</script>

<style scoped>
.main-content-container {
    position: relative;
}
</style>
