<template>
    <div :style="visibleOnDesktop" class="q-display-flex-column "
        style="width: 100vw;  background-color: var(--admin-canvas-bg);max-height: 100vh;overflow: hidden">

        <div class="q-display-flex-row " style="width: 100%;min-height: 56px !important">
            <AndroidUserHeaderComponent @showDrawer="showDrawer">
            </AndroidUserHeaderComponent>

        </div>
        <div class="q-display-flex-column  ">


            <div class="q-display-flex-column " style="width: 100%">

                <router-view> </router-view>
            </div>
        </div>

    </div>
    <AndroidLeftDrawerComponent ref="drawer" :sections="sections"></AndroidLeftDrawerComponent>

</template>

<script>


import AndroidUserHeaderComponent from '../../../../src/components/android/AndroidUserHeaderComponent.vue'

import AndroidLeftDrawerComponent from '../../../../src/components/AndroidLeftDrawerComponent.vue'
import { MAX_SMALL_DEVICE_WIDTH } from "../../../firebaseInit.js"
export default {
    name: "AdminPanelAndroid",

    components: {
        AndroidUserHeaderComponent,
        AndroidLeftDrawerComponent

        // DetailsComponent
    },
    methods: {
        showDrawer: function () { this.$refs.drawer.show() },

    },
    created() {
        this.deviceWidth = window.innerWidth

    },
    mounted: function () {
        this.deviceWidth = window.innerWidth


    },
    computed: {
        visibleOnDesktop() {
            console.log("isd fasdp fasdf asdf [ = == = = = = = =] " + (this.deviceWidth > MAX_SMALL_DEVICE_WIDTH) + "  " + this.deviceWidth + "  " + MAX_SMALL_DEVICE_WIDTH)
            return !this.deviceWidth || this.deviceWidth > MAX_SMALL_DEVICE_WIDTH ? "display:none !important" : "display:flex"
        },
    },
    data() {
        return {

            capturedData: "",
            sections: [
                {
                    key: 'key',
                    name: 'Overview',
                    icon: 'ri-home-5-line',
                    url: '/admin/overview',

                },
                {
                    key: 'key',
                    name: 'Administrators',
                    icon: 'ri-user-2-line',
                    url: '/admin/employees',

                },

                {
                    key: 'key',
                    name: 'Visitors',
                    icon: 'ri-group-line',
                    url: '/admin/visitors',

                },


            ],


        }
    },

};
</script>
<style scoped></style>
