<template>
  <div class="q-display-flex-column flow-center q-center">
    <p style="
        font-size: 0.8em;
        margin-top: 32px;
        opacity: 0.7;
        max-width: 100% !important;
      ">
      Copyright © Hr Pro. All rights reserved.
    </p>
  </div>
</template>
<script>
import { ref, onMounted, onUpdated } from "vue";
const el = ref();
onMounted(() => {
  console.log("msduf asf  = = = == = ==");
});
onUpdated(() => {
  console.log("msduf asf  = = = == = ==");
});
console.log("msf sf " + el.value);
export default {
  name: "legal-component",
  data() {
    return {};
  },
};
</script>
