<template>
    <div class="q-banner q-display-flex-row q-align-stretch  " style="margin-top: 24px;background-color: white;">
        <div class="q-banner-icon-container q-display-flex  flow-center q-center">
            <span class="q-display-flex  flow-center q-center" :style="bgStyle">
                <i :class="icon" :style="itemColor"></i>
            </span>
        </div>

        <div class="q-banner-container q-display-flex-column">
            <h4>{{ number }}</h4>
            <p>{{ caption }}.</p>
        </div>

    </div>
</template>

<script>
export default {
    name: "ContentHeaderComponent",
    props: [
        "number",
        "caption",
        "icon",
        "color",
        "backgroundColor"
    ],
    computed: {
        bgStyle() {
            return `background-color:${this.backgroundColor}`

        },
        itemColor() {
            return `color:${this.color} !important`

        }
    },
    data() {
        return {

        }
    },
    mounted: function () {

    },
    methods: {
        handleControlClick: function (event, data) {
            //mysdfu asdfasdf
            this.$emit('controlData', event, data)
        }

    }
};
</script>
<style scoped>
.q-banner {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    width: 95%;
}

.content-container {
    padding: 0 !important;
}

.q-banner-container p {
    font-size: 14px;

}

.banner-button span h2 {
    font-size: 16px;
    margin: 0;
    color: var(--gw_primary_color);
}

.q-banner-icon-container span {
    background-color: var(--accentColor);
    width: 42px;
    height: 42px;
    border-radius: 50% 50%;
    margin-right: 16px;
}

.q-banner-icon-container span i {
    font-size: 20px;
}

.q-banner-container h4 {
    font-family: main_font_bold;
    font-size: 30px;
    margin: 0;
}

.q-banner-container p {
    margin: 0;
}
</style>
