import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";

import NotFound from "@/views/404.vue";

import Signout from "./../views/Signout.vue";
import Login from "./../views/Login.vue";
import ResetPassword from "./../views/ResetPassword.vue";
//import VerifyAccount from "./../views/VerifyAccountView.vue";

import AdminOverView from "./../views/admin/OverView.vue";

import UsersView from "./../views/admin/UsersView.vue";
import PresetsView from "../views/admin/VisitorsView.vue";
import UserVisitorsView from "../views/user/VisitorsView.vue";
//import Ai from "./../views/admin/AIView.vue";
//import AiTypes from "./../views/admin/AiTypes.vue";
import UserWebsites from "../views/admin/AdminHome.vue";
import AdminVisitors from "../views/user/AdminVisitors..vue";
const routes = [
  {
    path: "/",
    component: Home
  },
  {
    path: "/login",
    component: Login
  },

  {
    path: "/forgotPassword",
    component: ResetPassword
  },
  {
    path: "/signout",
    component: Signout
  },

  {
    path: "/:notFound",
    component: NotFound
  },
  {
    path: "/admin",
    component: AdminOverView,

    children: [
      {
        path: "employees",
        name: "employees",
        component: UsersView
      },
      {
        path: "overview",
        name: "overview",
        component: UserWebsites
      },
      {
        path: "visitors",
        name: "visitors",
        component: PresetsView
      }
    ]
  },
  {
    path: "/all-visitors",
    component: UserVisitorsView
  },
  {
    path: "/admin-visitors",
    component: AdminVisitors
  }
];
const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes
});
export default router;
