import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import mitt from "mitt";
const emitter = mitt();
import store from "./store";
import excel from "vue-excel-export";

const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.use(excel);
app.use(router).use(store).mount("#app");

app.config.compilerOptions.isCustomElement = (tag) => {
  return tag === "form-input" || tag === "progress-overlay-element"; // (return true)
};
