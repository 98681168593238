<template>
    <dialog aria-modal="true" aria-label="user Details"
        class=" q-animatable q-delay-fast elevate-5 details-container  q-display-flex-column " ref="detailsDialog">
        <progress-overlay-element ref="q_overlay"></progress-overlay-element>

        <div class="drawer-header  q-display-flex-row q-align-stretch">
            <h3 style="margin: 0px;width: 100%;">{{ name }}</h3>
            <button data-can-be-disabled @click="close" class=" q-display-flex-column q-center q-flex-center-items"
                title="close">
                <i class="ri-close-circle-line q-align-self-right"></i>
            </button>

        </div>
        <div class="q-divider"></div>

        <div class="drawer-content-container">

            <div class=" q-layout-container q-progress-overlay-container content-container">

                <div class="q-layout-row  child-content-container">
                    <form action="#" ref="form" method="post" style="width: 100%;">
                        <div class="form-label-container q-display-flex-row ">
                            <label class="form-label">Photo</label>
                        </div>
                        <div class="q-layout-row  child-content-container">
                            <div class="photo-container q-display-flex-column q-center flow-center" ref="photoContainer"
                                style="cursor: pointer;" title="Click to take a photo">

                                <i class="ri-user-line"></i>
                                <img ref="imgItem" :src="imageData" alt="">
                            </div>
                        </div>




                        <div class="form-label-container q-display-flex-row ">
                            <label class="form-label" for="NIN">National Identification Number(NIN)</label>
                        </div>
                        <form-input ref="iniInput" type="text" name="id" placeHolder="NIN" label=" "></form-input>



                        <!-- time form -->

                        <div class="q-display-flex-row   q-align-content-stretch q-align-stretch">
                            <div class="form-section-container">
                                <div class="form-label-container q-display-flex-row ">
                                    <label class="form-label" for="timeIn">Time In</label>
                                </div>

                                <form-input :isRequired="isTimeInRequired" type="time" name="timeIn"
                                    placeHolder="Time in" label=" " ref="timeInElement"></form-input>
                            </div>
                            <div class="form-section-container">
                                <div class="form-label-container q-display-flex-row ">
                                    <label class="form-label" for="personToMeet">Time out</label>
                                </div>

                                <form-input :isRequired="isTimeOutRequired" type="time" name="timeOut"
                                    placeHolder="Time out" ref="timeOutElement" label=" "></form-input>
                            </div>
                        </div>

                        <div class="form-label-container q-display-flex-row ">
                            <label class="form-label" for="personToMeet">Address</label>
                        </div>
                        <form-input ref="addressInput" type="text" name="address" placeHolder="Address"
                            label=" "></form-input>

                        <p class="error" :style="showError">{{ errorMsg }}</p>
                        <div class=" q-form-button-container q-display-flex-column  ">
                            <button ref="createButton" data-can-be-disabled type="submit" name="button"
                                class="btn anchor-button   " style="align-self:stretch;">{{ title }}</button>
                        </div>
                    </form>
                </div>



            </div>
        </div>
    </dialog>
</template>

<script>
import { showMessage, toggleDisabableElements, jsonConcat, isVaidNIN, parseDate, currentDateFormated, getTime, generateString } from "../../../public/js/utils.js"
import 'intl-tel-input/build/css/intlTelInput.css';

import { doc, updateDoc } from "firebase/firestore";
import { VISITORS_COLLECTION, db, ON_GOING_STATUS, PENDING_STATUS, COMPLETE_STATUS, storage } from "../../firebaseInit.js"
import { ref, uploadString, getDownloadURL } from "firebase/storage";


export default {
    name: "EditPresetDialog",
    props: [
        "expectedDateReadonly",
        "contactReadonly",
        "showNin",
        "purposeReadonly",
        "hostReadonly",
        "showName",
        "showImage",
        "idRequired",
        "imageData"

    ],
    components: {

    },
    data() {
        return {
            errorMsg: "",
            name: "",
            signingOut: false,
            title: "Sign in Visitor",
            timeOutRequired: false,
            timeInRequired: false,

        }
    },
    computed: {
        showError: function () {
            return this.errorMsg.length == "" ? "display:none" : "display:block"
        },
        hasId: function () { return this.showNin && this.showNin == "true" },
        showId: function () { return !this.showNin || this.showNin != "true" ? "display:none" : "display:block" }
        , showNameField: function () { return !this.showName || this.showName != "true" ? "display:none" : "display:block" }
        , showImageField: function () { return !this.showImage || this.showImage != "true" ? "display:none" : "display:block" },
        enableIdEdit: function () { return this.hasId ? "false" : "" }
        ,
        isTimeOutRequired: function () {
            console.log("is out esaf s=df = =asf " + this.timeOutRequired)
            return !this.timeOutRequired ? "false" : ""
        },
        isTimeInRequired: function () {
            console.log("is time spf    = = =asf " + this.timeInRequired)
            return !this.timeInRequired ? "false" : ""
        }
    },
    mounted() {

        // input.style.width = '465px'
        // try {
        //     iti = intlTelInput(input, {

        //         allowDropdown: false,
        //         initialCountry: "Ug",
        //         strictMode: true,
        //         utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.12/build/js/utils.js"

        //     })
        // } catch (e) {
        //     console.log("has error " + e)
        // }
        this.$refs.form.addEventListener("submit", e => {
            e.preventDefault()
            console.log("msdf " + e)
            this.editPreset()
        })
        this.$refs.photoContainer.addEventListener('click', e => {
            console.log("is csdf asdf " + e)

            this.$emit('showCamera', '')
        })
    },
    methods: {
        updateCheckedItems: function () {
            var data = this.$store.getters["current/currentVisitor"]
            var elements = this.$refs.form.elements

            if (data) {

                Array.from(elements).forEach((input) => {

                    if (input.tagName === "FIELDSET") {

                        if (input.parentNode.tagName === "FORM-INPUT") {
                            var formInput = input.parentNode
                            var element = formInput.inputElement
                            if (data[formInput.getAttribute("name")] != undefined)
                                element.value = data[formInput.getAttribute("name")]


                        }
                    }
                })


            }
            this.name = data.name

            const input = this.$refs.timeInElement.inputElement
            if (data) {
                if (data.status === PENDING_STATUS) {
                    this.title = "Sign In Visitor"
                    input.value = getTime()
                    this.timeOutRequired = false
                    this.timeInRequired = true
                    this.signingOut = false
                }
                else {
                    this.title = "Sign Out Visitor"
                    this.$refs.timeOutElement.inputElement.value = getTime()
                    this.timeOutRequired = true
                    this.timeInRequired = false
                    this.signingOut = true
                }


                if (data.photoUrl) {
                    this.$refs.imgItem.setAttribute("src", data.photoUrl)
                } else {
                    this.$refs.imgItem.setAttribute("src", "")
                }
            }

        },
        getSelctedSections: function () {
            var arr = [];

            var chkBoxes = document.getElementsByClassName("checkBoxes1"); // get all check box array
            for (var i = 0; i < chkBoxes.length; i++) {
                if (chkBoxes[i].checked) { // check if checked
                    arr.push(chkBoxes[i].value);
                }
            }
            return arr
        },


        fetchSections: async function () {
            setTimeout(() => { this.updateCheckedItems() }, 10)
            // try {


        },
        show: async function () {

            this.$refs.detailsDialog.showModal()
            this.fetchSections()
            //   var w = this.$refs.detailsDialog.clientWidth

        },

        editPreset: async function () {
            try {


                var data = this.$store.getters["current/currentVisitor"]

                var elements = this.$refs.form.elements
                var isAllValid = true;
                var userData = {}
                this.errorMsg = ""

                if (!data.objectId || data.objectId == "") {
                    this.errorMsg = "Something went wrong, please try reloading the page"
                    showMessage(this.errorMsg)
                    return;
                }
                console.log("is do fasdof asf ")
                Array.from(elements).forEach((input) => {

                    if (input.tagName === "FIELDSET") {

                        if (input.parentNode.tagName === "FORM-INPUT") {
                            var formInput = input.parentNode
                            // if (!formInput.isValid && (formInput.inputType !== 5)) {
                            //     isAllValid = false;
                            //     this.errorMsg = "please check your data";
                            // } else {
                            console.log("my sid fasd fasdf as = = = " + formInput.inputType)
                            if (formInput.inputType === 5) {
                                // -:

                                if (formInput.inputValue.length < 11) {
                                    isAllValid = false
                                    this.errorMsg = "Enter a valid phone number"
                                } else {
                                    userData[formInput.getAttribute("name")] = formInput.inputValue
                                }
                            } else if (formInput.getAttribute("name") === "id" && this.hasId) {
                                console.log("is ha dleo sdf sadf  nin " + isVaidNIN(formInput.inputValue) + "  " + formInput.inputValue)
                                if (!isVaidNIN(formInput.inputValue)) {
                                    isAllValid = false
                                    this.errorMsg = "Enter a valid NIN";
                                }
                            } else if (formInput.getAttribute("name") === "expectedDate") {
                                var date_ = parseDate(formInput.inputValue)
                                var date_1 = parseDate(currentDateFormated())
                                const today = date_1
                                console.log("m sdf asd fasf " + date_ + "  " + formInput.inputValue + "  " + ((date_1 <= date_)) + "  " + date_1 + "  " + " " + currentDateFormated())

                                console.log("sdf asf asf  = == ")
                                if (today <= date_) {
                                    userData[formInput.getAttribute("name")] = formInput.inputValue
                                } else {
                                    isAllValid = false
                                    this.errorMsg = "Please enter a date greater or equal to today";
                                }
                                //  isAllValid = false
                                //this.errorMsg = "Enter a valid NIN";
                            }
                            userData[formInput.getAttribute("name")] = formInput.inputValue
                            //  }
                        }
                    }
                })
                console.log("is dsd fasdf af  fasdof asf  ")
                if (userData.timeIn && userData.timeOut) {
                    //validate time
                    const date1 = new Date(userData.expectedDate + (" " + userData.timeIn + ":00"))

                    const date2 = new Date(userData.expectedDate + (" " + userData.timeOut + ":00"))


                    if (date1 > date2) {
                        isAllValid = false;
                        this.errorMsg = "Time in cannot be greater than Time out"
                    }
                }

                console.log(" mysd fasod fasdf asf ------  " + JSON.stringify(userData) + "  " + isAllValid)
                console.log("is do s sdf sdf sdf dof asf ")
                if (!this.signingOut) {
                    if (this.imageData.length == 0) {
                        isAllValid = false;
                        this.errorMsg = "Please take a visitor`s photo"
                    }
                }


                if (!isAllValid) {
                    showMessage(this.errorMsg, true)
                } else {
                    console.log("is daf pasdf asf " + VISITORS_COLLECTION)
                    toggleDisabableElements(true)

                    if (data.status === ON_GOING_STATUS) {
                        userData.status = COMPLETE_STATUS
                    }
                    else {
                        userData.status = ON_GOING_STATUS
                    }
                    console.log("iss fasdf asf of asf ")
                    if (!this.signingOut) {


                        console.log("my sidf asdfsd fs " + this.imageData)
                        const fileName = generateString(5) + ".jpeg";

                        const message = this.imageData.replace("data:image/jpeg;base64,", "")

                        console.log("fmeisdf asdf as f" + fileName)
                        const path = 'images/' + fileName;
                        const storageRef = ref(storage, path);
                        console.log("msf asdf asf asdf " + storageRef)
                        // Base64 formatted string
                        // const message2 = '5b6p5Y+344GX44G+44GX44Gf77yB44GK44KB44Gn44Go44GG77yB';
                        const snapshot = await uploadString(storageRef, message, 'base64')
                        console.log("is usdpf asdf asf asdf " + JSON.stringify(snapshot))

                        const url = await getDownloadURL(storageRef)
                        console.log("my sdf pasdfa sdf " + url)

                        userData.photoUrl = url
                        userData.path = path

                        // await updateDoc(doc(db, VISITORS_COLLECTION, data.objectId), userData);

                        // toggleDisabableElements(false)

                        // this.close()
                        // this.$emit('edited', '')
                    }

                    await updateDoc(doc(db, VISITORS_COLLECTION, data.objectId), userData);

                    toggleDisabableElements(false)
                    console.log("smsd foasdo fasdf asf d=f s=d f=sdf sf  " + this.signingOut)
                    if (!this.signingOut) {
                        var result = jsonConcat(data, userData)
                        console.log("is sdp fpasd fasf asfa sf")
                        this.print(result);

                    }
                    this.close()
                    this.$emit('edited', '')
                }


            } catch (error) {
                console.log("my errpr " + error)
                this.showOverlay(false)

                showMessage("Failed to edit preset", true)
            }

        },
        print: function (data) {

            console.log("mydfo sad fasfa sf " + JSON.stringify(data))

            //var skippedKeys = ["photoUrl", "createdBy", "status", "path", "id", "objectId", "statusText"]
            var mData = []
            // for (var key in data) {
            //     if (skippedKeys.includes(key)) {
            //         continue
            //     }
            //     mData.push({
            //         "Title": key,
            //         "Content": data[key]
            //     })
            // }
            mData.push({
                "Title": "Name",
                "Content": data["name"]
            })
            mData.push({
                "Title": "Date",
                "Content": data["expectedDate"]
            })
            mData.push({
                "Title": "Person To Meet",
                "Content": data["personToMeet"]
            })
            mData.push({
                "Title": "Purpose",
                "Content": data["purpose"]
            })
            mData.push({
                "Title": "Address",
                "Content": data["address"]
            })
            mData.push({
                "Title": "Contact",
                "Content": data["contact"]
            })
            mData.push({
                "Title": "Time In",
                "Content": data["timeIn"]
            })
            mData.push({
                "Title": "Time Out",
                "Content": data["timeOut"]
            })

            mData.push({
                "Title": "Visitor`s Signature", "Content": ""
            })
            console.log("my sdf asfs f " + mData)
            window.createTable(mData)
            window.printAllTable()

        },
        close: function () {
            var data = this.$store.getters["current/currentVisitor"]
            var elements = this.$refs.form.elements

            if (data) {

                Array.from(elements).forEach((input) => {

                    if (input.tagName === "FIELDSET") {

                        if (input.parentNode.tagName === "FORM-INPUT") {
                            var formInput = input.parentNode
                            var element = formInput.inputElement
                            element.value = ""
                        }
                    }
                })


            }
            this.$refs.detailsDialog.close()
        },
        showOverlay: function (show) {
            this.$refs.q_overlay.run = show
            toggleDisabableElements(show)
        },


    }
};
</script>
<style scoped>
dialog {

    position: absolute;
    padding: 16px;
}

.showDrawer {
    width: 400px !important;
    opacity: 1 !important;
}

.details-container {

    background-color: white;

    width: 600px;
    right: 0;
    top: 0;
    padding: 16px;

}

.drawer-header button:hover {
    background-color: var(--nav-current)
}

.drawer-header button {

    border-radius: 32px;
    line-height: 1;
    height: 32px;
    width: 10%;
}

.drawer-header button i {
    font-size: 1.4em;
}

.drawer-header {
    height: 32px;
    padding: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
}

.q-divider {
    margin-top: 16px;
    margin-bottom: 0px;
}

.drawer-content-container {
    height: 100%;
    width: 100%;
    overflow: auto;
}



.child-content-container p,
.child-content-container h4 {
    margin: 0;
}

.child-content-container h4 {
    font-family: main_font_bold;
    font-size: .9em;
}

.group-title-container {
    background-color: var(--theme-color-navbar-bg);

}

.q-layout-row {
    width: 100%;
}

.q-button {
    width: 100%;
    background-color: var(--q_red);
    color: white !important;
}

.q-button p {
    color: white;
    margin-left: 8px
}
</style>
