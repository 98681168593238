<template>
    <dialog aria-modal="true" aria-label="user Details"
        class=" q-animatable q-delay-fast elevate-5 details-container  q-display-flex-column " ref="detailsDialog">
        <progress-overlay-element ref="q_overlay"></progress-overlay-element>

        <div class="drawer-header  q-display-flex-row q-align-stretch">
            <h3 style="margin: 0px;width: 100%;">Add Visitor</h3>
            <button data-can-be-disabled @click="close" class=" q-display-flex-column q-center q-flex-center-items"
                title="close">
                <i class="ri-close-circle-line q-align-self-right"></i>
            </button>

        </div>
        <div class="q-divider"></div>

        <div class="drawer-content-container">

            <div class=" q-layout-container q-progress-overlay-container content-container">

                <div class="q-layout-row  child-content-container">
                    <form action="#" ref="form" method="post" style="width: 100%;">
                        <div class="form-label-container q-display-flex-row ">
                            <label class="form-label" for="name">Name</label>

                        </div>
                        <form-input ref="nameElement" type="text" name="name" placeHolder="Name" label=" "></form-input>


                        <div class="form-label-container q-display-flex-row ">
                            <label class="form-label" for="contact">Expected Date</label>
                        </div>
                        <form-input ref="dateInput" type="date" name="expectedDate" placeHolder="Date"
                            label=" "></form-input>

                        <div class="form-label-container q-display-flex-row ">
                            <label class="form-label" for="contact">Contact</label>
                        </div>
                        <form-input ref="contactInput" type="tel" name="contact" placeHolder="Contact"
                            label=" "></form-input>

                        <!-- <div class="form-label-container q-display-flex-row ">
                            <label class="form-label" for="NIN">National Identification Number(NIN)</label>
                        </div>
                        <form-input ref="iniInput" type="text" name="id" placeHolder="NIN" label=" "></form-input> -->

                        <!-- purpose form -->

                        <div class="q-display-flex-row   q-align-content-stretch q-align-stretch">
                            <div class="form-section-container">
                                <div class="form-label-container q-display-flex-row ">
                                    <label class="form-label" for="purpose">Purpose Of Visit</label>
                                </div>

                                <form-input ref="purposeOfVisitInput" type="text" name="purpose" placeHolder="Purpose"
                                    label=" "></form-input>
                            </div>
                            <div class="form-section-container">
                                <div class="form-label-container q-display-flex-row ">
                                    <label class="form-label" for="personToMeet">Person To Meet</label>
                                </div>

                                <form-input ref="personToMeetInput" type="text" name="personToMeet"
                                    placeHolder="Person To Meet" label=" "></form-input>
                            </div>
                        </div>


                        <!-- time form -->

                        <!-- <div class="q-display-flex-row   q-align-content-stretch q-align-stretch">
                            <div class="form-section-container">
                                <div class="form-label-container q-display-flex-row ">
                                    <label class="form-label" for="timeIn">Time In</label>
                                </div>

                                <form-input type="time" name="timeIn" placeHolder="Time in" label=" "></form-input>
                            </div>
                            <div class="form-section-container">
                                <div class="form-label-container q-display-flex-row ">
                                    <label class="form-label" for="personToMeet">Time out</label>
                                </div>

                                <form-input type="time" name="timeOut" placeHolder="Time out" label=" "></form-input>
                            </div>
                        </div> -->

                        <!-- <div class="form-label-container q-display-flex-row ">
                            <label class="form-label" for="personToMeet">Address</label>
                        </div>
                        <form-input ref="addressInput" type="text" name="address" placeHolder="Address"
                            label=" "></form-input> -->


                        <p class="error" :style="showError">{{ errorMsg }}</p>
                        <div class=" q-form-button-container q-display-flex-column  ">
                            <button ref="createButton" data-can-be-disabled type="submit" name="button"
                                class="btn anchor-button   " style="align-self:stretch;">Add</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </dialog>
</template>

<script>
import { generateStr, showMessage, toggleDisabableElements, parseDate, currentDateFormated } from "../../../public/js/utils.js"
import { collection, addDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { VISITORS_COLLECTION, db, auth, PENDING_STATUS } from "../../firebaseInit.js"
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import axios from "axios";


var iti;
axios.defaults.withCredentials = true;
export default {
    name: "CreatePresetDrawer",
    components: {

    },
    data() {
        return {
            errorMsg: "",

        }
    },
    computed: {
        showError: function () {
            return this.errorMsg.length == "" ? "display:none" : "display:block"
        }
    },
    mounted() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log("My us sodfa sdf " + JSON.stringify(user))


                this.$store.dispatch("user/setUser", user);

                if (auth.currentUser)
                    this.$refs.personToMeetInput.inputElement.value = auth.currentUser.email



            } else {
                // this.$router.push("/login")
            }

        })
        this.$refs.form.addEventListener("submit", e => {
            e.preventDefault()
            this.createPreset()
        })
        const input = this.$refs.contactInput.inputElement
        input.style.width = '465px'

        if (auth.currentUser)
            this.$refs.personToMeetInput.inputElement.value = auth.currentUser.email
        try {
            iti = intlTelInput(input, {

                allowDropdown: false,
                initialCountry: "Ug",
                strictMode: true,
                utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.12/build/js/utils.js"

            })
        } catch (e) {
            console.log("has error " + e)
        }
    },
    methods: {
        show: async function () {
            this.$refs.detailsDialog.showModal()
            this.fetchSections()
            var w = this.$refs.detailsDialog.clientWidth
            const input = this.$refs.contactInput.inputElement

            console.log("my sdf assdpf asd-fasf as=fa =sdf sdf " + w)

            input.style.width = (w - 32) + 'px'
        },
        close: function () {
            this.$refs.detailsDialog.close()
        },
        showOverlay: function (show) {
            this.$refs.q_overlay.run = show
            toggleDisabableElements(show)
        },


        getSelctedSections: function () {
            var arr = [];
            console.clear()
            var chkBoxes = document.getElementsByClassName("checkBoxes"); // get all check box array
            for (var i = 0; i < chkBoxes.length; i++) {
                if (chkBoxes[i].checked) { // check if checked
                    arr.push(chkBoxes[i].value);
                }
            }
            return arr
        },


        fetchSections: async function () {
            // try {
            //     if (this.supportedPages.length == 0) {
            //         this.showOverlay(true)
            //         var result = await axios.get(`${this.$store.getters["urls/adminBaseUrl"]}/gw/sections`)
            //         this.supportedPages = result.data
            //         this.showOverlay(false)
            //     }

            // } catch (error) {
            //     console.log("my errpr " + error)
            //     this.showOverlay(false)
            //     showMessage("Failed to fetch pages, please try again", true)
            // }


        },
        createPreset: async function () {
            try {
                var elements = this.$refs.form.elements
                var isAllValid = true;
                var userData = {}
                this.errorMsg = ""


                Array.from(elements).forEach((input) => {

                    if (input.tagName === "FIELDSET") {

                        if (input.parentNode.tagName === "FORM-INPUT") {
                            var formInput = input.parentNode
                            if (!formInput.isValid && (formInput.inputType !== 5)) {
                                isAllValid = false;
                                this.errorMsg = "please check your data";
                            } else {
                                console.log("my sid fasd fasdf as = = = " + formInput.inputType)
                                if (formInput.inputType === 5) {
                                    // -:
                                    iti.getValidationError();
                                    if (formInput.inputValue.length < 11) {
                                        isAllValid = false
                                        this.errorMsg = "Enter a valid phone number"
                                    } else {
                                        userData[formInput.getAttribute("name")] = formInput.inputValue
                                    }
                                }
                                // } else if (formInput.getAttribute("name") === "id") {
                                //     console.log("is ha dleo sdf sadf  nin " + isVaidNIN(formInput.inputValue) + "  " + formInput.inputValue)
                                //     if (!isVaidNIN(formInput.inputValue)) {
                                //         isAllValid = false
                                //         this.errorMsg = "Enter a valid NIN";
                                //     }
                                // }
                                else if (formInput.getAttribute("name") === "expectedDate") {
                                    var date_ = parseDate(formInput.inputValue)
                                    var date_1 = parseDate(currentDateFormated())
                                    const today = date_1
                                    console.log("m sdf asd fasf " + date_ + "  " + formInput.inputValue + "  " + ((date_1 <= date_)) + "  " + date_1 + "  " + " " + currentDateFormated())

                                    console.log("sdf asf asf  = == ")
                                    if (today <= date_) {
                                        userData[formInput.getAttribute("name")] = formInput.inputValue
                                    } else {
                                        isAllValid = false
                                        this.errorMsg = "Please enter a date greater or equal to today";
                                    }
                                    //  isAllValid = false
                                    //this.errorMsg = "Enter a valid NIN";
                                }
                                userData[formInput.getAttribute("name")] = formInput.inputValue
                            }
                        }
                    }
                })
                console.log(" mysd fasod fasdf asf ------  " + JSON.stringify(userData) + "  " + isAllValid)

                // if (userData.timeIn && userData.timeOut) {
                //     //validate time
                //     const date1 = new Date(userData.expectedDate + (" " + userData.timeIn + ":00"))

                //     const date2 = new Date(userData.expectedDate + (" " + userData.timeOut + ":00"))


                //     if (date1 > date2) {
                //         isAllValid = false;
                //         this.errorMsg = "Time in cannot be greater than Time out"
                //     }
                // }

                if (!isAllValid) {
                    showMessage(this.errorMsg, true)
                } else {
                    console.log("is daf pasdf asf " + VISITORS_COLLECTION)
                    userData.createdBy = auth.currentUser.uid
                    userData.status = PENDING_STATUS
                    userData.key = generateStr(10)
                    userData.createdAt = Date.now()
                    userData.editedAt = Date.now()
                    toggleDisabableElements(true)
                    const docRef = await addDoc(collection(db, VISITORS_COLLECTION), userData);

                    toggleDisabableElements(false)
                    console.log("Document written with ID: ", docRef.id);
                    this.close()
                    this.$emit('created', '')
                }
                // var visitorData = {
                //     name:this.$refs. namedElement.inputValue
                // }
                //console.log("is daf pasdf asf " + VISITORS_COLLECTION)
                // const docRef = await addDoc(collection(db, VISITORS_COLLECTION), visitorData);
                //  console.log("Document written with ID: ", docRef.id);

                // var selectedSections = this.getSelctedSections()
                // if (selectedSections.length == 0) {
                //     showMessage("Please select where this preset will be accessed", true)
                //     return
                // }
                // var e = this.$refs.supportedActions
                // this.showOverlay(true)
                // let data = {
                //     name: this.$refs.inputElement.inputValue,
                //     sections: selectedSections,
                //     type: e.options[e.selectedIndex].text,
                // }
                // var result = await axios.post(`${this.$store.getters["urls/adminBaseUrl"]}/presets/create`, data)

                // console.log("my reslt sfa sfas f " + JSON.stringify(result))
                // if (result.data.objectId != "") {
                //     this.close()
                //     this.$emit('created', '')
                // } else {

                //     showMessage("Failed to create preset", true)
                // }

                // this.showOverlay(false)

            } catch (error) {
                console.log("my errpr " + error)
                showMessage(this.errorMsg, true)
                this.errorMsg = "Failed to add visitor"
            }


        }
    }
};
</script>
<style scoped>
dialog {
    position: absolute;
}


.showDrawer {
    width: 400px !important;
    opacity: 1 !important;
}

.details-container {

    background-color: white;
    width: 600px;
    right: 0;
    top: 0;
    padding: 0px;

}

.drawer-header button:hover {
    background-color: var(--nav-current)
}

.drawer-header button {

    border-radius: 32px;
    line-height: 1;
    height: 32px;
    width: 32px;
}

.drawer-header button i {
    font-size: 1.4em;
}

.drawer-header {
    height: 32px;
    padding: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
}

.q-divider {
    margin-top: 16px;
    margin-bottom: 0px;
}

.drawer-content-container {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.iti__tel-input {
    width: 900px !important;
}

.child-content-container p,
.child-content-container h4 {
    margin: 0;
}

.child-content-container h4 {
    font-family: main_font_bold;
    font-size: .9em;
}

.group-title-container {
    background-color: var(--theme-color-navbar-bg);

}

.q-layout-row {
    width: 100%;
}

.q-button {
    width: 100%;
    background-color: var(--q_red);
    color: white !important;
}

.q-button p {
    color: white;
    margin-left: 8px
}
</style>
