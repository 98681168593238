export default {
  namespaced: true,
  state: {
    baseUrl: "https://ember-clear-danthus.glitch.me",
    userStorageKey: "gw_user"
  },

  getters: {
    baseUrl: function (state) {
      return `${state.baseUrl}`;
    },
    userStorageKey: function (state) {
      return `${state.userStorageKey}`;
    }
  }
};
