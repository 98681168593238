<template>
    <div class="q-display-flex-row   "
        style="width: 100%;background-color: var(--admin-canvas-bg);max-height: 100vh;overflow: hidden">
        <popup-dialog-element ref="popUpDialog" id="popUpDialog"></popup-dialog-element>
        <progress-overlay-element ref="q_root_overlay" id="progress_overlay"></progress-overlay-element>

        <DesktopRootAdminOverView :style="visibleOnAndroid"></DesktopRootAdminOverView>

        <AndroidRootAdminOverview :style="visibleOnDesktop" :deviceWidth="deviceWidth">
        </AndroidRootAdminOverview>
    </div>
</template>

<script>

import DesktopRootAdminOverView from '../sections/desktop/DesktopRootAdminOverView.vue'
import AndroidRootAdminOverview from '../sections/android/AndroidRootAdminOverview.vue'
//import DetailsComponent from "./../../components/admin/DetailsComponent.vue"
import { showLoadingState } from "../../../public/js/utils.js"
import { MAX_SMALL_DEVICE_WIDTH } from "../../firebaseInit.js"

export default {
    name: "AdminPanel",
    components: {

        DesktopRootAdminOverView,
        AndroidRootAdminOverview
        // DetailsComponent
    },
    computed: {
        isLoadingUser() {
            return this.$store.getters["admin/isReloadingLoadingUser"];
        },
        visibleOnAndroid() {
            return this.deviceWidth <= MAX_SMALL_DEVICE_WIDTH ? "display:none" : "display:flex"
        },
        visibleOnDesktop() {
            console.log("isd fasdp fasdf asdf " + (this.deviceWidth > MAX_SMALL_DEVICE_WIDTH) + "  " + this.deviceWidth + "  " + MAX_SMALL_DEVICE_WIDTH)
            return this.deviceWidth > MAX_SMALL_DEVICE_WIDTH ? "display:none !important" : "display:flex"
        },
        isLoggedIn() {

            return (
                this.$store.getters["admin/isLoggedIn"] &&
                !this.$store.getters["admin/isReloadingLoadingUser"]
            );
        },
        status() {
            return this.$store.getters["user/status"];
        },
    },
    data() {
        return {
            deviceWidth: 0,
        }
    },
    watch: {
        isLoadingUser(new_, old_) {

            if (new_ != old_) {

                this.redirectUser();
            }
        },
    },
    created() {
        this.deviceWidth = window.innerWidth

    },
    mounted: function () {
        this.deviceWidth = window.innerWidth

        console.log("is mounted  =sd=f sdf=asd f=asdf=  " + this.deviceWidth)
        this.$store.dispatch("admin/reloadUser", {
            url: `${this.$store.getters["urls/adminBaseUrl"]}/checkSession/`,
            fetch: `${this.$store.getters["urls/adminBaseUrl"]}/users/me`,
        });
    },

    methods: {
        showMessage: function (text, isError) {

            this.$refs.popUpDialog.showDialog(text, isError);
        },
        redirectUser: function () {

            showLoadingState(this.isLoadingUser);
            if (this.isLoadingUser) return;

            if (!this.isLoggedIn) {

                // if (this.status == 404 || this.status == 400) {
                //  this.$router.push("/login");
                // redirect to home

                //  } else {
                // this.showMessage("An error ocurred, please try refreshing the page", true);

                //  }

            } else {
                this.$router.push("/console/users");
            }
        },
    }
};
</script>
<style scoped></style>
