// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCYWW0156lLEj2F0Za9Mr9LXXty2o69gVs",
  authDomain: "hrproject-a4a31.firebaseapp.com",
  projectId: "hrproject-a4a31",
  storageBucket: "hrproject-a4a31.appspot.com",
  messagingSenderId: "838031658302",
  appId: "1:838031658302:web:223bb7cacb7c7a9372f06f",
  measurementId: "G-PHHSZFTFTX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const VISITORS_COLLECTION = "Visitors";
const USERS_COLLECTION = "Users";
const USERS_ADMIN_ROLE = "admin";
const USERS_DEFAULT_ROLE = "askari";
const USERS_SUPER_ADMIN_ROLE = "root";
const PENDING_STATUS = 0;
const COMPLETE_STATUS = 1;
const ON_GOING_STATUS = 2;
const MAX_SMALL_DEVICE_WIDTH = 800;
const SUPPORTED_ROLES = [
  { key: "", name: USERS_DEFAULT_ROLE },
  { key: "", name: USERS_ADMIN_ROLE }
];
export {
  auth,
  app,
  db,
  storage,
  VISITORS_COLLECTION,
  USERS_COLLECTION,
  USERS_ADMIN_ROLE,
  USERS_DEFAULT_ROLE,
  SUPPORTED_ROLES,
  USERS_SUPER_ADMIN_ROLE,
  PENDING_STATUS,
  COMPLETE_STATUS,
  ON_GOING_STATUS,
  MAX_SMALL_DEVICE_WIDTH
};
