<template>
    <dialog aria-modal="true" aria-label="user Details"
        class=" q-animatable q-delay-fast elevate-5 details-container  q-display-flex-column " ref="detailsDialog">
        <progress-overlay-element ref="q_overlay"></progress-overlay-element>

        <div class="drawer-header  q-display-flex-row q-align-stretch">
            <h3 style="margin: 0px;width: 100%;">Filter</h3>
            <button data-can-be-disabled @click="loadUsers(true)"
                class=" q-display-flex-column q-center q-flex-center-items" title="Refresh Administrators">
                <i class="ri-loop-left-line"></i>
            </button>
            <button data-can-be-disabled @click="close" class=" q-display-flex-column q-center q-flex-center-items"
                title="close">
                <i class="ri-close-circle-line q-align-self-right"></i>
            </button>

        </div>
        <div class="q-divider"></div>

        <div class="drawer-content-container">

            <div class=" q-layout-container q-progress-overlay-container content-container">

                <div class="q-layout-row  child-content-container">
                    <form action="#" ref="form" method="post" style="width: 100%;">

                        <fieldset>
                            <label for="ai">Administrators</label>
                            <div class="q-password-field">

                                <select name="actions" ref="supportedActions">
                                    <option v-for="(item) in supportedActions" :key="item.key" :value="item.name">
                                        {{ item.name }}</option>
                                </select>
                            </div>

                        </fieldset>
                        <div class="q-display-flex-row   q-align-content-stretch q-align-stretch">
                            <div class="form-section-container">
                                <div class="form-label-container q-display-flex-row ">
                                    <label class="form-label" for="contact">From </label>
                                </div>
                                <form-input isRequired="false" ref="fromDateInput" type="date" name="fromDate"
                                    placeHolder="Date" label=" "></form-input>
                            </div>
                            <div class="form-section-container">
                                <div class="form-label-container q-display-flex-row ">
                                    <label class="form-label" for="contact">To </label>
                                </div>
                                <form-input isRequired="false" ref="toDateInput" type="date" name="toDate"
                                    placeHolder="Date" label=" "></form-input>
                            </div>
                        </div>

                        <p class="error" :style="showError">{{ errorMsg }}</p>
                        <div class=" q-form-button-container q-display-flex-column  ">
                            <button ref="createButton" data-can-be-disabled type="submit" name="button"
                                class="btn anchor-button   " style="align-self:stretch;">Search</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </dialog>
</template>

<script>
import axios from "axios";
import { showLoadingState, showMessage } from "../../../public/js/utils.js"
import { collection, getDocs } from "firebase/firestore";
import { db, USERS_COLLECTION, USERS_SUPER_ADMIN_ROLE, USERS_ADMIN_ROLE } from "../../firebaseInit.js"


axios.defaults.withCredentials = true;
export default {
    name: "VisitorFilter",
    components: {
    },
    data() {
        return {
            errorMsg: "",
            supportedActions: []
        }
    },
    computed: {
        showError: function () {
            return this.errorMsg.length == "" ? "display:none" : "display:block"
        }
    },
    mounted() {

        this.$refs.form.addEventListener("submit", e => {
            e.preventDefault()
            this.search()
        })
    },
    methods: {
        show: async function () {
            this.$refs.detailsDialog.showModal()
            this.loadUsers()
        },
        loadUsers: async function (force) {
            if (this.supportedActions.length > 0) {
                if (!force)
                    return
            }

            try {
                console.log("is laodsdofaso fasf asf  ")
                showLoadingState(true)

                var querySnapshot = await getDocs(collection(db, USERS_COLLECTION));

                this.supportedActions = [{
                    "name": "None"
                }];
                showLoadingState(false)

                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    var targetData = (data.role === USERS_ADMIN_ROLE || data.role === USERS_SUPER_ADMIN_ROLE)

                    console.log("is fetchsdf asf " + this.rowsData)
                    console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);

                    data.objectId = doc.data().uid
                    if (targetData)
                        this.supportedActions.push(data)
                });

            } catch (error) {

                showLoadingState(false)
                showMessage("Failed to load admininstrators", true)
                console.log("is failed " + error)
            }

        },
        close: function () {
            this.$refs.detailsDialog.close()
        },
        showOverlay: function (show) {
            this.$refs.q_overlay.run = show
        },
        search: function () {
            var elements = this.$refs.form.elements

            var userData = {}

            Array.from(elements).forEach((input) => {

                if (input.tagName === "FIELDSET") {

                    if (input.parentNode.tagName === "FORM-INPUT") {
                        var formInput = input.parentNode
                        userData[formInput.getAttribute("name")] = formInput.inputValue
                    }
                }
            })
            var e = this.$refs.supportedActions
            if (e.options[e.selectedIndex].text !== "None") {
                console.log("is not none " + JSON.stringify(this.supportedActions[e.selectedIndex]))
                userData.admin = this.supportedActions[e.selectedIndex].uid
            }
            if ((userData.toDate == "" || userData.fromDate == "") && !userData.admin) {
                showMessage("Please set both start and end date or filter by administrator")
            } else {
                console.log("my oasdf asf asdf " + JSON.stringify(userData))

                this.$emit("query", userData)
                this.close()
            }
            Array.from(elements).forEach((input) => {

                if (input.tagName === "FIELDSET") {

                    if (input.parentNode.tagName === "FORM-INPUT") {
                        var formInput = input.parentNode
                        formInput.inputElement.value = ""
                    }
                }
            })

        }

    }
};
</script>
<style scoped>
dialog {
    position: absolute;
}


.showDrawer {
    width: 400px !important;
    opacity: 1 !important;
}



.drawer-header button:hover {
    background-color: var(--nav-current)
}

.drawer-header button {

    border-radius: 32px;
    line-height: 1;
    height: 32px;
    width: 32px;
}

.drawer-header button i {
    font-size: 1.4em;
}

.drawer-header {
    height: 32px;
    padding: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
}

.q-divider {
    margin-top: 16px;
    margin-bottom: 0px;
}

.drawer-content-container {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.iti__tel-input {
    width: 900px !important;
}

.child-content-container p,
.child-content-container h4 {
    margin: 0;
}

.child-content-container h4 {
    font-family: main_font_bold;
    font-size: .9em;
}

.group-title-container {
    background-color: var(--theme-color-navbar-bg);

}

.q-layout-row {
    width: 100%;
}

.q-button {
    width: 100%;
    background-color: var(--q_red);
    color: white !important;
}

.q-button p {
    color: white;
    margin-left: 8px
}
</style>
