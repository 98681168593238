<template>
    <div class="q-display-flex-column   "
        style="width: 100%;background-color: var(--admin-canvas-bg);height: 100vh;overflow: hidden">
        <popup-dialog-element style="z-index: 9999999999999;" ref="popUpDialog" id="popUpDialog"></popup-dialog-element>
        <progress-overlay-element ref="q_root_overlay" id="progress_overlay"></progress-overlay-element>
        <DesktopVisitorsMainView :style="visibleOnAndroid"
            @handleCurrentSelectionChanged="handleCurrentSelectionChanged" @handleSearch="handleSearch"
            :tableData="tableData" :hasData="hasData" :currentPresetId="currentPresetId"
            @viewUserDetails="viewUserDetails" @setCurrent="setCurrent" @loadPresets="loadPresets"
            :currentPresetData="currentPresetData" :isCurrentPreset="isCurrentPreset"
            :showTableELement="showTableELement" :showPlaceHolderELement="showPlaceHolderELement"
            :rowControls="rowControls" :controls="controls" :supportedViewActions="supportedViewActions"
            :totalPresets="totalPresets" :headers="headers"></DesktopVisitorsMainView>

        <AndroidVisitorsMainView :style="visibleOnDesktop"
            @handleCurrentSelectionChanged="handleCurrentSelectionChanged" @handleSearch="handleSearch"
            :tableData="tableData" :hasData="hasData" :currentPresetId="currentPresetId"
            @viewUserDetails="viewUserDetails" @setCurrent="setCurrent" @loadPresets="loadPresets"
            :currentPresetData="currentPresetData" :isCurrentPreset="isCurrentPreset"
            :showTableELement="showTableELement" :showPlaceHolderELement="showPlaceHolderELement"
            :rowControls="rowControls" :controls="controls" :supportedViewActions="supportedViewActions"
            :totalPresets="totalPresets" :headers="headers"></AndroidVisitorsMainView>
    </div>
</template>

<script>
import DesktopVisitorsMainView from '../sections/desktop/DesktopVisitorsMainView.vue'
import AndroidVisitorsMainView from '../sections/android/AndroidVisitorsMainView.vue'

import { showLoadingState, showMessage, joinArrayToStr, currentDateFormated } from "../../../public/js/utils.js"
import { onAuthStateChanged } from "firebase/auth";

import { collection, getDocs, query, where, or, and } from "firebase/firestore";
import { db, auth, VISITORS_COLLECTION, PENDING_STATUS, ON_GOING_STATUS, MAX_SMALL_DEVICE_WIDTH } from "../../firebaseInit.js"

let currentView = 0
export default {
    name: "users-compoent",
    components: {
        DesktopVisitorsMainView,
        AndroidVisitorsMainView
    },
    created() {
        this.deviceWidth = window.innerWidth

    },
    data() {
        return {
            currentPresetId: "",
            hasData: true,
            totalPresets: 0,
            deviceWidth: 0,
            supportedViewActions: [{ "key": "", "name": "All" }, { "key": "", "name": "Today" }]
            ,
            capturedData: "",
            rowControls: [

                {
                    "key": 0,
                    "name": "Handle Visitor",
                    "toolTip": "Handle Visitor",

                },

            ],
            controls: [
                {
                    "key": 0,
                    "name": "Refresh",
                    "toolTip": "Refresh Visitors",
                    "icon": "ri-refresh-line"
                },

            ], headers: [

                { "name": "Name", "key": "" },

                { "name": "Date", "key": "" },
                { "name": "Purpose of visit", "key": "" },
                { "name": "Person to meet", "key": "" },
                { "name": "Status", "key": "" },

            ],
            tableData: []

        }
    },
    props: {
        users: [],
    },
    computed: {
        visibleOnAndroid() {
            return this.deviceWidth <= MAX_SMALL_DEVICE_WIDTH ? "display:none" : "display:flex"
        },
        visibleOnDesktop() {
            return this.deviceWidth > MAX_SMALL_DEVICE_WIDTH ? "display:none" : "display:flex"
        },
        isLoadingUser() {
            return this.$store.getters["admin/isReloadingLoadingUser"];
        },
        isLoggedIn() {
            return this.$store.getters["admin/isLoggedIn"];
        },
        showPlaceHolderELement() {
            return !this.hasData ? "showPlaceHolder" : ""
        },
        showTableELement() {
            return this.hasData ? "" : "hideTable"
        },

        currentPresetData() {
            return this.$store.getters["current/currentVisitor"];
        },
        isCurrentPreset() {
            var data = this.$store.getters["current/currentVisitor"]
            console.log("mi == " + "  ")

            if (data.objectId != undefined && this.currentPresetId == data.objectId)
                return "current-row"
            return ""
        }

    },
    watch: {
        isLoadingUser(new_, old_) {
            if (new_ != old_) {
                this.loadPresets();
            }
        },
        currentPresetData(new_, old_) {

            if (new_ != old_) {
                this.setCurrentPresetId();
            }
        },

    },
    methods: {
        nextPage: function () { },


        setCurrentPresetId: function () {
            this.currentPresetId = this.currentPresetData && this.currentPresetData.objectId ? this.currentPresetData.objectId : ""

        },
        handleCurrentSelectionChanged: function (data) {
            console.log("my dayadfa sf as " + JSON.stringify(data))
            currentView = data.index
            this.loadPresets()
        },
        formatData: function (data) {
            return joinArrayToStr(data)
        },
        handleSearch: function (event, data) {

            console.trace()
            if (!data || data === "") {
                this.loadPresets()
            } else {
                this.loadPresets({
                    query: data
                })
            }
        },
        loadPresets: async function (data) {

            console.log("my dattasdasdf asf " + JSON.stringify(data) + "  " + event)
            // reset current preset
            this.$store.dispatch("current/setCurrentVisitor", {})
            try {
                console.log("is laodsdofaso fasf asf  ")
                showLoadingState(true)
                var currentDate = currentDateFormated();
                console.log("my curre  date " + JSON.stringify(currentDate))
                const dateKey = "expectedDate"
                const statusKey = "status"

                const pendingState = PENDING_STATUS
                const visitorsRef = collection(db, VISITORS_COLLECTION);
                var querySnapshot = undefined
                var q = undefined
                var isQueringName = false
                var searchData = data

                if (data) {

                    // if (currentView == 1) {
                    //     // today
                    //     q = query(visitorsRef, and(where(dateKey, "==", currentDate), or(where(statusKey, "==", pendingState), where(statusKey, "==", ON_GOING_STATUS))));
                    // } else {
                    //     //all
                    //     q = query(visitorsRef, and(or(where(statusKey, "==", pendingState), where(statusKey, "==", ON_GOING_STATUS))));
                    // }
                    isQueringName = true
                    console.log("my sdfi asdf asf " + data.query)
                    //  querySnapshot = await getDocs(q);

                }

                if (currentView == 1) {
                    // today
                    q = query(visitorsRef, and(where(dateKey, "==", currentDate), or(where(statusKey, "==", pendingState), where(statusKey, "==", ON_GOING_STATUS))));
                    querySnapshot = await getDocs(q);
                } else {
                    //all
                    q = query(visitorsRef, or(where(statusKey, "==", pendingState), where(statusKey, "==", ON_GOING_STATUS)));
                    querySnapshot = await getDocs(q);
                }


                this.tableData = [];
                showLoadingState(false)
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    var targetData = false

                    if (isQueringName) {
                        targetData = data.name.includes(searchData.query)
                    } else {
                        targetData = true
                    }

                    data.objectId = doc.id
                    data.statusText = data.status == PENDING_STATUS ? "Pending" : "On Going";
                    if (targetData)
                        this.tableData.push(data)
                });
                this.hasData = this.tableData.length > 0


            } catch (error) {
                console.log("mysdf asdfa sf " + error)
                showLoadingState(false)
                showMessage("Failed to load visitors", true)
            }
            this.countPresets()
        },
        countPresets: async function () {

            if (!this.isLoggedIn)
                return

            // try {
            //     var result = await axios.get(
            //         `${this.$store.getters["urls/adminBaseUrl"]}/presets/all/limit=0&count=1`,
            //     );
            //     this.totalPresets = result.data.count;

            // } catch (error) {
            //     console.warn("Failed to count presets , retrying " + error)
            //     setTimeout(() => {
            //         this.countPresets()
            //     }, 10000);
            // }
        },
        viewUserDetails: function (event, data) {

            this.$store.dispatch("current/setCurrentVisitor", data)
            this.editPreset()
        },
        setCurrent: function (event, row) {
            this.capturedData = ""
            var data_ = this.tableData.filter(a => {
                return a.objectId === row.objectId
            })

            this.$store.dispatch("current/setCurrentVisitor", data_.length > 0 ? data_[0] : row)
        },
        handleRowControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data) + " ***** " + JSON.stringify(this.currentPresetData))
            if (this.currentPresetData.objectId) {
                if (data.key === 0) {
                    this.editPreset()
                } else if (data.key === 1) {
                    this.$refs.editDialog.startDeleteUser()
                }
            } else {
                showMessage("Please select a preset ", false)
            }
        },
        handleControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data))
            if (data.key === 1) {
                this.createPreset()
            } else if (data.key === 0) {
                this.loadPresets()
            } else if (data.key === 2) {
                if (this.currentPresetData.objectId) {
                    this.editPreset()
                } else {
                    showMessage("Please select a preset ", false)
                }
            } else if (data.key === 3) {

                if (this.currentPresetData.objectId) {
                    this.$refs.editDialog.startDeleteUser()
                } else {
                    showMessage("Please select a preset ", false)
                }
            }
        },
        editPreset: function () {
            this.$refs.mainEditDialog.show()
        },
        createPreset: function () {

            this.$refs.detailsDialog.show()
        }
    },
    mounted: function () {
        this.deviceWidth = window.innerWidth

        this.loadPresets();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.loadPresets();

            }

        })

    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-content-container {
    position: relative;
}

.section-title {
    margin-top: 32px;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 32px;
}


.q-place-holder {
    max-width: 1000px;
    background-color: transparent;
    width: 500px;
    height: 500px;
    display: none;
}

.q-place-holder p {
    font-size: 1em;
    opacity: .5;
    text-align: center;
    max-width: 250px;
}

.q-place-holder i {
    font-size: 7em;
    opacity: .3;
}

.hideTable {
    visibility: hidden !important;
}

.showPlaceHolder {
    display: flex !important;
}
</style>
