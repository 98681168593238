<template>
    <div class="q-display-flex-column "
        style="width: 100vw;background-color: var(--admin-canvas-bg);height: 100vh;overflow: hidden;">
        <popup-dialog-element ref="popUpDialog" id="popUpDialog"></popup-dialog-element>
        <progress-overlay-element ref="q_root_overlay" id="progress_overlay"></progress-overlay-element>

        <AdminDesktopVisitorsMainView :style="visibleOnAndroid"
            @handleCurrentSelectionChanged="handleCurrentSelectionChanged" @handleSearch="handleSearch"
            :tableData="tableData" :hasData="hasData" :currentPresetId="currentPresetId"
            @viewUserDetails="viewUserDetails" @setCurrent="setCurrent" @loadPresets="loadPresets"
            :currentPresetData="currentPresetData" :isCurrentPreset="isCurrentPreset"
            :showTableELement="showTableELement" :showPlaceHolderELement="showPlaceHolderELement"
            :rowControls="rowControls" :controls="controls" :supportedViewActions="supportedViewActions"
            :totalPresets="totalPresets" :headers="headers">
        </AdminDesktopVisitorsMainView>

        <AdminAndroidVisitorsView :style="visibleOnDesktop"
            @handleCurrentSelectionChanged="handleCurrentSelectionChanged" @handleSearch="handleSearch"
            :tableData="tableData" :hasData="hasData" :currentPresetId="currentPresetId"
            @viewUserDetails="viewUserDetails" @setCurrent="setCurrent" @loadPresets="loadPresets"
            :currentPresetData="currentPresetData" :isCurrentPreset="isCurrentPreset"
            :showTableELement="showTableELement" :showPlaceHolderELement="showPlaceHolderELement"
            :rowControls="rowControls" :controls="controls" :supportedViewActions="supportedViewActions"
            :totalPresets="totalPresets"></AdminAndroidVisitorsView>
    </div>

</template>

<script>


import AdminDesktopVisitorsMainView from "../sections/desktop/AdminVisitorsMainView.vue"
import AdminAndroidVisitorsView from "../sections/android/AdminAndroidVisitorsView.vue";
import { showLoadingState, showMessage, joinArrayToStr, isPending } from "../../../public/js/utils.js"
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db, VISITORS_COLLECTION, auth, MAX_SMALL_DEVICE_WIDTH } from "../../firebaseInit.js"

let currentView = 0
export default {
    name: "users-compoent",
    components: {

        AdminDesktopVisitorsMainView,
        AdminAndroidVisitorsView
    },
    created() {
        this.deviceWidth = window.innerWidth
        console.log("My device with is " + this.deviceWidth)
    },
    data() {
        return {
            deviceWidth: 0,
            currentPresetId: "",
            hasData: true,
            totalPresets: 0,
            supportedViewActions: [{ "key": "", "name": "Pending" }, { "key": "", "name": "Completed" }, { "key": "", "name": "On Going" }]
            ,

            rowControls: [

                {
                    "key": 0,
                    "name": "Edit",
                    "toolTip": "Edit Visitor",

                },
                {
                    "key": 1,
                    "name": "Delete",
                    "toolTip": "Delete Visitor",

                },
                {
                    "key": 2,
                    "name": "Sign Out",
                    "toolTip": "Sign Out Visitor",

                },

            ],
            controls: [
                {
                    "key": 0,
                    "name": "Refresh",
                    "toolTip": "Refresh Presets",
                    "icon": "ri-refresh-line"
                },
                {
                    "key": 1,
                    "name": "Add",
                    "toolTip": "Add Visitor",
                    "icon": "ri-add-circle-line"
                },
                {
                    "key": 2,
                    "name": "Edit",
                    "toolTip": "Edit Visitor",
                    "icon": "ri-pencil-line"
                },
                {
                    "key": 3,
                    "name": "Delete",
                    "toolTip": "Delete Visitor",
                    "icon": "ri-delete-bin-line"
                },

            ], headers: [

                { "name": "Name", "key": "" },

                { "name": "Date", "key": "" },
                { "name": "Purpose of visit", "key": "" },
                { "name": "Contact", "key": "" },

            ],
            tableData: []

        }
    },
    props: {
        users: [],
    },
    computed: {
        visibleOnAndroid() {
            return this.deviceWidth <= MAX_SMALL_DEVICE_WIDTH ? "display:none" : "display:flex"
        },
        visibleOnDesktop() {
            return this.deviceWidth > MAX_SMALL_DEVICE_WIDTH ? "display:none" : "display:flex"
        },
        isLoadingUser() {
            return this.$store.getters["admin/isReloadingLoadingUser"];
        },
        isLoggedIn() {
            return this.$store.getters["admin/isLoggedIn"];
        },
        showPlaceHolderELement() {
            return !this.hasData ? "showPlaceHolder" : ""
        },
        showTableELement() {
            return this.hasData ? "" : "hideTable"
        },
        currentPresetData() {
            return this.$store.getters["current/currentVisitor"];
        },
        isCurrentPreset() {
            var data = this.$store.getters["current/currentVisitor"]
            console.log("mi == " + "  ")

            if (data.objectId != undefined && this.currentPresetId == data.objectId)
                return "current-row"
            return ""
        }

    },
    watch: {
        isLoadingUser(new_, old_) {
            if (new_ != old_) {
                this.loadPresets();
            }
        },
        currentPresetData(new_, old_) {

            if (new_ != old_) {
                this.setCurrentPresetId();
            }
        },

    },
    methods: {
        nextPage: function () { },
        prevPage: function () { },

        setCurrentPresetId: function () {
            this.currentPresetId = this.currentPresetData && this.currentPresetData.objectId ? this.currentPresetData.objectId : ""

        },
        handleCurrentSelectionChanged: function (data) {
            console.log("my dayadfa sf as " + JSON.stringify(data))
            currentView = data.index
            this.loadPresets()
        },
        formatData: function (data) {
            return joinArrayToStr(data)
        },
        handleSearch: function (event, data) {

            console.log("myf asofasf asf " + data + "  " + currentView)
            if (!data || data === "") {
                this.loadPresets()
            } else {
                this.loadPresets({
                    query: data
                })
            }
        },
        loadPresets: async function (data) {
            console.log("my dattasdasdf asf " + JSON.stringify(data) + "  " + event)
            // reset current preset
            this.$store.dispatch("current/setCurrentVisitor", {})
            try {
                if (!auth.currentUser) {
                    console.log("current user is null")
                    return
                }
                console.log("is laodsdofaso fasf asf  " + auth.currentUser.uid)
                showLoadingState(true)
                var querySnapshot = null
                const visitorsRef = collection(db, VISITORS_COLLECTION);

                var currenState = currentView
                var isQueringName = false
                var searchData = data
                if (data) {
                    // search
                    isQueringName = true
                    // const q = query(visitorsRef, where("createdBy", "==", auth.currentUser.uid), where("name", "==", data.query), where("status", "==", currenState));
                    // console.log("my sdfi asdf asf  = = =" + data.query)
                    // querySnapshot = await getDocs(q);

                }
                const q = query(visitorsRef, where("createdBy", "==", auth.currentUser.uid), where("status", "==", currenState));
                querySnapshot = await getDocs(q);


                this.tableData = [];
                showLoadingState(false)
                querySnapshot.forEach((doc) => {
                    console.log("is fetchsdf asf " + this.tableData)
                    console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
                    const data = doc.data();
                    data.objectId = doc.id
                    var targetData = false
                    if (isQueringName) {
                        targetData = data.name.includes(searchData.query)
                    } else {
                        targetData = true
                    }
                    const mIsPending = isPending(data.expectedDate);
                    console.log("is pending " + mIsPending)
                    if (targetData)
                        this.tableData.push(data)
                });
                this.hasData = this.tableData.length > 0

                console.log("has ad asdf asdf has dtas " + this.hasData)
            } catch (error) {
                console.log("mysdf asdfa sf " + error)
                showLoadingState(false)
                showMessage("Failed to load visitors", true)
            }
            this.countPresets()
        },
        countPresets: async function () {

            if (!this.isLoggedIn)
                return

            // try {
            //     var result = await axios.get(
            //         `${this.$store.getters["urls/adminBaseUrl"]}/presets/all/limit=0&count=1`,
            //     );
            //     this.totalPresets = result.data.count;

            // } catch (error) {
            //     console.warn("Failed to count presets , retrying " + error)
            //     setTimeout(() => {
            //         this.countPresets()
            //     }, 10000);
            // }
        },
        viewUserDetails: function (event, data) {

            this.$store.dispatch("current/setCurrentVisitor", data)
            this.editPreset()
        },
        setCurrent: function (event, row) {
            var data_ = this.tableData.filter(a => {
                return a.objectId === row.objectId
            })

            this.$store.dispatch("current/setCurrentVisitor", data_.length > 0 ? data_[0] : row)
        },
        handleRowControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data) + " ***** " + JSON.stringify(this.currentPresetData))
            if (this.currentPresetData.objectId) {
                if (data.key === 0) {
                    this.editPreset()
                } else if (data.key === 1) {
                    this.$refs.editDialog.startDeleteUser()
                }
            } else {
                showMessage("Please select a visitor ", false)
            }
        },
        handleControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data))
            if (data.key === 1) {
                this.createPreset()
            } else if (data.key === 0) {
                this.loadPresets()
            } else if (data.key === 2) {
                if (this.currentPresetData.objectId) {
                    this.editPreset()
                } else {
                    showMessage("Please select a visitor ", false)
                }
            } else if (data.key === 3) {

                if (this.currentPresetData.objectId) {
                    this.$refs.editDialog.startDeleteUser()
                } else {
                    showMessage("Please select a visitor ", false)
                }
            }
        },
        editPreset: function () {
            this.$refs.mainEditDialog.show()
        },
        createPreset: function () {

            this.$refs.detailsDialog.show()
        }
    },
    mounted: function () {
        this.deviceWidth = window.innerWidth
        console.log("My device with is in utebs  " + this.deviceWidth)
        this.loadPresets();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.loadPresets();

            }

        })
    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-content-container {
    position: relative;
}

.section-title {
    margin-top: 32px;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 32px;
}

.isPending {
    background-color: var(--gw_primary_color);
    max-height: 24px;
    border-radius: 16px;
    border: 0px solid green;

}

.isPending p {
    color: white;
    font-size: 12px !important;
}


.hideTable {
    visibility: hidden !important;
}

.showPlaceHolder {
    display: flex !important;
}
</style>
