<template>

    <DesktopRootAdminHome :userTotal="userTotal" :visitorTotal="visitorTotal" :style="visibleOnAndroid"
        :tableData="tableData" :headers="headers"></DesktopRootAdminHome>
    <AndroidRootHomeOverview :userTotal="userTotal" :visitorTotal="visitorTotal" :style="visibleOnDesktop"
        :tableData="tableData"></AndroidRootHomeOverview>
</template>

<script>
// import ContentHeaderComponent from "../../components/admin/ContentHeaderComponent.vue"
//import OptionsPopup from "../../components/admin/OptionsPopup.vue"
// import ContentTableFooter from "../../components/admin/ContentTableFooter.vue"
import { showLoadingState, showMessage, isPending } from "../../../public/js/utils.js"
import { collection, getDocs, query, where, limit, getCountFromServer } from "firebase/firestore";
import { db, VISITORS_COLLECTION, USERS_COLLECTION, PENDING_STATUS, MAX_SMALL_DEVICE_WIDTH } from "../../firebaseInit.js"


import DesktopRootAdminHome from '../sections/desktop/DesktopRootAdminHome.vue'
import AndroidRootHomeOverview from '../sections/android/AndroidRootHomeOverview.vue'
import axios from "axios";
axios.defaults.withCredentials = true;
export default {
    name: "users-compoent",
    components: {
        DesktopRootAdminHome,
        AndroidRootHomeOverview
        // ContentHeaderComponent,
        // OptionsPopup,
        // ContentTableFooter
    },
    created() {
        this.deviceWidth = window.innerWidth

    },
    mounted: function () {
        this.deviceWidth = window.innerWidth
        this.loadPresets()

    },
    props: {
        users: [],
    },
    computed: {
        visibleOnDesktop() {

            return this.deviceWidth > MAX_SMALL_DEVICE_WIDTH ? "display:none !important" : "display:flex"
        },
        visibleOnAndroid() {

            return this.deviceWidth <= MAX_SMALL_DEVICE_WIDTH ? "display:none !important" : "display:flex"
        },
        isLoadingUser() {
            return this.$store.getters["admin/isReloadingLoadingUser"];
        },
        isLoggedIn() {
            return this.$store.getters["admin/isLoggedIn"];
        },
        currentUserData() {
            return this.$store.getters["current/currentUser"];
        },
        isCurrentUser() {
            var data = this.$store.getters["current/currentUser"]
            console.log("mi == " + "  ")

            if (data.objectId != undefined && this.currentUserId == data.objectId)
                return "current-row"
            return ""
        }


    },
    watch: {
        isLoadingUser(new_, old_) {
            if (new_ != old_) {
                this.loadUsers();
            }
        },
        currentUserData(new_, old_) {

            if (new_ != old_) {
                this.setCurrentUserId();
            }
        },

    },
    methods: {

        loadPresets: async function (data) {
            console.log("my dattasdasdf asf " + JSON.stringify(data) + "  " + event)
            // reset current preset
            this.$store.dispatch("current/setCurrentVisitor", {})
            try {
                console.log("is laodsdofaso fasf asf  ")
                showLoadingState(true)

                // coount visitors
                const coll = collection(db, VISITORS_COLLECTION);
                const qc = query(coll, where("status", "==", PENDING_STATUS));
                const snapshot = await getCountFromServer(qc);
                console.log('count: ', snapshot.data().count);
                this.visitorTotal = snapshot.data().count





                // count users
                const userCol = collection(db, USERS_COLLECTION);

                const snapshot1 = await getCountFromServer(userCol);
                console.log('count: ', snapshot1.data().count);
                this.userTotal = snapshot1.data().count


                const visitorsRef = collection(db, VISITORS_COLLECTION);
                const q = query(visitorsRef, where("status", "==", PENDING_STATUS), limit(10));

                const querySnapshot = await getDocs(q);

                showLoadingState(false)
                this.tableData = [];

                querySnapshot.forEach((doc) => {
                    console.log("is rsdofasfa asdfas =df=asd a ")
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
                    const data = doc.data();
                    data.objectId = doc.id
                    const mIsPending = isPending(data.expectedDate);
                    console.log("is pending " + mIsPending)
                    this.tableData.push(data)
                });
                console.log("is fosdfoas fasd f")

                this.hasData = this.tableData.length > 0



            } catch (error) {
                console.log("mysdf asdfa sf " + error)
                showLoadingState(false)
                showMessage("Failed to load visitors", true)
            }

        },

    },

    data() {

        return {
            currentUserId: "",
            userTotal: 0,
            visitorTotal: 0,
            deviceWidth: 0,
            // rowControls: [


            //     {
            //         "key": 1,
            //         "name": "Delete ",
            //         "toolTip": "Delete Arhive",

            //     },
            // ],
            headers: [
                { "name": "Name", "key": "" },
                { "name": "NIN", "key": "" },
                { "name": "Purpose Of Visit", "key": "" },
                { "name": "Person To Meet", "key": "" },


            ],
            tableData: []

        }
    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table td {
    padding-top: 16px;
    padding-bottom: 16px;

}



[class^="q-col-"],
[class^="q-layout"] {
    padding: 0;
}
</style>
