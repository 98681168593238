<template>
    <div class="q-main-container q-display-flex-column q-align-content-stretch q-align-stretch ">
        <div class=" q-title-container q-display-flex-row q-item-animatable q-flex-center-items q-center">

            <a href="/">
                <img src="../../../public/images/gw_icon.webp" class="hr-icon" alt="hr  icon" />
            </a>
        </div>
        <div class="nav-container">
            <ul>
                <li class="q-display-flex-row q-item-animatable q-flex-center-items nav-link">
                    <RouterLink to="/admin/overview"> <i class="ri-home-5-line"></i></RouterLink>
                </li>



                <li class="q-display-flex-row q-item-animatable q-flex-center-items  nav-link"
                    v-for="(section) in sections" :key="section.key" :title="section.name">
                    <RouterLink :to="section.url"> <i :class="section.icon"></i></RouterLink>
                </li>


            </ul>

        </div>
    </div>
</template>

<script>
export default {
    name: "NavigationBarComponent",
    data() {
        return {
            sections: [

                {
                    key: 'key',
                    name: 'Administrators',
                    icon: 'ri-user-2-line',
                    url: '/admin/employees',

                },

                {
                    key: 'key',
                    name: 'Visitors',
                    icon: 'ri-group-line',
                    url: '/admin/visitors',

                },




            ]
        }
    },
};
</script>
<style scoped>
.list-caption span {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: var(--gw_text_color);
    opacity: .6;
    margin-left: 16px;
}


.nav-container ul {
    list-style-type: none;
    margin: 0px;
    margin-top: 8px;
    padding: 0px;
}

li:has(> a.router-link-active) {
    background-color: var(--nav-current)
}

.nav-container ul li {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 4px;
    margin-left: 8px;
    margin-right: 8px;

    ;
}

ul li:hover {
    background-color: var(--nav-selected)
}

ul li {
    padding-left: 10px;
    margin-top: 4px;
    margin-bottom: 4px;
}



.nav-link i {
    font-size: 16px;
}

ul li a {
    padding-left: 0px;
    color: var(--gw_text_color);
    font-family: main_font_bold;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500
}



.nav-container {

    width: 100%;
    height: 100%;
}

.q-main-container {
    background-color: var(--theme-color-navbar-bg);

    overflow: hidden;
    min-height: 48px;
    border-right: 1px solid rgba(0, 0, 0, .12);
    width: 56px;
    height: 100vh;
    padding: 0 !important;
}

.q-layout-row {
    padding: 0 !important;
    width: inherit;
}

.q-col-6 {
    padding: 0 !important;
}

.q-title-container {
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    height: 48px
}

.q-title-container h3 {
    font-family: main_font_bold;
    font-style: normal;

    margin-top: 18px;
    font-size: 1em;
    margin-bottom: 16px
}

.q-title-container img {
    width: 76px;
}
</style>
