let DEFAULT_RANDOM_STRING_SIZE = 12;

const InputType = Object.freeze({
  EMAIL: 1,
  PASSWORD: 2,
  TEXT: 3,
  BFI_ID: 4,
  TEL: 5,
  CITY: 6,
  PIN: 7,
  CONTACT: 8,
  NAME: 9,
  DELETE: 10
});
window.inputType = InputType;
let invaldDataErrorMsg =
  "One or more fields contains invalid data, please cross check your data and try again.";

function generateString(length) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
function getInputTypeAsString(type) {
  var str = "";
  switch (type) {
    case InputType.EMAIL:
      str = "email";
      break;
    case InputType.PASSWORD:
      str = "password";
      break;
    case InputType.BFI_ID:
      str = "bfiId";
      break;
    case InputType.CONTACT:
      str = "contact";
      break;
    case InputType.CITY:
      str = "city";
      break;
    case InputType.PIN:
      str = "postalIndexNumber";
      break;
    case InputType.NAME:
      str = "name";
      break;
    case InputType.DELETE:
      str = "delete";
      break;
  }
  return str;
}
function joinArrayToStr(data) {
  if (data != undefined) {
    console.log("sd fasdfas " + data + "  " + data.length);

    return data.join(", ");
  }
  return "";
}
function getInputTypeStringAsInt(str) {
  if (str === getInputTypeAsString(InputType.EMAIL)) {
    return InputType.EMAIL;
  } else if (str === getInputTypeAsString(InputType.NAME)) {
    return InputType.NAME;
  } else if (str === getInputTypeAsString(InputType.CONTACT)) {
    return InputType.CONTACT;
  } else if (str === getInputTypeAsString(InputType.BFI_ID)) {
    return InputType.BFI_ID;
  } else if (str === getInputTypeAsString(InputType.PIN)) {
    return InputType.PIN;
  } else if (str === getInputTypeAsString(InputType.CITY)) {
    return InputType.CITY;
  } else if (str === getInputTypeAsString(InputType.PASSWORD)) {
    return InputType.PASSWORD;
  } else if (str === getInputTypeAsString(InputType.DELETE)) {
    return InputType.DELETE;
  }
  console.warn("Unknown input type ");
  return InputType.TEXT;
}
function verifyEmail(param_text) {
  //var reg1 =
  // /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return param_text; // reg1.test(param_text);
}
function verifyPassword(param_text) {
  //var reg1 = /^(?ve=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
  return param_text;
}

function verifyContact(param_text) {
  var reg1 = /^[0-9]{11}$/;
  return reg1.test(param_text);
}
function toggleDisabableElements(isDisabled) {
  var items = document.querySelectorAll("[data-can-be-disabled]");

  items.forEach(function (element) {
    if (isDisabled) element.setAttribute("disabled", "disabled");
    else element.removeAttribute("disabled");
  });
}
function isVaidNIN(text) {
  if (!text) return false;
  return text.length == 14;
}
function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

function toggleAuthDependantElements() {
  //var items =document.querySelectorAll("[data-auth-depenedant]");
  // items.each(function () {
  //   if (isDisabled) $(this).css("visibility", "hidden");
  //   else $(this).css("visibility", "visible");
  // });
  // var element = document.querySelector("#q-root-overlay");
  // if (element) element.run = isDisabled;
}
function showLoadingState(show) {
  toggleDisabableElements(show);
  var _progress = document.querySelector("#progress_overlay");
  if (_progress != null) _progress.run = show;
}
function showProgress() {
  var _progress = document.querySelector("#progress_overlay");
  if (_progress != null) _progress.run = true;
}
function stopProgress() {
  var _progress = document.querySelector("#progress_overlay");
  if (_progress != undefined) _progress.run = false;
}
function showMessage(msg, isError) {
  var _popupItem = document.querySelector("#popUpDialog");
  if (_popupItem) _popupItem.showDialog(msg, isError);
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function formatContact(phone) {
  if (!phone) return "";
  if (phone.length < 7) return;
  let prefixLength = 4;
  let suffixLength = 3;

  let prefix = phone.substring(0, prefixLength);
  let suffix = phone.slice(-suffixLength);
  let nbStars = phone.length - (prefixLength + suffixLength);

  return prefix + "*".repeat(nbStars) + suffix;
}
function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
function formatNumber(value) {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = "";
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision)
      );
      var dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function parseDate(s) {
  var b = s.split(/\D/);
  return new Date(b[0], --b[1], b[2]);
}
function currentDateFormated() {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = yyyy + "-" + mm + "-" + dd;
  return formattedToday;
}
function isPending(expectedDate) {
  var date_ = parseDate(expectedDate);
  var date_1 = parseDate(currentDateFormated());
  const today = date_1;
  return today <= date_;
}
function sortJsonKeys(unordered) {
  const ordered = Object.keys(unordered)
    .sort()
    .reduce((obj, key) => {
      obj[key] = unordered[key];
      return obj;
    }, {});
  return ordered;
}
function generateStr(
  length = 20,
  characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$"
) {
  return Array.from(crypto.getRandomValues(new Uint32Array(length)))
    .map((x) => characters[x % characters.length])
    .join("");
}

function getTime() {
  let today = new Date();
  let h = today.getHours();
  let m = today.getMinutes();

  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;

  let time = h + ":" + m;
  return time;
}
function showDialog(dialog) {
  dialog.style.width = window.innerWidth - 32 + "px";
  //dialog.style.top = wrapper.offsetTop + "px";
  dialog.showModal();

  console.log(
    "mysd fa fasf  " + dialog.offsetHeight + "asdas  " + window.innerHeight
  );
  //dialog.style.bottom = window.innerHeight - (dialog.offsetHeight - 150) + "px";
  dialog.style.bottom = "0";
  dialog.style.top = "0";
}
function jsonConcat(o1, o2) {
  for (var key in o2) {
    o1[key] = o2[key];
  }
  return o1;
}
export {
  DEFAULT_RANDOM_STRING_SIZE,
  invaldDataErrorMsg,
  generateString,
  getInputTypeAsString,
  getInputTypeStringAsInt,
  verifyEmail,
  verifyPassword,
  verifyContact,
  toggleDisabableElements,
  toggleAuthDependantElements,
  showProgress,
  stopProgress,
  showMessage,
  InputType,
  formatContact,
  showLoadingState,
  joinArrayToStr,
  setCookie,
  getCookie,
  eraseCookie,
  isJsonString,
  formatNumber,
  numberWithCommas,
  isVaidNIN,
  parseDate,
  currentDateFormated,
  isPending,
  capitalizeFirstLetter,
  sortJsonKeys,
  generateStr,
  getTime,
  showDialog,
  jsonConcat
};
