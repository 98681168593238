<template>
    <div class="q-main-container q-layout-container q-display-flex-row q-align-content-stretch q-align-stretch ">
        <div class="q-layout-row">
            <div class="q-col-6  q-display-flex-row  q-flex-center-item q-center q-flex-start  "
                style="padding-left: 32px !important;height: 56px;">
                <div style="" class="q-display-flex-row flow-center   q-flex-center-item q-center ">
                    <a href="/">
                        <img src="../../../public/images/gw_icon.webp" class="hr-icon" alt="hr  icon" />
                    </a>
                </div>
            </div>
            <div class="q-col-6  q-display-flex q-flex-end q-flex-center-items  "
                style="padding-right: 32px !important;">

                <UserAvatarContainer></UserAvatarContainer>
            </div>
        </div>
    </div>
</template>

<script>
import { formatContact } from "../../../public/js/utils.js"
import UserAvatarContainer from "../UserAvatarContainer.vue"
export default {
    name: "AdminHeaderComponent",
    components: {
        UserAvatarContainer
    },
    mounted: function () {
        this.updateNavItems();

    },
    data() {
        return {
            options: [

                {
                    key: '1',
                    name: 'Notifications',
                    icon: 'ri-notification-line',
                    url: '/console/presets',

                },


            ]
        }
    },
    watch: {
        isLoadingUser(new_, old_) {
            if (new_ != old_) {
                this.updateNavItems();
            }
        },
        username(new_, old_) {
            if (new_ != old_) {
                this.updateNavItems();
            }
        },

        phone(new_, old_) {
            if (new_ != old_) {
                this.updateNavItems();
            }
        },
    },
    computed: {
        isLoadingUser() {
            return this.$store.getters["user/isReloadingLoadingUser"];
        },
        isLoggedIn() {
            return this.$store.getters["user/isLoggedIn"];
        },
        username() {
            return this.$store.getters["user/username"];
        },
        photoUrl() {
            return this.$store.getters["user/photoUrl"];
        },
        useremail() {
            return this.$store.getters["user/email"];
        },
        phone() {
            return formatContact(this.$store.getters["user/phone"]);
        },
    },
    methods: {
        updateNavItems: function () {
            if (!this.isLoggedIn)
                return

            // this.$refs.smallDeviceAuth.userData = this.$store.getters["user/user"];
            // this.$refs.smallDeviceAuth.isSignedIn = this.isLoggedIn;
        },
    }
};
</script>
<style scoped>
.q-main-container {
    background-color: var(--admin-canvas-bg);

    position: fixed;
    z-index: 9999999;
    max-height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    width: 100%;
}

.hr-icon {
    width: 70px;
    height: auto;
}

.badge {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    background-color: red;
    width: 16px;
    height: 16px
}

.badge p {
    margin: 0;
    color: white;
    font-weight: 900;
    font-size: 12px;
}

.q-layout-container,
.q-layout-row {
    padding: 0 !important;


}

.option {
    width: 32px;
    height: 32px;
    border-radius: 50% 50%;
    padding: 8px;
    margin: 8px;
    position: relative;
}

.option i {
    font-size: 16px
}

.option:hover {
    background-color: var(--nav-selected)
}

.q-layout-row {
    height: 100%;

    width: inherit;
}

.q-col-6 {
    padding: 0 !important;
    height: 100%;
}
</style>
