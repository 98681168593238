<template>
    <div style="margin-left: 16px;">
        <h2 style="margin-top: 32; margin-bottom: 0px;">{{
            this.$store.getters["user/greeting"] }}</h2>
        <div class="date-container   q-flex-center-items  q-display-flex-row">
            <span class=" q-display-flex-row q-center  q-flex-center-items"><i class="ri-calendar-line"></i></span>
            <p>{{ this.$store.getters["user/fullDate"] }}</p>
        </div>

    </div>
</template>
<script>



export default {
    name: "GreetingsComponent"

};
</script>
<style scoped></style>
