<template>

    <div class=" q-display-flex-column  " :style="visibleOnDesktop"
        style="overflow-y: scroll; width: 100%;height: 100vh;">

        <h2 class="q-align-self-left" style="margin-top: 0; margin-bottom: 16px;padding-left: 16px ">Visitors</h2>
        <AndroidContentHeaderSection :controls="controls" :supportedActions="supportedViewActions"
            @controlData="handleControlClick" @exportClick="exportClick" @filter="filter" @searchItem="handleSearch"
            searchPlaceHolder="Find a visitor by name" @currentSelectionChanged="handleCurrentSelectionChanged"
            :hasMoreOptions="true">

        </AndroidContentHeaderSection>

        <div :class="showPlaceHolderELement"
            class=" q-place-holder q-display-flex-column  q-center q-flex-center-items">

            <i class="ri-group-line"></i>
            <p>Oops, No visitors yet, click "Add" to get started</p>
        </div>
        <div class="q-layout-container padding-top-0" style="padding: 0;max-width: 100vw !important;">
            <div class="q-layout-row padding-top-0 padding-bottom-0" style="padding: 0;">

                <div class="q-col-sm-12 q-col-md-12 q-col-lg-12 q-col-xl-12 q-display-flex-column  padding-top-0  padding-bottom-0"
                    style="padding-top: 0 !important;">


                    <div :style="showPlaceHolderELement"
                        class=" q-place-holder  q-display-flex-column  q-center q-flex-center-items"
                        style="width: 100px;">

                        <i class="ri-group-line"></i>
                        <p>Oops, No visitors yet,</p>
                    </div>

                    <ul class="q-listview-container" style="margin-bottom: 120px; !important">
                        <li v-for="(row) in tableData" :key="row.objectId" class="q-list-item q-display">
                            <div class="q-layout-container padding-top-0" style="padding: 0 !important;">
                                <div class="q-display-flex-row padding-top-0 padding-bottom-0" style="padding: 0;">


                                    <div class="q-display-flex-column"
                                        style="padding: 0;padding-left: 8px; width: 100%;"
                                        @click="viewUserDetails($event, row)">
                                        <h3 style="margin: 0;">{{ row.purpose }}</h3>
                                        <p class="q-display-flex-row q-list-caption"><span
                                                style="margin-left:  0 !important;">{{ row.name }} </span>
                                            <span>{{ row.expectedDate }}</span> <span>

                                                <p style="color: var(--gw_primary_color);"> {{ row.statusText }}</p>

                                            </span>
                                        </p>
                                    </div>
                                    <div style="padding: 0;width: 8px;"
                                        class="q-display-flex-column q-center flow-center">
                                        <!-- <button>
                                                <i class="ri-more-2-fill"></i>
                                            </button> -->
                                        <OptionsPopup @setCurrent="setCurrent" @controlData="handleRowControlClick"
                                            :controls="rowControls" :rowData="row">
                                        </OptionsPopup>
                                    </div>
                                </div>
                            </div>

                        </li>

                    </ul>


                </div>
            </div>
        </div>

        <PreviewPresetDrawer @edit="editPreset" @deleted="loadPresets" ref="editDialog">
        </PreviewPresetDrawer>
        <EditPresetDialog ref="mainEditDialog" @edited="loadPresets" @deleted="loadPresets">
        </EditPresetDialog>
        <CreatePresetDrawer @created="loadPresets" ref="detailsDialog">
        </CreatePresetDrawer>
        <VisitorsFilterDialog ref="filterDialog" @query="loadPresets"></VisitorsFilterDialog>
        <ExportDialog ref="exportDialog" @export="exportTable">

        </ExportDialog>
        <button class="q-fab elevate-5"><i class="ri-add-large-line" @click="createPreset"></i></button>
        <EditAskariDialog ref="askariEditDialog" @edited="loadPresets" @deleted="loadPresets"
            expectedDateReadonly="true" purposeReadonly="true" contactReadonly="true" hostReadonly="true"
            idRequired="true" showName="false" showNin="true" showImage="true" @showCamera="showMediaCam"
            :imageData="capturedData"></EditAskariDialog>

    </div>

</template>

<script>



import OptionsPopup from "../../../components/admin/OptionsPopup.vue"

import AndroidContentHeaderSection from '../../../../src/components/AndroidContentHeaderSection.vue'


import EditPresetDialog from "../../../components/admin/EditUserVisitor.vue"
import CreatePresetDrawer from "../../../components/admin/CreateVisitorDrawer.vue"
import PreviewPresetDrawer from "../../../components/admin/PreviewVisitorDrawer.vue"

import { showMessage, joinArrayToStr } from "../../../../public/js/utils.js"
import { MAX_SMALL_DEVICE_WIDTH } from "../../../firebaseInit.js"

import VisitorsFilterDialog from '../../../components/admin/VisitorsFilterDialog.vue'
import ExportDialog from '../../../components/admin/ExportDialog.vue'
import EditAskariDialog from "../../../components/admin/EditAskariVisitor.vue"
import { ON_GOING_STATUS } from "../../../firebaseInit.js"

export default {

    name: "AdminDesktopView",
    props: ["tableData", "hasData", "currentPresetId", "rowControls", "controls", "supportedViewActions", "totalPresets", "headers"],
    components: {
        OptionsPopup,
        AndroidContentHeaderSection,
        EditPresetDialog,
        CreatePresetDrawer,
        PreviewPresetDrawer,
        ExportDialog,
        VisitorsFilterDialog, EditAskariDialog
    },
    created() {
        this.deviceWidth = window.innerWidth

    }, mounted: function () {
        this.deviceWidth = window.innerWidth



    },
    computed: {
        visibleOnDesktop() {

            return this.deviceWidth > MAX_SMALL_DEVICE_WIDTH ? "display:none !important" : "display:flex"
        },
        showPlaceHolderELement() {
            return !this.hasData ? "showPlaceHolder" : ""
        },
        showTableELement() {
            return this.hasData ? "" : "hideTable"
        },
        currentPresetData() {
            return this.$store.getters["current/currentVisitor"];
        },
        isCurrentPreset() {
            var data = this.$store.getters["current/currentVisitor"]
            console.log("mi == " + "  ")

            if (data.objectId != undefined && this.currentPresetId == data.objectId)
                return "current-row"
            return ""
        }

    },
    data() {
        return {
            deviceWidth: 0,
        }
    },

    methods: {
        filter: function () {
            console.log("adf sd fsdf")
            this.$refs.filterDialog.show()
        },
        exportClick: function () { this.$refs.exportDialog.show(); },
        exportTable: function (data) {
            this.$emit('exportTable', data)
        },
        handleCurrentSelectionChanged: function (data) {

            this.$emit('handleCurrentSelectionChanged', data)
        },
        formatData: function (data) {
            return joinArrayToStr(data)
        },
        handleSearch: function (event, data) {
            this.$emit('handleSearch', event, data)

        },

        viewUserDetails: function (event, data) {

            this.$store.dispatch("current/setCurrentVisitor", data)
            console.log("tate sdyf asdfasdf as f" + JSON.stringify(data))
            this.editPreset()
        },
        setCurrent: function (event, row) {
            // var data_ = this.tableData.filter(a => {
            //     return a.objectId === row.objectId
            // })

            // this.$store.dispatch("current/setCurrentVisitor", data_.length > 0 ? data_[0] : row)

            this.$emit('setCurrent', event, row)
        },
        handleRowControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data) + " ***** " + JSON.stringify(this.currentPresetData))
            if (this.currentPresetData.objectId) {
                if (data.key === 0) {
                    this.editPreset()
                } else if (data.key === 1) {
                    this.$refs.editDialog.startDeleteUser()
                } else if (data.key === 2) {
                    console.log("is prepo pfasdf asdf " + JSON.stringify(this.$store.getters["current/currentVisitor"]))
                    this.$emit("print", this.$store.getters["current/currentVisitor"])
                } else if (data.key === 3) {
                    if (this.currentPresetData.status === ON_GOING_STATUS) {
                        this.$refs.askariEditDialog.show()
                    } else {
                        showMessage("Selected visitor`s appointment is not active")
                    }
                }
            } else {
                showMessage("Please select a visitor ", false)
            }
        },
        loadPresets: function (data) {
            this.$emit('loadPresets', data)
        },
        handleControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data))
            if (data.key === 1) {
                this.createPreset()
            } else if (data.key === 0) {
                this.loadPresets()
            } else if (data.key === 2) {
                if (this.currentPresetData.objectId) {
                    this.editPreset()
                } else {
                    showMessage("Please select a visitor ", false)
                }
            } else if (data.key === 3) {

                if (this.currentPresetData.objectId) {
                    this.$refs.editDialog.startDeleteUser()
                } else {
                    showMessage("Please select a visitor ", false)
                }
            }
        },
        editPreset: function () {
            this.$refs.mainEditDialog.show()
        },
        createPreset: function () {

            this.$refs.detailsDialog.show()
        }
    },
};
</script>
<style scoped></style>
