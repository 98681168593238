<template>
    <div class=" q-display-flex-column  q-center q-flex-center-items" style="width: 100%;">
        <div class="q-display-flex-column " style="width: 100%;height: fit-content;overflow-y: scroll;height: 100vh;">
            <AndroidGreetingsView></AndroidGreetingsView>
            <h2 class="q-align-self-left section-title" style="margin-left: 16px;margin-top: 0px;">Overview</h2>
            <div class="q-layout-container padding-top-0" style="padding: 0;">
                <div class="q-layout-row padding-top-0 padding-bottom-0" style="padding: 0;">

                    <div class="q-col-6 q-display-flex-column flow-center padding-top-0  padding-bottom-0"
                        style="padding: 0;">

                        <OverviewDataComponent backgroundColor="#CFC9F9" color="#4E3395" :number="visitorTotal"
                            caption="Visitors" icon="ri-group-line">
                        </OverviewDataComponent>
                    </div>
                    <div class="q-col-6 q-display-flex-column flow-center padding-top-0  padding-bottom-0"
                        style="padding: 0;">

                        <OverviewDataComponent backgroundColor="#E6F3EA" color="#329B57" :number="userTotal"
                            caption="Administrators" icon="ri-user-2-line">
                        </OverviewDataComponent>

                    </div>


                </div>
            </div>
            <!-- <Bar id="my-chart-id" :options="chartOptions" :data="chartData" /> -->

            <h2 class="q-align-self-left section-title" style="margin-left: 16px;">Pending Visitors </h2>


            <ul class="q-listview-container" style="width: 92%;margin-left: 16px;">
                <li v-for="(row) in tableData" :key="row.objectId" class="q-list-item q-display">
                    <div class="q-layout-container padding-top-0" style="padding: 0 !important;">
                        <div class="q-display-flex-row padding-top-0 padding-bottom-0" style="padding: 0;">
                            <div class="q-display-flex-column" style="padding: 0;padding-left: 8px; width: 100%;">
                                <h3 style="margin: 0;">{{ row.purpose }}</h3>
                                <p class="q-display-flex-row q-list-caption"><span
                                        style="margin-left:  0 !important;">{{ row.name }} </span>
                                    <span>{{ row.expectedDate }}</span>
                                </p>
                            </div>
                        </div>
                    </div>

                </li>

            </ul>
        </div>
    </div>
</template>

<script>


import { showMessage, joinArrayToStr } from "../../../../public/js/utils.js"
import AndroidGreetingsView from '../../../../src/components/AndroidGreetingsView.vue'

import OverviewDataComponent from '../../../components/admin/OverviewDataComponent.vue'

// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

// import { Bar } from 'vue-chartjs'
// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: "AdminDesktopView",
    props: ["tableData", "hasData", "userTotal", "visitorTotal", "controls", "supportedViewActions", "totalPresets", "headers"],
    components: {
        OverviewDataComponent,
        AndroidGreetingsView,
        //  Bar
    },
    mounted() {
        console.log("s ad asdf asdf asdfasf " + this.tableData)

    },
    computed: {

        showPlaceHolderELement() {
            return !this.hasData ? "showPlaceHolder" : ""
        },
        showTableELement() {
            return this.hasData ? "" : "hideTable"
        },
        currentPresetData() {
            return this.$store.getters["current/currentVisitor"];
        },
        isCurrentPreset() {
            var data = this.$store.getters["current/currentVisitor"]
            console.log("mi == " + "  ")

            if (data.objectId != undefined && this.currentPresetId == data.objectId)
                return "current-row"
            return ""
        }

    },
    data() {
        return {
            chartData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                datasets: [{ data: [40, 20, 12] }]
            },
            chartOptions: {
                responsive: true
            },
            capturedData: "",
            sections: [

                {
                    key: 'key',
                    name: 'Signout',
                    icon: 'ri-user-received-line',
                    url: '/signout',

                },
            ],


        }
    },

    methods: {
        showMediaCam: function () {
            this.capturedData = ""
            this.$refs.mediaComponent.show()
        },
        handleAccepted: function () {

            this.capturedData = this.$refs.mediaComponent.getImageData()
            console.log("mysd f asdf asf " + this.$refs.mediaComponent.getImageData())

        },
        showDrawer: function () { this.$refs.drawer.show() },
        handleCurrentSelectionChanged: function (data) {

            this.$emit('handleCurrentSelectionChanged', data)
        },
        formatData: function (data) {
            return joinArrayToStr(data)
        },
        handleSearch: function (event, data) {
            this.$emit('handleSearch', event, data)
            // console.trace()
            // if (!data || data === "") {
            //     this.loadPresets()
            // } else {
            //     this.loadPresets({
            //         query: data
            //     })
            // }
        },

        viewUserDetails: function (event, data) {
            // this.$emit('viewUserDetails', event, data)
            this.$store.dispatch("current/setCurrentVisitor", data)
            this.editPreset()
        },
        setCurrent: function (event, row) {
            // var data_ = this.tableData.filter(a => {
            //     return a.objectId === row.objectId
            // })

            // this.$store.dispatch("current/setCurrentVisitor", data_.length > 0 ? data_[0] : row)

            this.$emit('setCurrent', event, row)
        },
        handleRowControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data) + " ***** " + JSON.stringify(this.currentPresetData))
            if (this.currentPresetData.objectId) {
                if (data.key === 0) {
                    this.editPreset()
                } else if (data.key === 1) {
                    this.$refs.editDialog.startDeleteUser()
                }
            } else {
                showMessage("Please select a visitor ", false)
            }
        },
        loadPresets: function (data) {
            this.$emit('loadPresets', data)
        },
        handleControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data))
            if (data.key === 1) {
                this.createPreset()
            } else if (data.key === 0) {
                this.loadPresets()
            } else if (data.key === 2) {
                if (this.currentPresetData.objectId) {
                    this.editPreset()
                } else {
                    showMessage("Please select a visitor ", false)
                }
            } else if (data.key === 3) {

                if (this.currentPresetData.objectId) {
                    this.$refs.editDialog.startDeleteUser()
                } else {
                    showMessage("Please select a visitor ", false)
                }
            }
        },
        editPreset: function () {
            this.$refs.mainEditDialog.show()
        },
        createPreset: function () {

            this.$refs.detailsDialog.show()
        }
    },
};
</script>
<style scoped></style>
