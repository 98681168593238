<template>
    <dialog aria-modal="true" aria-label="user Details"
        class=" q-animatable q-delay-fast elevate-5 details-container  q-display-flex-column " ref="detailsDialog">
        <progress-overlay-element ref="q_overlay"></progress-overlay-element>

        <div class="drawer-header  q-display-flex-row q-align-stretch">
            <h3 style="margin: 0px;width: 100%;">Export</h3>
            <button data-can-be-disabled @click="close" class=" q-display-flex-column q-center q-flex-center-items"
                title="close">
                <i class="ri-close-circle-line q-align-self-right"></i>
            </button>

        </div>
        <div class="q-divider"></div>

        <div class="drawer-content-container">

            <div class=" q-layout-container q-progress-overlay-container content-container">

                <div class="q-layout-row  child-content-container">
                    <button @click="formatChosen(item)" v-for="item in formats" :key="item.key" class="exportButton">
                        <p><i :class="item.icon"></i>{{ item.text }}</p>
                    </button>
                </div>
            </div>
        </div>
    </dialog>
</template>

<script>
import axios from "axios";


axios.defaults.withCredentials = true;
export default {
    name: "VisitorFilter",
    components: {

    },
    data() {
        return {
            errorMsg: "",
            formats: [
                {
                    key: "",
                    id: 1,
                    icon: 'ri-file-excel-2-line',
                    text: "Excel"

                },
                {
                    key: "",
                    id: 2,
                    icon: 'ri-file-3-line',
                    text: "CSV"
                },
                {
                    key: "",
                    id: 3,
                    icon: 'ri-file-pdf-2-line',
                    text: "PDF"
                },
                {
                    key: "",
                    id: 4,
                    icon: 'ri-printer-line',
                    text: "Print"
                },
            ],
        }
    },
    computed: {
        showError: function () {
            return this.errorMsg.length == "" ? "display:none" : "display:block"
        }
    },
    mounted() {

    },
    methods: {
        show: async function () {
            this.$refs.detailsDialog.showModal()
        },
        close: function () {
            this.$refs.detailsDialog.close()
        },
        showOverlay: function (show) {
            this.$refs.q_overlay.run = show
        },
        formatChosen: function (userData) {
            this.$emit("export", userData)
            this.close()
        },


    }
};
</script>
<style scoped>
dialog {
    position: absolute;
}

.exportButton {
    width: 100%;
    height: 56px;

}

.exportButton i {
    font-size: 20px;
    margin-right: 8px;
}

.exportButton p {
    font-size: 16px;

}

.exportButton:hover {
    background-color: var(--nav-current)
}

.showDrawer {
    width: 400px !important;
    opacity: 1 !important;
}



.drawer-header button:hover {
    background-color: var(--nav-current)
}

.drawer-header button {

    border-radius: 32px;
    line-height: 1;
    height: 32px;
    width: 32px;
}

.drawer-header button i {
    font-size: 1.4em;
}

.drawer-header {
    height: 32px;
    padding: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
}

.q-divider {
    margin-top: 16px;
    margin-bottom: 0px;
}

.drawer-content-container {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.iti__tel-input {
    width: 900px !important;
}

.child-content-container p,
.child-content-container h4 {
    margin: 0;
}

.child-content-container h4 {
    font-family: main_font_bold;
    font-size: .9em;
}

.group-title-container {
    background-color: var(--theme-color-navbar-bg);

}

.q-layout-row {
    width: 100%;
}

.q-button {
    width: 100%;
    background-color: var(--q_red);
    color: white !important;
}

.q-button p {
    color: white;
    margin-left: 8px
}
</style>
