<template>
  <div>
    <popup-dialog-element ref="popUpDialog" id="popUpDialog"></popup-dialog-element>
    <progress-overlay-element ref="q_root_overlay" id="progress_overlay"></progress-overlay-element>
    <main>
      <span class="q-credit-span">
        Image by <a
          href="https://pixabay.com/users/voltamax-60363/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2141808">Yerson
          Retamal</a> from <a
          href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2141808">Pixabay</a>
      </span>
      <section class=" q-has-background-img q-display-flex-column flow-center q-center q-grayed-backgroud" style="
        min-height: 100vh;
          overflow-y: auto;
          background-image: url(images/bg.jpg);
        ">
        <div class="q-overlay q-item-blur"></div>
        <div class="q-section">
          <div class="q-layout-container q-auth-box q-progress-overlay-container" style="padding: 0 !important;">
            <progress-overlay-element id="progress_overlay"></progress-overlay-element>

            <div class="q-layout-row">
              <div class="q-col-sm-12 q-col-md-12 q-col-lg-5 q-col-xl-5 q-display-flex-column flow-center q-center">
                <a href="/">
                  <img src="images/gw_icon.webp" class="q-auth-icon" alt="gowant writer icon" />
                </a>
                <h2 class="" style="margin-bottom: 0.3em; margin-top: 0.2em">Log in</h2>

                <p style=" margin-top: 0px; font-size: 1em">Welcome back, log in to continue</p>
              </div>
              <div class="q-col-sm-12 q-col-md-12 q-col-lg-7 q-col-xl-7 q-display-flex-column flow-center q-center">
                <!--Dass Logo-->
                <form ref="form" class="q-auth-form form-container" style="max-width: 400px;" method="post" action="#">

                  <!-- <fieldset>
                    <label for="ai">Account type</label>
                    <div class="q-password-field">

                      <select name="ais" ref="supportedActions">
                        <option v-for="(item) in supportedAisList" :key="item.key" :value="item.name"
                          :data-key="item.key">
                          {{ item.name }}</option>

                      </select>
                    </div>

                  </fieldset> -->
                  <form-input ref="username" type="email" name="email" placeHolder="Email"
                    label="Your email"></form-input>
                  <form-input ref="password" type="password" name="password" placeHolder="Password"
                    label="Your password"></form-input>

                  <div class="q-display-flex-column  q-form-button-container  ">
                    <a href="/forgotPassword" class="q-form-link" style="margin-bottom: 8px;">Forgot Password?</a>


                    <button data-can-be-disabled type="submit" name="button" id="submit" class="btn anchor-button   "
                      style="align-self:stretch;">Login</button>

                    <legal-component></legal-component>
                  </div>

                </form>

                <UserRoleManager adminUrl="admin/overview" userUrl="user"></UserRoleManager>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>

import LegalComponent from "./../components/LegalComponent.vue";
import UserRoleManager from './../components/UserRoleManager.vue'

import { toggleDisabableElements, showMessage } from "../../public/js/utils.js";
import { SUPPORTED_ROLES, USERS_COLLECTION, db } from "../firebaseInit.js"
import { collection, query, where, getDocs } from "firebase/firestore";

export default {
  name: "loginView",
  components: {
    LegalComponent,
    UserRoleManager
  },
  props: {
    msg: String,
  },
  data() {
    return {
      userData: {},
      supportedAisList: SUPPORTED_ROLES,
    }
  },
  computed: {
    isLoadingUser() {
      return this.$store.getters["user/isReloadingLoadingUser"];
    },
    userRole() {
      return this.$store.getters["user/role"];
    },
    isLoggedIn() {

      return (
        this.$store.getters["user/isLoggedIn"]
      );
    },
    isLoginOperationRunning() {

      return (
        this.$store.getters["user/loginOperationRunning"]
      );
    },
    loginError() {

      return (
        this.$store.getters["user/loginError"]
      );
    },
  },
  watch: {
    isLoadingUser(new_, old_) {
      if (new_ != old_) {
        this.redirectUser();
      }
    },
    userRole(new_, old_) {
      if (new_ != old_) {
        this.redirectUser();
      }
    },
    isLoginOperationRunning(new_, old_) {
      if (new_ != old_) {
        this.handleState()
      }
    },
    loginError(new_, old_) {
      if (new_ != old_) {
        this.handleError()
      }
    }
  },
  mounted: function () {
    console.log("is logged in  " + this.isLoggedIn)
    this.$refs.form.addEventListener("submit", (e) => {
      e.preventDefault()
      const userPasswordElement = this.$refs.password

      const userEmailElement = this.$refs.username
      if (!userEmailElement.isValid) {
        //  this.showMessage("请检查您的用户名。", true);
      } else if (!userPasswordElement.isValid) {
        // this.showMessage("请检查您的密码", true);
      } else {
        this.userData = {
          email: userEmailElement.inputValue,
          password: userPasswordElement.inputValue
        };

        this.$store.dispatch("user/login", this.userData);

      }
    });
    this.redirectUser();
  },
  methods: {
    handleState: function () {
      toggleDisabableElements(this.isLoginOperationRunning)

    },
    handleError: function () {
      if (this.loginError !== "")
        showMessage(this.loginError, true)

    },
    loginUser: async function (payload) {
      this.$store.dispatch("user/setLoginError", "");
      console.log("is logginh in user " + JSON.stringify(payload));
      this.$store.dispatch("user/setLoginOperation", true);
      try {
        const usersRef = collection(db, USERS_COLLECTION);

        const q = query(usersRef, where("email", "==", payload.email));
        const contactQ = query(
          usersRef,
          where("password", "==", payload.password)
        );

        const querySnapshot = await getDocs(q);
        const contactQuerySnapshot = await getDocs(contactQ);

        if (!querySnapshot.empty && !contactQuerySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            this.$store.dispatch("user/setLoginOperation", false);
            this.$store.dispatch("user/setUser", doc.data());
            this.$store.dispatch("user/setUserRole", doc.data().role);
            this.$store.dispatch("user/setIsLoggedIn", true);
            this.$router.push("/visitors")
          });
        } else {
          // sign in failed
          throw "Failed to find user with email and password";
        }
      } catch (error) {
        this.$store.dispatch("user/setLoginOperation", false);
        this.$store.dispatch("user/setLoginError",
          "Failed to login, please check your credentials or contact system administrator"
        );
        this.$store.dispatch("user/setIsLoggedIn", false);
        console.log(
          "error " + error.code + "  " + error.message + "  " + error
        );
      }
    },
    showLoadingState(show) {
      this.$refs.q_root_overlay.run = show;
    },

    redirectUser: function () {

      this.showLoadingState(this.isLoadingUser);
      if (this.isLoadingUser) return;
      console.log("my role osdf asdf  " + this.userRole + "  " + this.isLoggedIn)

    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.q-text-container {
  padding-right: 32px;
}

.q-layout-row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.q-credit-span {
  position: absolute;
  top: 95%;
  font-size: 0.6em;
}

.q-overlay {
  position: absolute;
  top: 0%;
  background-color: transparent;
  width: 100%;
  height: 100%;
}
</style>
