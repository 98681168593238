<template>
    <div class="q-display-flex-row   "
        style="width: 100%;background-color: var(--admin-canvas-bg);max-height: 100vh;overflow: hidden">

        <NavigationBarComponent></NavigationBarComponent>

        <main class="q-display-flex-column" style="width: 100%;max-height: 100vh;overflow: auto;">
            <div class="q-display-flex-row " style="width: 100%;min-height: 56px !important">

                <AdminHeaderComponent></AdminHeaderComponent>
            </div>
            <div class="q-display-flex-row ">
                <router-view> </router-view>
            </div>
        </main>
    </div>
</template>

<script>
import AdminHeaderComponent from "../../../components/admin/HeaderComponent.vue"
import NavigationBarComponent from "../../../components/admin/NavigationBarComponent.vue"

export default {
    name: "AdminPanel1",
    components: {
        AdminHeaderComponent,
        NavigationBarComponent,
        // DetailsComponent
    },




};
</script>
<style scoped></style>
