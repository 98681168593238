<template>
    <div class=" q-display-flex-column  q-center q-flex-center-items " style="width: 100%;">


        <DesktopRootAdminVisitorsView :style="visibleOnAndroid"
            @handleCurrentSelectionChanged="handleCurrentSelectionChanged" @handleSearch="handleSearch"
            :tableData="tableData" :hasData="hasData" :currentPresetId="currentPresetId"
            @viewUserDetails="viewUserDetails" @print="print" @setCurrent="setCurrent" @loadPresets="loadPresets"
            :currentPresetData="currentPresetData" :isCurrentPreset="isCurrentPreset"
            :showTableELement="showTableELement" :showPlaceHolderELement="showPlaceHolderELement"
            :rowControls="rowControls" :controls="controls" :supportedViewActions="supportedViewActions"
            :totalPresets="totalPresets" @exportTable="exportTable" :headers="headers"></DesktopRootAdminVisitorsView>

        <AndroidRootAdminVisitorsView :style="visibleOnDesktop"
            @handleCurrentSelectionChanged="handleCurrentSelectionChanged" @handleSearch="handleSearch"
            :tableData="tableData" :hasData="hasData" :currentPresetId="currentPresetId"
            @viewUserDetails="viewUserDetails" @setCurrent="setCurrent" @loadPresets="loadPresets"
            :currentPresetData="currentPresetData" :isCurrentPreset="isCurrentPreset"
            :showTableELement="showTableELement" :showPlaceHolderELement="showPlaceHolderELement"
            :rowControls="rowControls" :controls="controls" @exportTable="exportTable" @print="print"
            :supportedViewActions="supportedViewActions" :totalPresets="totalPresets" :headers="headers">

        </AndroidRootAdminVisitorsView>


    </div>
</template>

<script>

import { showLoadingState, showMessage, sortJsonKeys, joinArrayToStr, isPending, parseDate } from "../../../public/js/utils.js"

import DesktopRootAdminVisitorsView from '../sections/desktop/DesktopRootAdminVisitorsView.vue'
import AndroidRootAdminVisitorsView from '../sections/android/AndroidRootAdminVisitorsView.vue'
import { collection, getDocs } from "firebase/firestore";
import { db, VISITORS_COLLECTION, PENDING_STATUS, ON_GOING_STATUS, COMPLETE_STATUS, MAX_SMALL_DEVICE_WIDTH } from "../../firebaseInit.js"
import Papa from "papaparse";


import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'

export default {
    name: "users-compoent",
    components: {
        DesktopRootAdminVisitorsView,
        AndroidRootAdminVisitorsView
    },
    data() {
        return {
            currentPresetId: "",
            hasData: true,
            totalPresets: 0,
            deviceWidth: 0,
            rowControls: [

                {
                    "key": 0,
                    "name": "Edit",
                    "toolTip": "Edit Visitor",

                },
                {
                    "key": 2,
                    "name": "Print",
                    "toolTip": "Print Visitor",

                },
                {
                    "key": 3,
                    "name": "Sign Out",
                    "toolTip": "Sign Out Visitor",

                },
                {
                    "key": 1,
                    "name": "Delete",
                    "toolTip": "Delete Visitor",

                },
            ],
            controls: [
                {
                    "key": 0,
                    "name": "Refresh",
                    "toolTip": "Refresh Visitor",
                    "icon": "ri-refresh-line"
                },
                {
                    "key": 1,
                    "name": "Add",
                    "toolTip": "Add Visitor",
                    "icon": "ri-add-circle-line"
                },

                {
                    "key": 4,
                    "name": "Filter",
                    "toolTip": "Filter Visitors",
                    "icon": "ri-filter-line"
                },
                {
                    "key": 5,
                    "name": "Export",
                    "toolTip": "Export data",
                    "icon": "ri-export-line"
                },

            ], headers: [

                { "name": "Name", "key": "" },
                { "name": "Contact", "key": "" },
                { "name": "Date", "key": "" },
                { "name": "Purpose of visit", "key": "" },
                { "name": "Person to meet", "key": "" },
                { "name": "Status", "key": "" },

            ],
            tableData: []

        }
    },
    props: {
        users: [],
    },
    computed: {
        visibleOnDesktop() {

            return this.deviceWidth > MAX_SMALL_DEVICE_WIDTH ? "display:none !important" : "display:flex"
        },
        visibleOnAndroid() {

            return this.deviceWidth <= MAX_SMALL_DEVICE_WIDTH ? "display:none !important" : "display:flex"
        },
        isLoadingUser() {
            return this.$store.getters["admin/isReloadingLoadingUser"];
        },
        isLoggedIn() {
            return this.$store.getters["admin/isLoggedIn"];
        },
        showPlaceHolderELement() {
            return !this.hasData ? "showPlaceHolder" : ""
        },
        showTableELement() {
            return this.hasData ? "" : "hideTable"
        },
        currentPresetData() {
            return this.$store.getters["current/currentVisitor"];
        },
        isCurrentPreset() {
            var data = this.$store.getters["current/currentVisitor"]
            console.log("mi == " + "  ")

            if (data.objectId != undefined && this.currentPresetId == data.objectId)
                return "current-row"
            return ""
        }

    },
    watch: {
        isLoadingUser(new_, old_) {
            if (new_ != old_) {
                this.loadPresets();
            }
        },
        currentPresetData(new_, old_) {

            if (new_ != old_) {
                this.setCurrentPresetId();
            }
        },

    },
    methods: {
        nextPage: function () { },
        print: function (data) {

            console.log("mydfo sad fasfa sf " + JSON.stringify(data))

            //var skippedKeys = ["photoUrl", "createdBy", "status", "path", "id", "objectId", "statusText"]
            var mData = []
            // for (var key in data) {
            //     if (skippedKeys.includes(key)) {
            //         continue
            //     }
            //     mData.push({
            //         "Title": key,
            //         "Content": data[key]
            //     })
            // }
            mData.push({
                "Title": "Name",
                "Content": data["name"]
            })
            mData.push({
                "Title": "Date",
                "Content": data["expectedDate"]
            })
            mData.push({
                "Title": "Person To Meet",
                "Content": data["personToMeet"]
            })
            mData.push({
                "Title": "Purpose",
                "Content": data["purpose"]
            })
            mData.push({
                "Title": "Address",
                "Content": data["address"]
            })
            mData.push({
                "Title": "Contact",
                "Content": data["contact"]
            })
            mData.push({
                "Title": "Time In",
                "Content": data["timeIn"] != undefined ? data["timeIn"] : ""
            })
            mData.push({
                "Title": "Time Out",
                "Content": data["timeOut"] != undefined ? data["timeOut"] : ""
            })

            mData.push({
                "Title": "Visitor`s Signature", "Content": ""
            })
            console.log("my sdf asfs f " + mData)
            window.createTable(mData)
            window.printAllTable()

        },
        exportTable: function (params) {
            console.log("musdi fasdf as f" + JSON.stringify(params))
            var data = []
            this.tableData.forEach(function (argData) {
                var element = argData
                console.log("mu fsdfasdf " + element.address)
                delete element['path'];
                delete element['objectId'];
                delete element["id"]
                delete element["photoUrl"]
                delete element["createdBy"]

                console.log("my osd fasd ff = =  " + element.timeIn + "  " + JSON.stringify(element))
                if (element.timeOut == undefined) {
                    element.timeOut = ""
                }
                if (!element.timeIn == undefined) {
                    console.log("reset sdo fasdof ")
                    element.timeIn = ""
                }
                if (element.address == undefined) {
                    element.address = ""
                }


                switch (data.status) {
                    case PENDING_STATUS:
                        data.statusText = "Pending"
                        break;
                    case ON_GOING_STATUS:
                        data.statusText = "On Going"
                        break;
                    case COMPLETE_STATUS:
                        data.statusText = "Complete"
                        break;
                    default:
                        data.statusText = "Unknown"
                        break;
                }
                delete element["status"]
                console.log("e spf asdf asf " + JSON.stringify(element))
                data.push(sortJsonKeys(element))
            });
            var table = window.createTable(data)
            console.log("mudf, sdf asf  = = = " + data.length + "  " + this.tableData.length)
            if (params.id == 1) {
                console.log("sd fasdf asdf " + this.$refs.table_)

                window.exportTableToExcel(table)
            } else if (params.id == 2) {

                var blob = new Blob([Papa.unparse(data)], { type: 'text/csv;charset=utf-8;' });

                var link = document.createElement("a");
                var filename = "Hr Export" + window.getCurrentDateAsString() + ".csv";
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else if (params.id === 3) {

                // Default export is a4 paper, portrait, using millimeters for units
                const doc = new jsPDF('p', 'mm', 'a3');

                console.log("cratd spdf asdf ")
                var __data__ = [];
                var headers = ["name", "asa"]
                __data__.push(headers)
                __data__.push(data)

                console.log("sdf sadf " + table.id)
                autoTable(doc, { html: `#${table.id}`, styles: { minCellWidth: 20 } });
                doc.save("table.pdf");

            } else if (params.id === 4) {
                window.printAllTable()
                // Default export is a4 paper, portrait, using millimeters for units

            }
            window.clearTable();
        },
        setCurrentPresetId: function () {
            this.currentPresetId = this.currentPresetData && this.currentPresetData.objectId ? this.currentPresetData.objectId : ""

        },
        formatData: function (data) {
            return joinArrayToStr(data)
        },
        handleSearch: function (event, data) {

            console.trace()
            if (!data || data === "") {
                this.loadPresets()
            } else {
                this.loadPresets({
                    query: data
                })
            }
        },
        loadPresets: async function (data) {
            console.log("my dattasdasdf asf " + JSON.stringify(data) + "  " + event)
            // reset current preset
            this.$store.dispatch("current/setCurrentVisitor", {})
            try {
                console.log("is laodsdofaso fasf asf  ")
                showLoadingState(true)
                var querySnapshot = null
                var isQueringDate = false

                var isQueringName = false
                var isQueryingByAdmin = false
                var searchData = data

                if (data) {
                    if (data.fromDate && data.fromDate != "" && data.toDate && data.toDate != "") {
                        // find in date range
                        isQueringDate = true
                    } else if (data.admin && data.admin != "") {
                        isQueryingByAdmin = true
                    }
                    else {
                        // find name
                        isQueringName = true
                    }
                }


                querySnapshot = await getDocs(collection(db, VISITORS_COLLECTION));

                this.tableData = [];
                showLoadingState(false)
                querySnapshot.forEach((doc) => {


                    const data = doc.data();
                    data.objectId = doc.id
                    var targetData = false
                    console.log("my sdf asdf asf " + JSON.stringify(data))
                    if (searchData) {
                        console.log("is qerif datdf asdf " + JSON.stringify(searchData))

                        if (isQueringDate) {
                            var resultDate = parseDate(data.expectedDate)
                            var toDate = parseDate(searchData.toDate)
                            var fromDate = parseDate(searchData.fromDate)


                            console.log("my sdf asdf asf " + resultDate + "  " + toDate + "  " + fromDate)
                            if (toDate != fromDate) {
                                // query date between
                                if (resultDate >= fromDate && resultDate <= toDate) {
                                    targetData = true
                                }
                            } else {
                                // query oly current date
                                if (resultDate == toDate) {
                                    console.log("is fosadi foasdf asf ")
                                    targetData = true
                                }
                            }
                        }
                        if (isQueringName) {
                            console.log("si fsad fasdf sf" + JSON.stringify(searchData))
                            if (data.name.includes(searchData.query)) {
                                targetData = true
                            }
                        }
                        if (isQueryingByAdmin) {
                            var createdByQuery = searchData.admin
                            if (data.createdBy === createdByQuery) {
                                targetData = true
                            }

                        }
                    } else {
                        targetData = true
                    }
                    if (targetData) {
                        console.log("musdo fasd fasf asdf asdf " + JSON.stringify(data))

                        const mIsPending = isPending(data.expectedDate);
                        console.log("is pending " + mIsPending)
                        switch (data.status) {
                            case PENDING_STATUS:
                                data.statusText = "Pending"
                                break;
                            case ON_GOING_STATUS:
                                data.statusText = "On Going"
                                break;
                            case COMPLETE_STATUS:
                                data.statusText = "Complete"
                                break;
                            default:
                                data.statusText = "Unknown"
                                break;
                        }

                        this.tableData.push(data)
                    } else {
                        console.log("target dat i empty")
                    }

                });
                this.hasData = this.tableData.length > 0


            } catch (error) {
                console.log("mysdf asdfa sf " + error)
                showLoadingState(false)
                showMessage("Failed to load visitors", true)
            }
            this.countPresets()
        },
        countPresets: async function () {

            if (!this.isLoggedIn)
                return

            // try {
            //     var result = await axios.get(
            //         `${this.$store.getters["urls/adminBaseUrl"]}/presets/all/limit=0&count=1`,
            //     );
            //     this.totalPresets = result.data.count;

            // } catch (error) {
            //     console.warn("Failed to count presets , retrying " + error)
            //     setTimeout(() => {
            //         this.countPresets()
            //     }, 10000);
            // }
        },
        viewUserDetails: function (event, data) {

            this.$store.dispatch("current/setCurrentVisitor", data)
            this.$refs.editDialog.show()
        },
        setCurrent: function (event, row) {
            var data_ = this.tableData.filter(a => {
                return a.objectId === row.objectId
            })

            this.$store.dispatch("current/setCurrentVisitor", data_.length > 0 ? data_[0] : row)
        },
        handleRowControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data) + " ***** " + JSON.stringify(this.currentPresetData))
            if (this.currentPresetData.objectId) {
                if (data.key === 0) {
                    this.editPreset()
                } else if (data.key === 1) {
                    this.$refs.editDialog.startDeleteUser()
                }
            } else {
                showMessage("Please select a preset ", false)
            }
        },
        handleControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data))
            if (data.key === 1) {
                this.createPreset()
            } else if (data.key === 0) {
                this.loadPresets()
            } else if (data.key === 2) {
                if (this.currentPresetData.objectId) {
                    this.editPreset()
                } else {
                    showMessage("Please select a preset ", false)
                }
            } else if (data.key === 3) {

                if (this.currentPresetData.objectId) {
                    this.$refs.editDialog.startDeleteUser()
                } else {
                    showMessage("Please select a preset ", false)
                }
            }
        },
        editPreset: function () {
            this.$refs.mainEditDialog.show()
        },
        createPreset: function () {

            this.$refs.detailsDialog.show()
        }
    },
    created() {
        this.deviceWidth = window.innerWidth

    },
    mounted: function () {
        this.deviceWidth = window.innerWidth

        this.loadPresets();

    },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-content-container {
    position: relative;
}


.q-place-holder {
    max-width: 1000px;
    background-color: transparent;
    width: 500px;
    height: 500px;
    display: none;
}

.q-place-holder p {
    font-size: 1em;
    opacity: .5;
    text-align: center;
    max-width: 250px;
}

.q-place-holder i {
    font-size: 7em;
    opacity: .3;
}

.hideTable {
    visibility: hidden !important;
}

.showPlaceHolder {
    display: flex !important;
}
</style>
