<template>

    <div class="main-content-container q-display-flex-column  q-center q-flex-center-items" :style="visibleOnAndroid">
        <h2 class="q-align-self-left" style="margin-top: 0; margin-bottom: 32px;">Visitors</h2>
        <ContentHeaderComponent :controls="controls" @controlData="handleControlClick" @searchItem="handleSearch"
            searchPlaceHolder="Find a visitor by name">
        </ContentHeaderComponent>

        <div :class="showPlaceHolderELement"
            class=" q-place-holder q-display-flex-column  q-center q-flex-center-items">

            <i class="ri-group-line"></i>
            <p>Oops, No visitors yet, click "Add" to get started</p>
        </div>
        <table ref="table_" :class="showTableELement" style="visibility: visible;">
            <tr style="background-color:transparent;border-radius: 8px;">
                <th v-for="(header) in headers" :key="header.key">
                    {{ header.name }}
                </th>
                <th style="width: 32px;">

                </th>
            </tr>
            <tr data-can-be-disabled :class="currentPresetId == row.objectId ? 'current-row' : ''" class="q-animatable"
                v-for="(row) in tableData" :key="row.objectId">

                <td @click="viewUserDetails($event, row)">
                    {{ row.name }}
                </td>
                <td @click="viewUserDetails($event, row)">
                    {{ row.contact }}
                </td>
                <td @click="viewUserDetails($event, row)">
                    {{ row.expectedDate }}
                </td>
                <td @click="viewUserDetails($event, row)">
                    {{ row.purpose }}
                </td>
                <td @click="viewUserDetails($event, row)">
                    {{ row.personToMeet }}
                </td>
                <td @click="viewUserDetails($event, row)">
                    <div class="isPending   q-display-flex-column  q-center q-flex-center-items">
                        <p>{{ row.statusText }}</p>
                    </div>
                </td>
                <OptionsPopup @setCurrent="setCurrent" @controlData="handleRowControlClick" :controls="rowControls"
                    :rowData="row">
                </OptionsPopup>

            </tr>
        </table>

    </div>
    <PreviewPresetDrawer @edit="editPreset" @deleted="loadPresets" ref="editDialog">
    </PreviewPresetDrawer>
    <EditPresetDialog ref="mainEditDialog" @edited="loadPresets" @deleted="loadPresets">
    </EditPresetDialog>
    <CreatePresetDrawer @created="loadPresets" ref="detailsDialog">
    </CreatePresetDrawer>
    <VisitorsFilterDialog ref="filterDialog" @query="loadPresets"></VisitorsFilterDialog>
    <ExportDialog ref="exportDialog" @export="exportTable">

    </ExportDialog>

    <EditAskariDialog ref="askariEditDialog" @edited="loadPresets" @deleted="loadPresets" expectedDateReadonly="true"
        purposeReadonly="true" contactReadonly="true" hostReadonly="true" idRequired="true" showName="false"
        showNin="true" showImage="true" @showCamera="showMediaCam" :imageData="capturedData"></EditAskariDialog>
</template>

<script>


import ContentHeaderComponent from "../../../components/admin/ContentHeaderComponent.vue"
import OptionsPopup from "../../../components/admin/OptionsPopup.vue"
import VisitorsFilterDialog from '../../../components/admin/VisitorsFilterDialog.vue'
import EditPresetDialog from "../../../components/admin/EditUserVisitor.vue"
import CreatePresetDrawer from "../../../components/admin/CreateVisitorDrawer.vue"
import PreviewPresetDrawer from "../../../components/admin/PreviewVisitorDrawer.vue"
import ExportDialog from '../../../components/admin/ExportDialog.vue'
import EditAskariDialog from "../../../components/admin/EditAskariVisitor.vue"
import { ON_GOING_STATUS } from "../../../firebaseInit.js"

import { showMessage, joinArrayToStr } from "../../../../public/js/utils.js"
import { MAX_SMALL_DEVICE_WIDTH } from "../../../firebaseInit.js"

export default {

    name: "AdminDesktopView",
    props: ["tableData", "hasData", "currentPresetId", "rowControls", "controls", "supportedViewActions", "totalPresets", "headers"],
    components: {
        OptionsPopup,
        VisitorsFilterDialog,
        ContentHeaderComponent,
        EditPresetDialog,
        CreatePresetDrawer,
        PreviewPresetDrawer,
        ExportDialog,
        EditAskariDialog
    },
    created() {
        this.deviceWidth = window.innerWidth

    }, mounted: function () {
        this.deviceWidth = window.innerWidth
    },
    computed: {
        visibleOnAndroid() {
            console.log("mt sdfo asd fasdf asf = = = ==  " + this.deviceWidth + "  " + MAX_SMALL_DEVICE_WIDTH)
            return this.deviceWidth <= MAX_SMALL_DEVICE_WIDTH ? "display:none !important" : "display:flex"
        },
        showPlaceHolderELement() {
            return !this.hasData ? "showPlaceHolder" : ""
        },
        showTableELement() {
            return this.hasData ? "" : "hideTable"
        },
        currentPresetData() {
            return this.$store.getters["current/currentVisitor"];
        },
        isCurrentPreset() {
            var data = this.$store.getters["current/currentVisitor"]
            console.log("mi == " + "  ")

            if (data.objectId != undefined && this.currentPresetId == data.objectId)
                return "current-row"
            return ""
        }

    },
    data() {
        return {
            deviceWidth: 0
        }
    },

    methods: {
        exportTable: function (data) {
            this.$emit('exportTable', data)
        },
        handleCurrentSelectionChanged: function (data) {

            this.$emit('handleCurrentSelectionChanged', data)
        },
        formatData: function (data) {
            return joinArrayToStr(data)
        },
        handleSearch: function (event, data) {
            this.$emit('handleSearch', event, data)

        },

        viewUserDetails: function (event, data) {
            // this.$emit('viewUserDetails', event, data)
            this.$store.dispatch("current/setCurrentVisitor", data)
            this.editPreset()
        },
        setCurrent: function (event, row) {
            // var data_ = this.tableData.filter(a => {
            //     return a.objectId === row.objectId
            // })

            // this.$store.dispatch("current/setCurrentVisitor", data_.length > 0 ? data_[0] : row)

            this.$emit('setCurrent', event, row)
        },
        handleRowControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data) + " ***** " + JSON.stringify(this.currentPresetData))
            if (this.currentPresetData.objectId) {
                if (data.key === 0) {
                    this.editPreset()
                } else if (data.key === 1) {
                    this.$refs.editDialog.startDeleteUser()
                } else if (data.key === 2) {
                    console.log("is prepo pfasdf asdf " + JSON.stringify(this.$store.getters["current/currentVisitor"]))
                    this.$emit("print", this.$store.getters["current/currentVisitor"])
                } else if (data.key === 3) {
                    if (this.currentPresetData.status === ON_GOING_STATUS) {
                        this.$refs.askariEditDialog.show()
                    } else {
                        showMessage("Selected visitor`s appointment is not active")
                    }
                }
            } else {
                showMessage("Please select a visitor ", false)
            }
        },
        loadPresets: function (data) {
            this.$emit('loadPresets', data)
        },
        handleControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data))
            if (data.key === 1) {
                this.createPreset()
            } else if (data.key === 0) {
                this.loadPresets()
            } else if (data.key === 2) {
                if (this.currentPresetData.objectId) {
                    this.editPreset()
                } else {
                    showMessage("Please select a visitor ", false)
                }
            } else if (data.key === 3) {

                if (this.currentPresetData.objectId) {
                    this.$refs.editDialog.startDeleteUser()
                } else {
                    showMessage("Please select a visitor ", false)
                }
            } else if (data.key === 4) {
                this.$refs.filterDialog.show()
            } else if (data.key === 5) {
                this.$refs.exportDialog.show();
            }
        },
        editPreset: function () {
            this.$refs.mainEditDialog.show()
        },
        createPreset: function () {

            this.$refs.detailsDialog.show()
        }
    },
};
</script>
<style scoped></style>
