<template>
    <div>
    </div>
</template>

<script>

import { onAuthStateChanged } from "firebase/auth";
import {
    auth,
    USERS_COLLECTION,
    USERS_DEFAULT_ROLE,
    db, USERS_SUPER_ADMIN_ROLE, USERS_ADMIN_ROLE
} from "../firebaseInit";
import { collection, getDocs, query, where } from "firebase/firestore";
import { toggleDisabableElements, showMessage } from "../../public/js/utils.js"

export default {
    name: "UserPoints",
    props: ["adminUrl", "userUrl"],
    mounted: function () {

        console.log("is laosdf sfasf ")

        onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log("My us sodfa sdf " + JSON.stringify(user))


                this.$store.dispatch("user/setUser", user);

                (async () => {
                    try {
                        const { claims } = await auth.currentUser.getIdTokenResult(true);
                        console.log("My claim is  " + JSON.stringify(claims))
                        this.$store.dispatch("user/setUserRole", claims.role);
                        if (claims.role === USERS_SUPER_ADMIN_ROLE) this.$router.push('/admin/overview');
                        else if (claims.role === USERS_DEFAULT_ROLE) this.$router.push('/all-visitors');
                        else if (claims.role === USERS_ADMIN_ROLE) this.$router.push('/admin-visitors');
                        else
                            this.$router.push('/');
                    } catch (e) {
                        console.log("is loading e " + e)
                    }


                })()



            } else {
                this.$router.push("/login")
            }

        })

    },
    methods: {
        handleState: function () {
            toggleDisabableElements(this.isLoginOperationRunning)

        },
        fetcUserRole: async function (payload) {
            console.log("fetchinfg role ")
            try {



                console.log("My data issdfa sf " + JSON.stringify(payload));
                toggleDisabableElements(true)
                const usersRef = collection(db, USERS_COLLECTION);
                const q = query(usersRef, where("uid", "==", payload));

                const querySnapshot = await getDocs(q);
                toggleDisabableElements(false)

                var role = USERS_DEFAULT_ROLE

                console.log("user roe exists ");
                querySnapshot.forEach((doc) => {
                    console.log("fetching user role  ");
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());
                    console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
                    console.log("my role is " + doc.data().role);
                    role = doc.data().role;

                    if (!role || role == "")
                        role = USERS_DEFAULT_ROLE
                });

                console.log("settign suer role " + role)
                this.$store.dispatch("user/setUserRole", role);

                if (role == USERS_DEFAULT_ROLE)
                    this.$router.push(this.userUrl)
                else
                    this.$router.push(this.adminUrl)


            } catch (error) {
                console.log("Failed to fetch role " + error);

                showMessage(

                    "Failed to fetch role, you will be signed out"
                    , true);
                setTimeout(() => {
                    // this.$router.push("signout")
                }, 3000);
            }
        },
    },

};
</script>
