<template>
    <main class="q-display-flex-column" style="width: 100%;max-height: 100vh;overflow: auto;">
        <div class="q-display-flex-row " style="width: 100%;min-height: 56px !important">

            <AdminHeaderComponent></AdminHeaderComponent>
        </div>
        <div class="q-display-flex-row ">
            <div class=" q-display-flex-column  q-center q-flex-center-items" style="width: 100%;">
                <div class="main-content-container q-display-flex-column  q-center q-flex-center-items">
                    <h2 class="q-align-self-left" style="margin-top: 32; margin-bottom: 0px;">{{
                        this.$store.getters["user/greeting"] }}</h2>
                    <div class="date-container q-center  q-flex-center-items q-align-self-left q-display-flex-row">
                        <span class=" q-display-flex-row q-center  q-flex-center-items"><i
                                class="ri-calendar-line"></i></span>
                        <p>{{ this.$store.getters["user/fullDate"] }}</p>
                    </div>

                    <ContentHeaderComponent :controls="controls" :supportedActions="supportedViewActions"
                        @controlData="handleControlClick" @searchItem="handleSearch"
                        searchPlaceHolder="Find a visitor by name"
                        @currentSelectionChanged="handleCurrentSelectionChanged" :style="visibleOnAndroid">
                    </ContentHeaderComponent>



                    <div class="q-layout-container padding-top-0" style="padding: 0">
                        <div class="q-layout-row padding-top-0 padding-bottom-0" style="padding: 0;">

                            <div class="q-col-sm-12 q-col-md-12 q-col-lg-12 q-col-xl-12 q-display-flex-column flow-center padding-top-0  padding-bottom-0"
                                style="padding-top: 0 !important;">


                                <div :class="showPlaceHolderELement"
                                    class=" q-place-holder q-display-flex-column  q-center q-flex-center-items">

                                    <i class="ri-group-line"></i>
                                    <p>Oops, No visitors yet,</p>
                                </div>

                                <table :class="showTableELement" style="visibility: visible;">
                                    <tr style="background-color:transparent;border-radius: 8px;" :key="tableKey">
                                        <th v-for="(header) in headers" :key="header.key">
                                            {{ header.name }}
                                        </th>
                                        <th style="width: 32px;">
                                        </th>
                                    </tr>
                                    <tr data-can-be-disabled class="q-animatable" v-for="(row) in tableData"
                                        :key="row.objectId"
                                        :class="currentPresetId == row.objectId ? 'current-row' : ''">
                                        <td @click="viewUserDetails($event, row)">
                                            {{ row.name }}
                                        </td>
                                        <td @click="viewUserDetails($event, row)">
                                            {{ row.expectedDate }}
                                        </td>
                                        <td @click="viewUserDetails($event, row)">
                                            {{ row.purpose }}
                                        </td>
                                        <td @click="viewUserDetails($event, row)">
                                            {{ row.contact }}
                                        </td>

                                        <OptionsPopup @setCurrent="setCurrent" @controlData="handleRowControlClick"
                                            :controls="rowControls" :rowData="row">
                                        </OptionsPopup>


                                    </tr>
                                </table>


                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

        <EditPresetDialog ref="mainEditDialog" @edited="loadPresets" @deleted="loadPresets" editExpectedDate="false"
            purposeReadonly="false" hostReadonly="false" idRequired="false" showName="true">
        </EditPresetDialog>
        <CreatePresetDrawer @created="loadPresets" ref="detailsDialog">
        </CreatePresetDrawer>
        <PreviewPresetDrawer @edit="editPreset" @deleted="loadPresets" ref="editDialog">
        </PreviewPresetDrawer>
        <EditAskariDialog ref="askariEditDialog" @edited="loadPresets" @deleted="loadPresets"
            expectedDateReadonly="true" purposeReadonly="true" contactReadonly="true" hostReadonly="true"
            idRequired="true" showName="false" showNin="true" showImage="true" @showCamera="showMediaCam"
            :imageData="capturedData"></EditAskariDialog>
    </main>
</template>

<script>

import AdminHeaderComponent from "../../../components/admin/UserHeaderComponent.vue"
import ContentHeaderComponent from "../../../components/admin/ContentHeaderComponent.vue"
import OptionsPopup from "../../../components/admin/OptionsPopup.vue"

import EditPresetDialog from "../../../components/admin/EditUserVisitor.vue"
import CreatePresetDrawer from "../../../components/admin/CreateVisitorDrawer.vue"
import PreviewPresetDrawer from "../../../components/admin/PreviewVisitorDrawer.vue"
import EditAskariDialog from "../../../components/admin/EditAskariVisitor.vue"
import { ON_GOING_STATUS } from "../../../firebaseInit.js"

import { showMessage, joinArrayToStr } from "../../../../public/js/utils.js"

export default {

    name: "AdminDesktopView",
    props: ["tableData", "hasData", "currentPresetId", "rowControls", "controls", "supportedViewActions", "totalPresets", "headers"],
    components: {
        OptionsPopup,
        AdminHeaderComponent,
        ContentHeaderComponent,
        EditPresetDialog,
        CreatePresetDrawer,
        PreviewPresetDrawer,
        EditAskariDialog
    },
    computed: {

        showPlaceHolderELement() {
            return !this.hasData ? "showPlaceHolder" : ""
        },
        showTableELement() {
            return this.hasData ? "" : "hideTable"
        },
        currentPresetData() {
            return this.$store.getters["current/currentVisitor"];
        },
        isCurrentPreset() {
            var data = this.$store.getters["current/currentVisitor"]
            console.log("mi == " + "  ")

            if (data.objectId != undefined && this.currentPresetId == data.objectId)
                return "current-row"
            return ""
        }

    },
    data() {
        return {
        }
    },

    methods: {


        handleCurrentSelectionChanged: function (data) {

            this.$emit('handleCurrentSelectionChanged', data)
        },
        formatData: function (data) {
            return joinArrayToStr(data)
        },
        handleSearch: function (event, data) {
            this.$emit('handleSearch', event, data)

        },

        viewUserDetails: function (event, data) {
            // this.$emit('viewUserDetails', event, data)
            this.$store.dispatch("current/setCurrentVisitor", data)
            this.editPreset()
        },
        setCurrent: function (event, row) {
            // var data_ = this.tableData.filter(a => {
            //     return a.objectId === row.objectId
            // })

            // this.$store.dispatch("current/setCurrentVisitor", data_.length > 0 ? data_[0] : row)

            this.$emit('setCurrent', event, row)
        },
        handleRowControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data) + " ***** " + JSON.stringify(this.currentPresetData))
            if (this.currentPresetData.objectId) {
                if (data.key === 0) {
                    this.editPreset()
                } else if (data.key === 1) {
                    this.$refs.editDialog.startDeleteUser()
                } else if (data.key === 2) {
                    if (this.currentPresetData.status === ON_GOING_STATUS) {
                        this.$refs.askariEditDialog.show()
                    } else {
                        showMessage("Selected visitor`s appointment is not active")
                    }
                }
            } else {
                showMessage("Please select a visitor ", false)
            }
        },
        loadPresets: function (data) {
            this.$emit('loadPresets', data)
        },
        handleControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data))
            if (data.key === 1) {
                this.createPreset()
            } else if (data.key === 0) {
                this.loadPresets()
            } else if (data.key === 2) {
                if (this.currentPresetData.objectId) {
                    this.editPreset()
                } else {
                    showMessage("Please select a visitor ", false)
                }
            } else if (data.key === 3) {

                if (this.currentPresetData.objectId) {
                    this.$refs.editDialog.startDeleteUser()
                } else {
                    showMessage("Please select a visitor ", false)
                }
            }
        },
        editPreset: function () {
            this.$refs.mainEditDialog.show()
        },
        createPreset: function () {

            this.$refs.detailsDialog.show()
        }
    },
};
</script>
<style scoped></style>
