<template>
  <div class=" q-display-flex-column  q-center q-flex-center-items" style="height: 100vh;">
    <h1>Welcome</h1>

    <div class=" q-sample-nav q-display-flex-row q-center q-flex-center-items">
      <RouterLink to="/login">Login</RouterLink>

    </div>
    <router-view />
  </div>
</template>

<script>
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebaseInit"
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  computed: {

    isLoggedIn() {

      return (
        this.$store.getters["user/isLoggedIn"]

      );
    },

  },
  watch: {
    isLoggedIn(new_, old_) {
      if (new_ != old_) {
        console.log("is changed in home ")
      }
    },

  },
  mounted: function () {
    console.log("is sodaf osd as f")
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("My us sodfa sdf in home" + JSON.stringify(user))
        this.$store.dispatch("user/setUser", user);
        this.$store.dispatch("user/setIsLoggedIn", true);
      } else {
        console.log("user nor found " + JSON.stringify(user))
        this.$store.dispatch("user/setIsLoggedIn", false);
      }

    })
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.q-sample-nav a {
  margin: 16px;
  margin-left: 32px;
  margin-right: 32px;
  color: white;
  background-color: var(--gw_primary_color);
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;

}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
