<template>
    <dialog aria-modal="true" aria-label="user Details"
        class=" q-animatable q-delay-fast elevate-5 details-container  q-display-flex-column " ref="detailsDialog">
        <progress-overlay-element ref="q_overlay"></progress-overlay-element>

        <div class="drawer-header  q-display-flex-row q-align-stretch">
            <h3 style="margin: 0px;width: 100%;">Add</h3>
            <button data-can-be-disabled @click="close" class=" q-display-flex-column q-center q-flex-center-items"
                title="close">
                <i class="ri-close-circle-line q-align-self-right"></i>
            </button>

        </div>
        <div class="q-divider"></div>

        <div class="drawer-content-container">

            <div class=" q-layout-container q-progress-overlay-container content-container">

                <div class="q-layout-row  child-content-container">
                    <form action="#" ref="form" method="post" style="width: 100%;">
                        <div class="form-label-container q-display-flex-row ">
                            <label class="form-label" for="name">Full Names</label>
                        </div>
                        <form-input ref="nameElement" type="text" name="name" placeHolder="Full Names"
                            label=" "></form-input>


                        <fieldset>
                            <label for="ai">Choose type</label>
                            <div class="q-password-field">

                                <select name="actions" ref="supportedActions">
                                    <option v-for="(item) in supportedActions" :key="item.key" :value="item.name">
                                        {{ item.name }}</option>
                                </select>
                            </div>

                        </fieldset>

                        <div class="form-label-container q-display-flex-row ">
                            <label class="form-label" for="email">Email</label>
                        </div>
                        <form-input type="email" name="email" placeHolder="Email" label=" "></form-input>


                        <div class="form-label-container q-display-flex-row ">
                            <label class="form-label" for="NIN">National Identification Number(NIN)</label>
                        </div>
                        <form-input ref="iniInput" type="text" name="id" placeHolder="NIN" label=" "></form-input>




                        <div class="form-label-container q-display-flex-row ">
                            <label class="form-label" for="contact">Contact</label>
                        </div>
                        <form-input ref="contactInput" type="tel" name="contact" placeHolder="Contact"
                            label=" "></form-input>

                        <p class="error" :style="showError">{{ errorMsg }}</p>
                        <div class=" q-form-button-container q-display-flex-column  ">
                            <button ref="createButton" data-can-be-disabled type="submit" name="button"
                                class="btn anchor-button   " style="align-self:stretch;">Add</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </dialog>
</template>

<script>
import { showMessage, toggleDisabableElements, generateStr, isVaidNIN } from "../../../public/js/utils.js"
//import { collection, addDoc, query, where, getDocs, } from "firebase/firestore";
import {
    SUPPORTED_ROLES, USERS_SUPER_ADMIN_ROLE
} from "../../firebaseInit.js"


import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';

import axios from "axios";
var iti;

export default {
    name: "CreatePresetDrawer",
    components: {

    },
    data() {
        return {
            errorMsg: "",
            supportedActions: SUPPORTED_ROLES,
        }
    },
    computed: {
        showError: function () {
            if (this.errorMsg.length != 0)
                showMessage(this.errorMsg, true)

            return this.errorMsg.length == 0 ? "display:none" : "display:block"
        }
    },
    mounted() {

        this.$refs.form.addEventListener("submit", e => {
            e.preventDefault()
            this.createPreset()
        })
        const input = this.$refs.contactInput.inputElement
        // input.style.width = '465px'
        try {
            iti = intlTelInput(input, {

                allowDropdown: false,
                initialCountry: "Ug",
                strictMode: true,
                utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.12/build/js/utils.js"

            })
        } catch (e) {
            console.log("has error " + e)
        }
    },
    methods: {
        show: async function () {
            console.log("shoe model")
            this.$refs.detailsDialog.showModal()
            this.fetchSections()
            var w = this.$refs.detailsDialog.clientWidth
            const input = this.$refs.contactInput.inputElement

            console.log("my sdf assdpf asd-fasf as=fa =sdf sdf " + w)

            input.style.width = (w - 32) + 'px'
        },
        close: function () {
            this.$refs.detailsDialog.close()
        },
        showOverlay: function (show) {
            this.$refs.q_overlay.run = show
            toggleDisabableElements(show)
        },


        getSelctedSections: function () {
            var arr = [];
            console.clear()
            var chkBoxes = document.getElementsByClassName("checkBoxes"); // get all check box array
            for (var i = 0; i < chkBoxes.length; i++) {
                if (chkBoxes[i].checked) { // check if checked
                    arr.push(chkBoxes[i].value);
                }
            }
            return arr
        },


        fetchSections: async function () {
            // try {
            //     if (this.supportedPages.length == 0) {
            //         this.showOverlay(true)
            //         var result = await axios.get(`${this.$store.getters["urls/adminBaseUrl"]}/gw/sections`)
            //         this.supportedPages = result.data
            //         this.showOverlay(false)
            //     }

            // } catch (error) {
            //     console.log("my errpr " + error)
            //     this.showOverlay(false)
            //     showMessage("Failed to fetch pages, please try again", true)
            // }


        },
        createPreset: async function () {
            try {
                var elements = this.$refs.form.elements
                var isAllValid = true;
                var userData = {}
                this.errorMsg = ""


                Array.from(elements).forEach((input) => {

                    if (input.tagName === "FIELDSET") {

                        if (input.parentNode.tagName === "FORM-INPUT") {
                            var formInput = input.parentNode
                            if (!formInput.isValid && (formInput.inputType !== 5)) {
                                isAllValid = false;
                                this.errorMsg = "please check your data";
                            } else if (formInput.getAttribute("name") === "id") {
                                console.log("is ha dleo sdf sadf  nin " + isVaidNIN(formInput.inputValue) + "  " + formInput.inputValue)
                                if (!isVaidNIN(formInput.inputValue)) {
                                    isAllValid = false
                                    this.errorMsg = "Enter a valid NIN (14 characters long)";
                                }
                            } else {
                                console.log("my sid fasd fasdf as = = = " + formInput.inputType)
                                if (formInput.inputType === 5) {
                                    // -:
                                    iti.getValidationError();
                                    if (formInput.inputValue.length < 11) {
                                        isAllValid = false
                                        this.errorMsg = "Enter a valid phone number"
                                    } else {
                                        userData[formInput.getAttribute("name")] = formInput.inputValue
                                    }
                                }

                            }
                            userData[formInput.getAttribute("name")] = formInput.inputValue
                        }
                    }
                })
                var e = this.$refs.supportedActions

                userData.role = e.options[e.selectedIndex].text
                var p = generateStr(10)
                console.log(" mysd fasod fasdf asf ------  " + JSON.stringify(userData) + "  " + p)



                var currentUserRole = this.$store.getters["user/role"];
                console.log("my sdof asdf " + currentUserRole)
                if (currentUserRole != USERS_SUPER_ADMIN_ROLE) {
                    this.errorMsg = "Permission denied, please contact system administrator"
                    isAllValid = false

                }

                if (!isAllValid) {
                    showMessage(this.errorMsg, true)
                } else {
                    userData.password = p
                    userData.createdBy = this.$store.getters["user/uid"];
                    toggleDisabableElements(true)
                    // CREATE  user 
                    // fetch("http://localhost:8000/createuser")
                    //     .then((res) => res.json())
                    //     .then((gfg_articles) => console.log(gfg_articles));

                    // if (isAllValid)
                    //     return


                    var result = await axios.post(`${this.$store.getters["urls/baseUrl"]}/createuser`, userData)



                    console.log("my reslsdf asd fasdf " + JSON.stringify(result))

                    this.close()
                    this.$emit('created', '')
                    toggleDisabableElements(false)

                    // userData.uid = generateString(20)
                    // // write user to database

                    // console.log("my collsdfasd  " + USERS_COLLECTION + "  " + JSON.stringify(userData))


                    // // check if email exists
                    // const usersRef = collection(db, USERS_COLLECTION);

                    // const q = query(usersRef, where("email", "==", userData.email));

                    // const contactQ = query(usersRef, where("contact", "==", userData.contact));

                    // const querySnapshot = await getDocs(q);
                    // const contactQuerySnapshot = await getDocs(contactQ);
                    // console.log("my reslsdfasdf a " + querySnapshot.length)
                    // if (!querySnapshot.empty || !contactQuerySnapshot.empty) {
                    //     toggleDisabableElements(false)

                    //     this.errorMsg = !querySnapshot.empty ? "Email already exists" : "Contact already exists";
                    // } else {

                    //     const docRef = await addDoc(collection(db, USERS_COLLECTION), userData);

                    //     console.log("Document written with ID: ", docRef.id);


                    // }
                    // console.log("is daf pasdf asf " + VISITORS_COLLECTION)
                    // toggleDisabableElements(true)
                    // const docRef = await addDoc(collection(db, VISITORS_COLLECTION), userData);

                    // toggleDisabableElements(false)
                    // console.log("Document written with ID: ", docRef.id);
                    // this.close()
                    // this.$emit('created', '')
                }
                // var visitorData = {
                //     name:this.$refs. namedElement.inputValue
                // }
                //console.log("is daf pasdf asf " + VISITORS_COLLECTION)
                // const docRef = await addDoc(collection(db, VISITORS_COLLECTION), visitorData);
                //  console.log("Document written with ID: ", docRef.id);

                // var selectedSections = this.getSelctedSections()
                // if (selectedSections.length == 0) {
                //     showMessage("Please select where this preset will be accessed", true)
                //     return
                // }
                // var e = this.$refs.supportedActions
                // this.showOverlay(true)
                // let data = {
                //     name: this.$refs.inputElement.inputValue,
                //     sections: selectedSections,
                //     type: e.options[e.selectedIndex].text,
                // }
                // var result = await axios.post(`${this.$store.getters["urls/adminBaseUrl"]}/presets/create`, data)

                // console.log("my reslt sfa sfas f " + JSON.stringify(result))
                // if (result.data.objectId != "") {
                //     this.close()
                //     this.$emit('created', '')
                // } else {

                //     showMessage("Failed to create preset", true)
                // }

                // this.showOverlay(false)
            } catch (error) {
                toggleDisabableElements(false)
                console.log("my errpr " + JSON.stringify(error.response) + "  ")
                if (error.response && error.response.data) {
                    console.log("my erroesdf asf as fasaf " + JSON.stringify(error.response))
                    var errorData = error.response.data
                    if (errorData.code) {
                        this.errorMsg = errorData.code

                        // if (error.error == "username or password incorrect.") {
                        console.log("shoe capatac")

                    }
                } else {
                    showMessage(error.code, true)
                }
            }


        }
    }
};
</script>
<style scoped>
dialog {
    position: absolute;
}


.showDrawer {
    width: 400px !important;
    opacity: 1 !important;
}

.details-container {

    background-color: white;
    width: 600px;
    right: 0;
    top: 0;
    padding: 0px;

}

.drawer-header button:hover {
    background-color: var(--nav-current)
}

.drawer-header button {

    border-radius: 32px;
    line-height: 1;
    height: 32px;
    width: 32px;
}

.drawer-header button i {
    font-size: 1.4em;
}

.drawer-header {
    height: 32px;
    padding: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
}

.q-divider {
    margin-top: 16px;
    margin-bottom: 0px;
}

.drawer-content-container {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.iti__tel-input {
    width: 900px !important;
}

.child-content-container p,
.child-content-container h4 {
    margin: 0;
}

.child-content-container h4 {
    font-family: main_font_bold;
    font-size: .9em;
}

.group-title-container {
    background-color: var(--theme-color-navbar-bg);

}

.q-layout-row {
    width: 100%;
}

.q-button {
    width: 100%;
    background-color: var(--q_red);
    color: white !important;
}

.q-button p {
    color: white;
    margin-left: 8px
}
</style>
