<template>
    <div class=" q-display-flex-column  q-center q-flex-center-items" style="width: 100%;">
        <div class="main-content-container q-display-flex-column  q-center q-flex-center-items">
            <h2 class="q-align-self-left" style="margin-top: 0; margin-bottom: 32px;">Administrators</h2>
            <ContentHeaderComponent :controls="controls" searchPlaceHolder="Find a administrator by name"
                @controlData="handleControlClick" @searchItem="handleSearch">
            </ContentHeaderComponent>

            <table>
                <tr style="background-color:transparent;border-radius: 8px;">
                    <th v-for="(header) in headers" :key="header.key">
                        {{ header.name }}
                    </th>
                    <th style="width: 32px;">

                    </th>
                </tr>
                <tr :class="currentUserId == row.objectId ? 'current-row' : ''" data-can-be-disabled
                    class="q-animatable" v-for="(row) in rowsData" :key="row.objectId">

                    <td @click="viewUserDetails($event, row)">
                        {{ row.name }}
                    </td>
                    <td @click="viewUserDetails($event, row)">
                        {{ row.email }}
                    </td>
                    <td @click="viewUserDetails($event, row)">
                        {{ row.contact }}
                    </td>
                    <td @click="viewUserDetails($event, row)">
                        {{ row.role }}
                    </td>
                    <!-- <td @click="viewUserDetails($event, row)">
                        0pts
                    </td> -->
                    <OptionsPopup @setCurrent="setCurrent" @controlData="handleRowControlClick" :controls="rowControls"
                        :rowData="row">
                    </OptionsPopup>
                </tr>
            </table>
            <ContentTableFooter ref="footerItem" :totalCount="total">
            </ContentTableFooter>
            <UserDetailsDrawer @edit="editPreset" @userDeleted="loadUsers" ref="detailsDialog"></UserDetailsDrawer>
        </div>

        <CreateUserView @created="loadUsers" ref="createDialog">
        </CreateUserView>
        <AdminEditUserView @edited="loadUsers" ref="editDialog"></AdminEditUserView>
    </div>
</template>

<script>


import ContentHeaderComponent from "../../../components/admin/ContentHeaderComponent.vue"
import OptionsPopup from "../../../components/admin/OptionsPopup.vue"
import CreateUserView from "../../../components/admin/CreateUserView.vue"
import AdminEditUserView from '../../../components/admin/AdminEditUserView.vue'
import { showMessage } from "../../../../public/js/utils.js"
import UserDetailsDrawer from "../../../components/admin/UserDetailsDrawer.vue"


// import { showMessage, joinArrayToStr } from "../../../../public/js/utils.js"

export default {

    name: "AdminDesktopView",
    props: ["rowsData", "hasData", "currentUserData", "currentUserId", "rowControls", "controls", "supportedViewActions", "totalPresets", "headers"],
    components: {
        OptionsPopup,

        ContentHeaderComponent,
        CreateUserView, AdminEditUserView, UserDetailsDrawer,

    },
    computed: {

        showPlaceHolderELement() {
            return !this.hasData ? "showPlaceHolder" : ""
        },
        showTableELement() {
            return this.hasData ? "" : "hideTable"
        },
        currentPresetData() {
            return this.$store.getters["current/currentVisitor"];
        },
        isCurrentPreset() {
            var data = this.$store.getters["current/currentVisitor"]
            console.log("mi == " + "  ")

            if (data.objectId != undefined && this.currentPresetId == data.objectId)
                return "current-row"
            return ""
        }

    },
    data() {
        return {
            capturedData: "",
        }
    },

    methods: {
        showMediaCam: function () {
            this.capturedData = ""
            this.$refs.mediaComponent.show()
        },
        handleAccepted: function () {

            this.capturedData = this.$refs.mediaComponent.getImageData()
            console.log("mysd f asdf asf " + this.$refs.mediaComponent.getImageData())

        },
        handleCurrentSelectionChanged: function (data) {

            this.$emit('handleCurrentSelectionChanged', data)
        },

        handleSearch: function (event, data) {
            this.$emit('handleSearch', event, data)

        },

        viewUserDetails: function (event, row) {
            this.$store.dispatch("current/setCurrentUser", row)
            console.log("is sentsdf asdfasdf " + JSON.stringify(row))
            this.$refs.detailsDialog.show(event, row)
        },
        setCurrent: function (event, row) {
            // var data_ = this.tableData.filter(a => {
            //     return a.objectId === row.objectId
            // })

            // this.$store.dispatch("current/setCurrentVisitor", data_.length > 0 ? data_[0] : row)
            console.log("sett cisd asdf asdf ")
            this.$emit('setCurrent', event, row)
        },
        handleRowControlClick: function (event, data) {
            console.log(" mysdfu asdfasdf " + JSON.stringify(data) + "  " + JSON.stringify(this.currentUserData))
            if (this.currentUserData.objectId) {
                if (data.key === 2) {
                    this.$refs.createDialog.show()
                } else if (data.key == 0) {
                    this.$refs.editDialog.show()
                } else if (data.key == 1) {
                    this.$refs.detailsDialog.deleteUser()
                }
            } else {

                showMessage("Please select a user ", false)

            }
        },
        loadUsers: function (data) {
            this.$emit('loadUsers', data)
        },
        handleControlClick: function (event, data) {
            if (data.key === 0) {
                this.loadUsers()
            } else if (data.key === 1) {
                this.$refs.createDialog.show()
            } else {
                if (!this.hasData) {
                    showMessage("No users created")
                } else {
                    var userData = this.$store.getters["current/currentUser"]
                    if (!userData.objectId) {
                        this.setCurrent(null, this.rowsData[0])
                    }
                }
                if (data.key === 3) {
                    this.$refs.detailsDialog.deleteUser()
                } else if (data.key === 2) {
                    this.$refs.editDialog.show()
                }
            }
        },
        editPreset: function () {
            this.$refs.editDialog.show()
        },
        createPreset: function () {

            this.$refs.detailsDialog.show()
        }
    },
};
</script>
<style scoped></style>
