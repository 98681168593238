<template>
    <div ref="backdrop" class="q-back-drop"></div>
    <div aria-modal="true" aria-label="user Details"
        class=" q-animatable elevate-5 details-container  q-display-flex-column " ref="detailsDialog">



        <div class="drawer-headerq  q-display-flex-column" style="width: 100%;">
            <div style="padding-left: 16px;padding-right: 16px;width: 56px;height: 56px; ">
                <div class=" avatar-container q-display-flex-column q-flex-center-items q-center">
                    <h2>{{ userInitials }}</h2>
                </div>
            </div>
            <div style="padding-left: 16px;padding-right: 16px; width: 100%; " class=" user-details-container">
                <h3>{{ userName }}</h3>
                <p>{{ userEmail }}</p>
            </div>
            <div class="q-divider" style="margin-top: 32px;"></div>
        </div>


        <div class="nav-container" style="border: 0px">
            <ul>
                <li @click="close" class="q-display-flex-row q-item-animatable q-flex-center-items "
                    v-for="(section) in sections" :key="section.key">
                    <i :class="section.icon"></i>
                    <RouterLink :to="section.url">{{ section.name }}</RouterLink>
                </li>

            </ul>

        </div>
        <div class="nav-footer q-display-flex-row q-item-animatable q-flex-center-items">
            <p style="
        font-size: 0.8em;
        margin-top: 8px;
        margin-left: 8px;
        opacity: 0.7;
        max-width: 100% !important;
      ">
                Copyright © Hr Pro. All rights reserved.
            </p>
        </div>
    </div>
</template>

<script>

export default {
    name: "PreviewPresetDrawer",
    props: ["sections"],
    components: {

    },
    computed: {
        userRole() {
            return this.$store.getters["user/role"];
        },
        userPoints() {
            return this.$store.getters["user/points"];
        },
        isLoadingUser() {
            return this.$store.getters["user/isReloadingLoadingUser"];
        },
        showAdminButton() {
            return this.isAdmin ? "display:flex" : "display:none"
        },
        isLoggedIn() {
            return (
                this.$store.getters["user/isLoggedIn"]
            );
        },
        userName() {
            const user_name = this.$store.getters["user/username"]

            if (!user_name || user_name === "")
                return this.$store.getters["user/role"]
            else
                return this.$store.getters["user/username"]
        },
        userEmail() {
            return this.$store.getters["user/email"]
        },
        userInitials() {
            const user_name = this.$store.getters["user/username"]
            console.log("my sue rnamsdfasdf " + this.userName)
            if (user_name && user_name !== "")
                return this.userName != "" ? this.userName.at(0) : ""
            else {
                console.log("my sdfo as fasf asf = = = " + this.userEmail)
                return "HR"
            }
        },
        photoUrl() {
            return this.$store.getters["user/photoUrl"]
        },
        showInitials() {
            return this.photoUrl != "" ? "hideInitials" : ""
        },
        showAuthButtons() {
            return !this.isLoggedIn ? "show" : ""
        }
    },
    data() {
        return {
            presetDetails: [],

        }
    },
    mounted() {
        this.$refs.backdrop.addEventListener("click", () => {
            this.close()
        })
    },
    methods: {

        show: async function () {
            this.close()
            console.log("is sd pfasp faspdf pasf asdf")
            this.$refs.detailsDialog.classList.add("showDrawer")
            this.$refs.backdrop.classList.add("showBackDrop")

        },
        close: function () {
            if (this.$refs.detailsDialog && this.$refs.backdrop) {
                this.$refs.detailsDialog.classList.remove("showDrawer")
                this.$refs.backdrop.classList.remove("showBackDrop")
            }
        },

        showOverlay: function (show) {
            this.$refs.q_overlay.run = show

        },
        startDeleteUser: async function () {
            this.$refs.dialog_component.show(undefined, { "title": "Delete", "description": "Are you sure you want to delete this visitor, this operation is irreversible?" })
        },
        edit: function () {
            this.$emit('edit', '')
            this.close()
        },


    }
};
</script>
<style scoped>
.nav-footer {

    width: 100%;
    height: 32px;
    border-top: 1px solid rgba(0, 0, 0, .12);
}

.user-details-container h3 {
    margin-top: 16px;
    margin-bottom: 0;

}

.user-details-container p {
    margin-top: 8px;
    margin: 0;
    opacity: .7;
}

.showDrawer {
    width: 320px !important;
    opacity: 1 !important;
    visibility: visible !important;
}

.details-container {
    position: fixed;
    background-color: white;
    height: 100vh;
    width: 0;
    left: 0;
    top: 0;
    padding: 0px;
    padding-top: 16px;
    padding-bottom: 0px;
    z-index: 99999999999;
    visibility: hidden;
    opacity: 0;
}



.button-container .content-container {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.drawer-header button:hover {
    background-color: var(--nav-current)
}

.drawer-header button {

    border-radius: 32px;
    line-height: 1;
    height: 32px;
    width: 10%;
}

.drawer-header button i {
    font-size: 1.4em;
}

.avatar-container {
    width: 56px;
    height: 56px;
    background-color: var(--gw_primary_color);
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 50% 50%;
}

.avatar-container h2 {
    color: white;
    font-size: 24px;
    font-weight: 500;
    margin: 0;
}

.drawer-header {
    height: 32px;
    padding: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
}

.q-divider {
    margin-top: 16px;
    margin-bottom: 0px;
}

.drawer-content-container {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.child-content-container p,
.child-content-container h4 {
    margin: 0;
}

.child-content-container h4 {
    font-family: main_font_bold;
    font-size: .9em;
}

.group-title-container {
    background-color: var(--theme-color-navbar-bg);

}

.q-layout-row {
    width: 100%;
}

.q-button {
    width: 100%;
    background-color: var(--q_red);
    color: white !important;
    border-radius: 8px;
}

.q-button p {
    color: white;
    margin-left: 8px
}

.showBackDrop {
    display: block !important;
}

.q-back-drop {
    width: 100vw;
    background-color: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999999;
    opacity: .12;
    display: none;
}

.nav-container ul {
    list-style-type: none;
    margin: 0px;
    margin-top: 8px;
    padding: 0px;
}

li:has(> a.router-link-active) {
    background-color: var(--nav-current)
}

.list-caption span {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: var(--gw_text_color);
    opacity: .6;
    margin-left: 16px;
}

.nav-container ul li {
    padding-top: 14px;
    padding-bottom: 12px;
    border-radius: 4px;
    margin-left: 0px;
    margin-right: 0px;

    ;
}

ul li:hover {
    background-color: var(--nav-selected)
}

ul li {
    padding-left: 16px;
}

ul li i {
    font-size: 15px;
}

.nav-container {

    width: 100%;
    height: 100%;
}

ul li a {
    padding-left: 12px;
    color: var(--gw_text_color);
    font-family: main_font_bold;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500
}

@media (min-width: 992px) {
    .showDrawer {
        width: 400px !important;
        opacity: 1 !important;
    }


}
</style>
